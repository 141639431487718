"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaQueries = exports.isTablet = exports.isSubDesktop = exports.isMobile = exports.isDesktop = exports.determineCurrentMedia = exports.checkBreakpoint = void 0;

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.keys.js");

/*
 Note: These mediaQueries should be kept in sync with the mediaQueries in styles-v6/_variables-media-queries.less
 as well as the mediaQueries in @sqs/styles/_variables-media-queries.less

 In short, if you change one, change them all
 */
var mediaQueries = {
  /*
    Currently we are only targeting mobile devices up to 432px. This should include all the most typical iOS, Android
    and Windows phones. Tablet devices are currently not being targeted for the mobile experience.
   */
  mobile: 'screen and (max-width: 432px)',
  desktop: 'screen and (min-width: 433px)',
  tablet: 'screen and (max-width: 0px)' //disabled for now

};
exports.mediaQueries = mediaQueries;

var determineCurrentMedia = function determineCurrentMedia() {
  return Object.keys(mediaQueries).find(function (key) {
    return window.matchMedia(mediaQueries[key]).matches;
  });
};

exports.determineCurrentMedia = determineCurrentMedia;

var checkBreakpoint = function checkBreakpoint(breakpointName, currentBreakpoint) {
  if (currentBreakpoint) {
    return currentBreakpoint === breakpointName;
  }

  return determineCurrentMedia() === breakpointName;
};

exports.checkBreakpoint = checkBreakpoint;

var isMobile = function isMobile(breakpoint) {
  return checkBreakpoint('mobile', breakpoint);
};

exports.isMobile = isMobile;

var isDesktop = function isDesktop(breakpoint) {
  return checkBreakpoint('desktop', breakpoint);
};

exports.isDesktop = isDesktop;

var isTablet = function isTablet(breakpoint) {
  return checkBreakpoint('tablet', breakpoint);
};

exports.isTablet = isTablet;

var isSubDesktop = function isSubDesktop(breakpoint) {
  return isMobile(breakpoint) || isTablet(breakpoint);
};

exports.isSubDesktop = isSubDesktop;