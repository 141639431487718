import { t } from 'shared/i18n';
import mapValues from 'lodash/mapValues';

/**
 Gallery Options
 Used to generate predicates & by the dialog.
 @property  GalleryOptions

 @caution minor gotcha ahead
 Actual DF usage of this configuration is updated to a new-style select
 later by the module that consumes these options. Also, if we convert this to
 the new style, then we create a circular dependency between utils and df2.
 and then we have to refactor a bunch of code that's currently working
 fine as far as I know - jangel
 */

export const GalleryOptions = {
  'design': {
    title: t("Gallery Design"),
    description: t("Gallery designs give you a variety of ways to display images."),





    type: 'multiOption',
    defaultValue: 'grid',
    options: {
      'slideshow': {
        title: t("Slideshow"),
        values: {
          thumbnails: true },

        icon: 'slideshow' },

      'slider': {
        title: t("Carousel"),
        values: {
          thumbnails: false },

        icon: 'carousel' },

      'grid': {
        title: t("Grid"),
        values: {
          thumbnails: false,
          autoplay: false },

        icon: 'grid' },

      'stacked': {
        title: t("Stacked"),
        values: {
          thumbnails: false,
          autoplay: false },

        icon: 'stacked' }

      /*,
       'autocolumns': {
       title: t("Auto Columns"),
       values: {
       'autoplay': false,
       'controls': false,
       'indicators': false,
       'auto-crop': true,
       'square-thumbs': false,
       'thumbnails': true,
       'active-alignment': 'center',
       'thumbnails-per-row': 4,
       'padding': 20,
       'lightbox': true,
       'max-width': 30
       }
       },
       'standard': {
       title: t("Standard"),
       values: {
       'autoplay': false,
       'controls': false,
       'indicators': false,
       'auto-crop': true,
       'square-thumbs': false,
       'thumbnails': true,
       'active-alignment': 'center',
       'thumbnails-per-row': 4,
       'padding': 20,
       'lightbox': false,
       'max-width': 30
       }
       }
       */ } },


  'autoplay': {
    type: 'boolean',
    title: t("Automatically Transition Between Slides"),
    // description: t('Automatically transition to the next slide after a set duration.'),
    defaultValue: false,
    onlyFor: [
    'design:slideshow',
    'design:slider',
    'design:standard'] },


  'autoplay-duration': {
    type: 'slider',
    title: t("Autoplay Delay (Seconds)"),
    description: t("Seconds between autoplay transitions."),
    min: 1,
    max: 10,
    defaultValue: 5,
    onlyFor: [
    'autoplay:true'] },



  'controls': {
    type: 'boolean',
    defaultValue: false,
    title: t("Show Next and Previous Controls"),
    // description: t('Show the next and previous image controls.'),
    onlyFor: [
    'design:slideshow',
    'design:slider',
    'design:standard'] },


  'auto-crop': {
    type: 'boolean',
    title: t("Automatically Crop Images"),
    defaultValue: true,
    // description: t('Auto crop the images.'),
    onlyFor: [
    'design:slideshow',
    'design:standard'] },


  'aspect-ratio': {
    title: t("Aspect Ratio"),
    type: 'select',
    defaultValue: 'square',
    options: {
      'square': {
        title: t("1:1 Square") },

      'standard': {
        title: t("3:2 Standard") },

      'standard-vertical': {
        title: t("2:3 Standard (Vertical)") },

      'four-three': {
        title: t("4:3 Four-Three") },

      'three-four-vertical': {
        title: t("3:4 Three-Four (Vertical)") },

      'widescreen': {
        title: t("16:9 Widescreen") },

      'anamorphic-widescreen': {
        title: t("2.40 Anamorphic Widescreen") } },


    onlyFor: [
    'design:grid'] },



  // The existing 'square-thumbs' option simply toggles image cropping and has
  // nothing to do with squares. Now that we have additional aspect ratios ^^,
  // it's display name needs to change. Under the hood, we need to keep it
  // around as-is in the interest of protecting the settings of existing
  // blocks.
  'square-thumbs': {
    type: 'boolean',
    title: t("Crop Images"),
    description: t("Crop images to the aspect ratio."),
    defaultValue: true,
    onlyFor: [
    'design:grid',
    'design:standard'] },


  'thumbnails': {
    type: 'boolean',
    defaultValue: false,
    title: t("Show Thumbnails"),
    // description: t('Show thumbnails.'),
    onlyFor: [
    'design:slideshow'] },


  'thumbnail-strip-height': {
    type: 'slider',
    title: t("Thumbnail Strip Height"),
    description: t("Select the thumbnail strip height."),
    min: 0,
    max: 200,
    defaultValue: 80,
    onlyFor: [
    'thumbnails:true'] },


  'thumbnail-strip-margin': {
    type: 'slider',
    title: t("Thumbnail Strip Margin"),
    description: t("Select the distance between the main image and the thumbnail strip."),




    min: 0,
    max: 60,
    defaultValue: 20,
    onlyFor: [
    'thumbnails:true'] },



  'show-meta-basic': {
    type: 'boolean',
    title: t("Show Title and Description"),
    defaultValue: true,
    onlyFor: [
    'design:stacked'] },



  'show-meta': {
    type: 'boolean',
    title: t("Show Title and Description"),
    defaultValue: true,
    // description: t('Display the title and description.'),
    onlyFor: [
    'design:slideshow'] },



  'show-meta-only-title': {
    type: 'boolean',
    title: t("Show Title"),
    description: t("Show the title below the image"),
    defaultValue: false,
    onlyFor: [
    'design:grid'] },



  'meta-position': {
    title: t("Title and Description Position"),
    description: t("Control the location of the Image's Title and Description."),




    type: 'select',
    padding: [10, 0],
    defaultValue: 'bottom',
    options: {
      'top': {
        title: t("Top") },

      'top-left': {
        title: t("Top Left") },

      'top-right': {
        title: t("Top Right") },


      'center': {
        title: t("Center") },


      'bottom': {
        title: t("Bottom") },

      'bottom-left': {
        title: t("Bottom Left") },

      'bottom-right': {
        title: t("Bottom Right") } },


    onlyFor: [
    'show-meta:true'] },


  'show-meta-on-hover': {
    type: 'boolean',
    title: t("Show on Hover"),
    defaultValue: false,
    description: t("Show the title and description only on hover."),




    onlyFor: [
    'show-meta:true'] },



  'active-alignment': {
    title: t("Active Alignment"),
    description: t("Where does the active image align to in the slider."),




    type: 'select',
    defaultValue: 'center',
    options: {
      'left': {
        title: t("Left") },

      'center': {
        title: t("Center") },

      'right': {
        title: t("Right") } },


    onlyFor: [
    'design:slider'] },


  'thumbnails-per-row': {
    type: 'slider',
    title: t("Thumbnails Per Row"),
    description: t("Choose the thumbnails per row."),
    min: 1,
    max: 10,
    defaultValue: 4,
    labelWidth: 33,
    onlyFor: [
    'design:grid',
    'design:standard'] },


  'padding': {
    type: 'slider',
    title: t("Padding"),
    description: t("Padding around the gridded images."),
    min: 0,
    max: 50,
    defaultValue: 20,
    labelWidth: 33,
    onlyFor: [
    'design:grid',
    'design:autocolumns',
    'design:standard'] },


  'lightbox': {
    type: 'boolean',
    defaultValue: false,
    title: t("Lightbox"),
    description: t("Clicking on the images will pop the image up in a lightbox."),




    onlyFor: [
    'design:grid',
    'design:autocolumns'] },


  'lightboxTheme': {
    type: 'select',
    defaultValue: 'dark',
    title: t("Lightbox Theme"),
    options: {
      'dark': {
        title: t("Dark Overlay") },

      'light': {
        title: t("Light Overlay") } },


    onlyFor: [
    'lightbox:true'] },


  'max-width': {
    type: 'slider',
    title: t("Max Width"),
    description: t("Maximum width for each column"),
    min: 100,
    max: 300,
    defaultValue: 300,
    onlyFor: [
    'design:autocolumns'] },


  'newWindow': {
    type: 'boolean',
    defaultValue: false,
    title: t("Open Links in New Window"),
    description: t("Images with links will open in a new window.") },

  'transparentBackground': {
    type: 'boolean',
    defaultValue: false,
    title: t("Transparent Background"),
    description: t("Remove the color behind uncropped or transparent images."),




    onlyFor: [
    'auto-crop:false',
    'design:slideshow'],

    matchAllOnlyFor: true } };



export const getDefaultGalleryOptions = () =>
mapValues(GalleryOptions, ({ defaultValue }) => defaultValue);