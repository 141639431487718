"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createWarningLogger = exports.createLoggers = exports.createLogger = exports.createGroupCollapsed = exports.createGroup = exports.createErrorLogger = exports.createDebugLogger = void 0;

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.object.assign.js");

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _noop2 = _interopRequireDefault(require("lodash/noop"));

var createLogger = function createLogger(namespace, color) {
  var isEnabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : __DEV__;
  return isEnabled ? console.log.bind(console, "%c[".concat(namespace, "] %c"), "color: ".concat(color, "; font-weight: 300;"), 'color: #000; font-weight: 400;') : _noop2.default;
};

exports.createLogger = createLogger;

var createDebugLogger = function createDebugLogger(namespace, color) {
  var isEnabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : __DEV__;
  return isEnabled ? console.debug.bind(console, "%c[".concat(namespace, "] %c"), "color: ".concat(color, "; font-weight: 300;"), 'color: #000; font-weight: 400;') : _noop2.default;
};

exports.createDebugLogger = createDebugLogger;

var createWarningLogger = function createWarningLogger(namespace) {
  return console.warn.bind(console, "[".concat(namespace, "]"));
};

exports.createWarningLogger = createWarningLogger;

var createErrorLogger = function createErrorLogger(namespace) {
  return function () {
    console.error.bind(console, "[".concat(namespace, "]")).apply(void 0, arguments);
  };
};

exports.createErrorLogger = createErrorLogger;

var createGroupCollapsed = function createGroupCollapsed(groupName, color) {
  var _console$groupCollaps;

  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var isEnabled = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : __DEV__;
  return isEnabled ? (_console$groupCollaps = console.groupCollapsed).bind.apply(_console$groupCollaps, [console, groupName, "color: ".concat(color, "; font-weight: 300;")].concat((0, _toConsumableArray2.default)(format))) : _noop2.default;
};

exports.createGroupCollapsed = createGroupCollapsed;

var createGroup = function createGroup(groupName, color) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var isEnabled = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : __DEV__;
  return {
    group: createGroupCollapsed(groupName, color, format, isEnabled),
    groupEnd: function groupEnd() {
      return console.groupEnd();
    }
  };
}; //To accomodate cases where callsites used properties from the default (createLoggers)


exports.createGroup = createGroup;
var forwardFromCreateLoggers = {
  createLogger: createLogger,
  createDebugLogger: createDebugLogger,
  createWarningLogger: createWarningLogger,
  createGroupCollapsed: createGroupCollapsed,
  createGroup: createGroup,
  createErrorLogger: createErrorLogger
};

var _createLoggers = function _createLoggers(namespace, color) {
  var isEnabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : __DEV__;
  var scopeTag = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  return {
    debug: createDebugLogger(namespace, color, isEnabled),
    err: createErrorLogger(namespace),
    log: createLogger(namespace, color, isEnabled),
    warn: createWarningLogger(namespace)
  };
};

var createLoggers = Object.assign(_createLoggers, forwardFromCreateLoggers);
exports.createLoggers = createLoggers;