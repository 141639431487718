"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.array.concat.js");

var _get2 = _interopRequireDefault(require("lodash/get"));

var _qs = _interopRequireDefault(require("qs"));

var _StatusConstants = _interopRequireDefault(require("@sqs/enums/StatusConstants"));

var _SessionStorage = require("./SessionStorage");

/**
 * A util that can be used to set up checking for Praetor flags where a query param
 * can be used to overwrite the value from Praetor. The query param will set the value in local storage,
 * and continue overwriting until unset.
 *
 * The query parameter used to set the value for a specific praetor flag is: ?namespace_flagName
 *
 * For example where namespace='commerce-activation' and flag='asset-manager-pc' :
 *
 * - ?commerce-activation_asset-manager-pc=true sets the override to true
 * - ?commerce-activation_asset-manager-pc=false sets the override to false
 * - ?commerce-activation_asset-manager-pc=foo sets the override to foo
 * - ?commerce-activation_asset-manager-pc=reset clears the override
 */
var setupPraetorFlagging = function setupPraetorFlagging(namespace, client) {
  var queryParams;
  return function (testName) {
    var controlValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'false';
    var experimentValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'true';

    var matchedVariant = function () {
      try {
        var siteStatus = (0, _get2.default)(window, 'Static.SQUARESPACE_CONTEXT.website.siteStatus.value');
        var isInternalSite = siteStatus === _StatusConstants.default.INTERNAL;

        if (isInternalSite) {
          queryParams = queryParams || _qs.default.parse(window.location.search, {
            ignoreQueryPrefix: true
          });

          var _key = "".concat(namespace, "_").concat(testName);

          var praetorOverrideValue = queryParams[_key];

          if (praetorOverrideValue) {
            if (praetorOverrideValue === 'reset') {
              (0, _SessionStorage.removeSessionData)(_key);
            } else {
              (0, _SessionStorage.saveSessionData)(_key, {
                variant: praetorOverrideValue
              });
            }
          }

          var storedValue = (0, _SessionStorage.getSessionData)(_key);

          if (storedValue) {
            return storedValue.variant;
          }
        }

        var _client$getABTestVari = client.getABTestVariant(testName, controlValue),
            variant = _client$getABTestVari.variant;

        return variant;
      } catch (err) {
        console.error("error reading praetor flag: ".concat(err));
        return controlValue;
      }
    }();

    return function () {
      return matchedVariant === experimentValue;
    };
  };
};

var _default = setupPraetorFlagging;
exports.default = _default;
module.exports = exports.default;