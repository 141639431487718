"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.TemplateVersion */

/* Java class defined in repository cms-legacy-data */
var TemplateVersion;

(function (TemplateVersion) {
  TemplateVersion["SEVEN"] = "7";
  TemplateVersion["SEVEN_ONE"] = "7.1";
  TemplateVersion["EIGHT"] = "8";
})(TemplateVersion || (TemplateVersion = {}));

var _default = TemplateVersion;
exports["default"] = _default;
module.exports = exports.default;