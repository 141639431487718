import "core-js/modules/es.object.freeze.js";

/*
 *  A map used by BlockBetaFeatureUtils to gate a given block on one or more flags.
 *
 *  Example usage:
 *
 *   const BlockTypeToBetaFlags = Object.freeze({
 *     [BlockType.MEMBER_AREA]: [Flag.MEMBER_AREAS_BETA_V2, Flag.MEMBER_AREAS_GA]
 *   });
 *
 * */
var BlockTypeToBetaFlags = Object.freeze({});
export default BlockTypeToBetaFlags;