import "core-js/modules/es.array.concat.js";
import { legacyV6Flags as BetaFeaturesUtils } from '@sqs/universal-flags';
import Flag from '@sqs/enums/Flag';
export var ONBOARDING_SAVE_TITLE = 'cms-onboarding-save-title';
export var PAGES_ADD_BLANK = 'cms-pages-add-blank';
export var FRAME_TOOLBAR_EDIT = 'cms-frame-toolbar-edit';
export var PAGE_EDIT_SAVE = 'cms-page-edit-save';
export var SITE_HEADER_FOOTER_LOAD = 'cms-site-header-footer-load';
export var HEADER_PROPERTY_UPDATE = 'cms-header-property-update';
export var HEADER_NEW_LAYOUT_LOAD = 'cms-header-new-layout-load';
export var GALLERY_SECTION_NEW_LAYOUT_LOAD = 'cms-section-gallery-new-layout-load';
export var COLLECTION_NEW_LAYOUT_LOAD = 'cms-collection-new-layout-load';
export var RENDER_LAYOUT_ENGINE_SECTION = 'cms-render-layout-engine-section';
export var LAYOUT_ENGINE_SECTION_COLOR_PREVIEW = 'cms-layout-engine-section-color-preview';
export var ANIMATIONS_EDIT_SAVE = 'cms-design-animations-panel-save';
export var ANIMATIONS_PANEL_INTERACTIVE = 'cms-design-animations-panel-interactive';
export var TWEAKACTION_FETCH_CUSTOM_CSS = 'cms-tweakactions-fetch-custom-css';
export var TWEAKACTION_FETCH_DATA = 'cms-tweakactions-fetchdata';
export var TWEAKACTION_FETCH_TEMPLATE_TWEAK_SETTINGS = 'cms-tweakactions-fetch-template-tweak-settings';
export var TWEAKACTION_FETCH_TYPEKIT_DATA = 'cms-tweakactions-fetch-typekit-data';
export var TWEAKACTION_FETCH_TWEAK_ENGINE_PROPERTIES = 'cms-tweakactions-fetch-tweak-engine-properties';
export var COLORS_PANEL_SAVE = 'cms-design-colors-panel-save' + (BetaFeaturesUtils.isFeatureEnabled(Flag.SEVEN_ONE_AESTHETICS_PATCH_API) ? '-patch' : '');
export var COLORS_PANEL_INTERACTIVE = 'cms-design-colors-panel-interactive';
export var THEME_EDITOR_INTERACTIVE = 'cms-design-colors-theme-editor-interactive';
var isCssVarColorThemesEnabled = BetaFeaturesUtils.isFeatureEnabled(Flag.CSS_VAR_COLOR_THEMES);

var conditionallyAppendCssVarsSuffix = function conditionallyAppendCssVarsSuffix(name) {
  return "".concat(name).concat(isCssVarColorThemesEnabled ? '-css-vars' : '');
};

export var COLORS_PANEL_SELECT_PALETTE = conditionallyAppendCssVarsSuffix('cms-design-colors-panel-select-palette');
export var COLORS_PANEL_GENERATE_PALETTE = conditionallyAppendCssVarsSuffix('cms-design-colors-panel-generate-palette');
export var COLORS_PANEL_UPDATE_PALETTE_COLOR = conditionallyAppendCssVarsSuffix('cms-design-colors-panel-update-palette-color');
export var COLORS_PANEL_UPDATE_TWEAK_COLOR = conditionallyAppendCssVarsSuffix('cms-design-colors-panel-update-tweak-color');
export var FONTS_PANEL_FETCHED_DATA = 'cms-design-fonts-panel-fetched-data';
export var FONTS_PANEL_FETCHED_DATA_TO_INTERACTIVE = 'cms-design-fonts-panel-fetched-data-interactive'; // for historical reasons the const name is different than the string
// this is for actual panel is interactive

export var FONTS_PANEL_INTERACTIVE = 'cms-design-fonts-panel-packs-interactive';
export var FONTS_PANEL_RENDER_ACTIVE_CARD = 'cms-design-fonts-render-active-card';
export var FONTS_PANEL_SAVE = 'cms-design-fonts-panel-save' + (BetaFeaturesUtils.isFeatureEnabled(Flag.SEVEN_ONE_AESTHETICS_PATCH_API) ? '-patch' : '');
export var FONTS_PANEL_TAB_SWITCH_INTERACTIVE = 'cms-design-fonts-panel-tab-switch-interactive';
export var FONTS_PANEL_PACK_CHANGE_CLEAN_DIRTY = 'cms-design-fonts-panel-pack-change-clean-dirty';
export var FONTS_PANEL_PACK_CHANGE_DIRTY_DIRTY = 'cms-design-fonts-panel-pack-change-dirty-dirty';
export var DESIGN_BUTTONS_PANEL_SAVE = 'cms-design-buttons-panel-save';
export var DESIGN_BUTTONS_PANEL_LOAD = 'cms-design-buttons-panel-load';
export var SECTION_DUPLICATION = 'cms-section-duplicate';
export var ASSET_UPLOAD = 'cms-asset-upload';
export var SITE_SERVER_ASSET_UPLOAD = 'cms-site-server-asset-upload';
export var MEDIA_UPLOAD_SERVICE_ASSET_UPLOAD = 'cms-media-upload-service-asset-upload';
export var MEDIA_UPLOAD_SERVICE_ASSET_UPLOAD_ONLY = 'cms-media-upload-service-asset-upload-only';
export var MEDIA_UPLOAD_SERVICE_ASSET_PROCESSING_ONLY = 'cms-media-upload-service-asset-processing-only';
export var EDIT_SECTION_TEXT = 'cms-edit-section-text';
export var ADD_SECTION_FROM_CATALOG = 'cms-add-section-from-catalog';