"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CollectionType */

/* Java class defined in repository cms-legacy-data */
var CollectionTypes;

(function (CollectionTypes) {
  CollectionTypes["COLLECTION_TYPE_GENERIC"] = 1;
  CollectionTypes["COLLECTION_TYPE_SUBSCRIPTION"] = 2;
  CollectionTypes["TWITTER"] = 3;
  CollectionTypes["FOURSQUARE"] = 4;
  CollectionTypes["INSTAGRAM"] = 5;
  CollectionTypes["GALLERY_BLOCK"] = 6;
  CollectionTypes["TEMPLATE_PAGE"] = 7;
  CollectionTypes["SPLASH_PAGE"] = 8;
  CollectionTypes["COLLECTION_TYPE_PAGE"] = 10;
  CollectionTypes["FIVEHUNDREDPIX"] = 11;
  CollectionTypes["FLICKR"] = 12;
  CollectionTypes["PRODUCTS"] = 13;
  CollectionTypes["SLIDE_GALLERY"] = 15;
  CollectionTypes["SLIDE_ALBUM"] = 16;
  CollectionTypes["SLIDE_VIDEO"] = 17;
  CollectionTypes["ALBUM_BLOCK"] = 18;
  CollectionTypes["PROJECT"] = 19;
  CollectionTypes["SECTION_CATALOG"] = 20;
  CollectionTypes["PAGE_CATALOG"] = 21;
  CollectionTypes["ITEM_CATALOG"] = 22;
  CollectionTypes["PORTFOLIO"] = 23;
  CollectionTypes["LESSONS"] = 24;
  CollectionTypes["MOSAIC"] = 14;
})(CollectionTypes || (CollectionTypes = {}));

var _default = CollectionTypes;
exports["default"] = _default;
module.exports = exports.default;