/**
 * A list of the selectors that we use to match and search which items should have a
 * Pin It Button
 * Nore: Make sure to check pinit_main.js for pin placement
 */
var PinterestSelectors = {
  BLOGS: ['body.collection-type-blog [data-type="item"]', 'body.collection-type-blog [data-type="promoted-block"]', '[class*="collection-type-blog"]' // seven one blog
  ],
  BLOGS_AND_PAGES: ['body.collection-type-blog [data-type="item"]', 'body.collection-type-blog [data-type="promoted-block"]', '[class*="collection-type-page"]', // seven one page
  '[class*="collection-type-blog"]', // seven one blog
  '[class*="collection-type-products"]', // seven one products
  '[data-type="page"]', '#productList', // system product 1.0, supply
  '#productlist', // galapagos
  '#productGallery', '.ProductList', // system product 2.0,
  '.sqs-system-gallery', // system gallery
  '#flowItems', // supply gallery
  '.productlist', // galapagos products list
  '.productitem-images', // galapagos products item
  '.sqs-pinterest-products-wrapper'],

  /**
   * These selects will be tested for when pinit logic is attempting to decide if
   * there should be a pin it button displayed.
   * @type {Array}
   */
  VALID_IMAGE_CONTAINER: ['.sqs-block-image', '.sqs-gallery-block-stacked', '.sqs-gallery-block-grid', '.sqs-gallery-block-slideshow', '.sqs-block-html', '.product-image', '#productGallery', '#productSlideshow', '.ProductList-item', // system product 2.0
  '.product', // supply, system product 1.0
  '.productlist-item', // galapagos,
  '.sqs-system-gallery .slides .slide', // system gallery
  'article.flow-item', // supply gallery
  '.productitem-images', // galapagos products item
  '.sqs-pinterest-products-wrapper', '[data-controller="ProductListImageLoader"]' // seven one product images
  ],

  /**
   * The selectors that when they exist on the page, the pinit logic will load.
   * @type {Array}
   */
  TRIGGER_SELECTORS: ['.sqs-block-image', '.sqs-gallery-block-stacked', '.sqs-gallery-block-grid', '.sqs-gallery-block-slideshow', '.sqs-block-html img', '.product-image', '#productGallery .slide img', '#productSlideshow', '.ProductList-item', // system product 2.0
  '.product', // supply, system product 1.0
  '[class^="products"]', // seven one products
  '.productlist-item', // galapagos,
  '.sqs-system-gallery', // system gallery
  '#flowItems', // supply gallery
  '.productitem-images', // galapagos products item
  '.sqs-pinterest-image img', '.sqs-pinterest-products-wrapper img']
};
export default PinterestSelectors;