import ErrorReporter from '@sqs/error-reporter';
import Context from 'shared/utils/Context';

/**
 * @module squarespace-debugger
 */
YUI.add('squarespace-debugger', function(Y) {

  /**
   * @namespace Squarespace
   * @class Debugger
   */
  Y.namespace('Squarespace');

  var Class = Y.Squarespace.Legacy.Class;

  Y.Squarespace.Debugger = Class.create({

    defaultOpts: {
      output: true,
      ignore: [],
      name: 'GenericComponent'
    },

    /**
     * @method initialize
     * @param  {Object} params
     */
    initialize: function(params) {
      this.params = Y.merge( this.defaultOpts, params );

      if (Context.isProductionEnvironment()) {
        this.params.output = false; // turn off the logger in production
      }

      if (this.params.uid) {
        this.params.name += ' #' + this.getUID();
      }

      //if (Y.Squarespace.Utils && Y.Squarespace.Utils.isInDamaskFrame()) {
      //  this.params.name = 'FRAME: ' + this.params.name;
      //}

      if (this.params.logEvents) {
        var ctx = this;
        this.params.logEvents.fire = function(event) {
          ctx.log('Firing', event);
          Y.EventTarget.prototype.fire.apply(ctx.params.logEvents, arguments);
        };
      }

      this.logEnabled = this.params.output;

    },

    enable: function() {
      this.params.output = true;
    },

    disable: function() {
      this.params.output = false;
    },

    isInfoEnabled: function() {
      if (Y.Lang.isBoolean(this.params.output)) {
        return this.params.output;
      }
      return false;

    },

    /**
     * @method info
     */
    info: function() {
      return this.log.apply(this, arguments);
    },

    /**
     * @method log
     */
    log: function() {

      if ( !this.params.output ) { return; }

      if ( this.params.ignore.indexOf(arguments[0]) >= 0 ) { return; }

      var args = Array.prototype.slice.call(arguments);

      var loggerLabel = '[' + this.params.name + ']';

      if (typeof (args[0]) === 'string') {
        args[0] = loggerLabel + ' ' + args[0];
      } else {
        args.unshift(loggerLabel);
      }

      if (console.log) {
        if (Y.UA.ios) {
          console.log(args.join(' '));
        } else if (console.log.apply) {
          console.log.apply(console, args);
        } else {
          console.log(args);
        }
      }

    },

    /**
     * @method error
     */
    error: function() {

      var args = Array.prototype.slice.call(arguments);
      var error = args[args.length - 1];
      if (error instanceof Error) {
        args.pop();
      }

      args.unshift( this.params.name );

      if (Y.UA.ios) {
        args = [args.join(' ')];
      }

      if (console.error) {
        if (Y.UA.ios) {
          console.error(args.join(' '));
        } else if (console.error.apply) {
          console.error.apply(console, args);
        } else {
          console.error(args);
        }
      }
      var stack;
      if (error && error instanceof Error) {
        stack = error.stack;
        console.error('Stack: ' + stack);
      } else if (console.trace) {
        console.trace();
      }

      ErrorReporter.trackCaughtError('damask', args[0], stack);

    },

    /**
     * @method warn
     */
    warn: function() {

      var args = Array.prototype.slice.call(arguments);

      var loggerLabel = '[' + this.params.name + ']';

      if (typeof (args[0]) === 'string') {
        args[0] = loggerLabel + ' ' + args[0];
      } else {
        args.unshift(loggerLabel);
      }

      if (console.warn) {
        if (Y.UA.ios) {
          console.warn( args.join(' ') );
        } else if (console.warn.apply) {
          console.warn.apply(console, args);
        } else {
          console.warn(args);
        }
      }

    },

    isTimingEnabled: function() {
      return !!this.params.output;
    },

    /**
     * @method getUID
     */
    getUID: function() {
      if (Y.Squarespace.Debugger.lastUID[this.params.name] === undefined) {
        Y.Squarespace.Debugger.lastUID[this.params.name] = 0;
      }

      return Y.Squarespace.Debugger.lastUID[this.params.name]++;
    },

    /**
     * @method time
     */
    time: function(name) {
      if ( !this.params.output ) { return; }
      if (console.time) {
        console.time('[' + this.params.name + '] ' + name);
      }
    },

    /**
     * @method timeEnd
     * @param  name
     */
    timeEnd: function(name) {
      if ( !this.params.output ) { return; }
      if (console.timeEnd) {
        console.timeEnd('[' + this.params.name + '] ' + name);
      }
    }

  });

  Y.Squarespace.Debugger.lastUID = {};

}, '1.0', {
  requires: [
    'squarespace-legacy-class',
    'squarespace-util'
  ]
});
