var SurveyCohorts;

(function (SurveyCohorts) {
  SurveyCohorts["A"] = "cohort-A";
  SurveyCohorts["B"] = "cohort-B";
  SurveyCohorts["C"] = "cohort-C";
  SurveyCohorts["D"] = "cohort-D";
  SurveyCohorts["E"] = "cohort-E";
})(SurveyCohorts || (SurveyCohorts = {}));

export default SurveyCohorts;