import { I18nUI } from '@sqs/i18n-ui';

import { checkCookie } from 'shared/utils/formatting/utils';
import { getResolvedWebsiteLocale } from './resolvedLocales';
import cldrResourcePackLoader from './cldrResourcePackLoader';
import Flag from '@sqs/enums/Flag';
import { legacyV6Flags as BetaFeaturesUtils } from '@sqs/universal-flags';
import { TranslateValue, PluralFormsType } from '@sqs/i18n-ui/lib/functions/I18nUI';


function isPseudoLocalizationEnabled(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  const pseudoQueryParam = urlParams.get('i18nLang');

  if (pseudoQueryParam === 'true' || pseudoQueryParam === 'on') {
    return true;
  }

  return BetaFeaturesUtils.isFeatureEnabled(Flag.ENABLE_I18N_LANGUAGE);
}

const i18nInstance = new I18nUI({
  formattingLocale: getResolvedWebsiteLocale(),
  isDebugMode: checkCookie('i18nShowLocalizedComponents'),
  translationDictionary: {},
  // this is always tied to the build
  // as long as we continue to build multiple assets per language
  translationLocale: __TRANSLATION_LOCALE__,
  cldrOptions: {
    loader: cldrResourcePackLoader,
  },
  isPseudoLocalized: isPseudoLocalizationEnabled(),
});

interface TranslationOptions {
  translationId?: string;
  project?: string;
  notes?: string;
  count?: number;
}

/**
 * Validates the count value used to determine the plural form used, then pass it
 * down to @sqs/rosette for pluralization. Fallbacks to 0 if invalid and warn.
 * @sqs/rosette#pluralize docs: https://i18n-docs.squarespace.net/api/sqs-rosette#pluralize
 * Compatible with @sqs/rosette "v5.0.0"
 *
 * @param {Object} forms plural forms (e.g. "one", "other",...)
 * @param {Number} selectValue count value to select the plural form
 * @param {Object} subs substitutions to insert in the string
 * @param {Object} opts options for translation metadata.
 */
export function pluralize(
  forms: Record<PluralFormsType, string>,
  selectValue: number,
  subs: Record<string, TranslateValue> | null,
  opts: TranslationOptions
): string {
  /**
   * Old guard from @sqs/rosette, should be fixed in the newer library.
   * Remove when Rosette is replaced with @sqs/i18n-ui
   *
   * For now, call `.toString()` on the passed value
   * `"string".toString() => "string"`
   * `(1234).toString() => "1234"`
   *
   */
  selectValue = parseInt(selectValue.toString(), 10);
  if (isNaN(selectValue)) {
    if (__DEV__) {
      console.warn('invalid selectValue passed to pluralize');
    }
    selectValue = 0;
  }

  return i18nInstance.pluralize(forms, selectValue, subs, opts);
}

export const {
  getCountries,
  getLanguageName,
  getOfacCountries,
  weekdays,
  // Vanilla Formatters
  formatNumber,
  formatCurrency,
  formatCurrencyToParts,
  formatMoney,
  getCurrencySymbol,
  formatQuantity,
  translate: t,
  // pluralize,
  formatDateTime,
  formatRelativeTime: formatRelativeDateTime,
  setLocale,
  setDebugMode,
} = i18nInstance;

export { getResolvedWebsiteLanguage, getResolvedMemberLanguage } from './resolvedLocales';

export default i18nInstance;
