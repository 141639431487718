/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/website-overlays-manager.js';
import 'src/main/webapp/universal/scripts-v6/licensed-assets/widgets/preview-bar.js';
import 'src/main/webapp/universal/scripts-v6/ss-widget.js';
import 'src/main/webapp/universal/scripts-v6/debugger.js';
import 'src/main/webapp/universal/scripts-v6/template-helpers.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/handlebars-base/handlebars-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/substitute/substitute.js';
import 'src/main/webapp/universal/scripts-v6/ui-templates.js';
import 'src/main/webapp/universal/scripts-v6/mobile-info-bar.js';
import 'src/main/webapp/universal/scripts-v6/business-hours.js';
import 'src/main/webapp/templates-v6/handlebars/business-hours.html';
import 'src/main/webapp/universal/scripts-v6/business-hours-utils.js';
import 'src/main/webapp/templates-v6/handlebars/mobile-info-bar.html';
import 'src/main/webapp/universal/scripts-v6/ss-badge.js';