"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SiteStatus */

/* Java class defined in repository squarespace-v6 */
var StatusConstants;

(function (StatusConstants) {
  StatusConstants["EXPIRED"] = 1;
  StatusConstants["PASTDUE"] = 2;
  StatusConstants["TRIAL"] = 3;
  StatusConstants["BETA"] = 4;
  StatusConstants["REMOVED"] = 5;
  StatusConstants["INTERNAL"] = 6;
  StatusConstants["COMPED"] = 7;
  StatusConstants["PAID"] = 8;
  StatusConstants["V5_LINKED"] = 11;
  StatusConstants["ACTIVE_PARKING_PAGE"] = 12;
  StatusConstants["RESOLD"] = 13;
  StatusConstants["RESOLD_GRACE_PERIOD"] = 14;
  StatusConstants["ENTERPRISE"] = 15;
})(StatusConstants || (StatusConstants = {}));

var _default = StatusConstants;
exports["default"] = _default;
module.exports = exports.default;