import CollectionTypes from '@sqs/enums/CollectionTypes';
import MobileInfoBarStyle from '@sqs/enums/MobileInfoBarStyle';
import PageFeatures from '@sqs/enums/PageFeatures';
import PageTypes from '@sqs/enums/PageTypes';
import SSBadgeType from '@sqs/enums/SSBadgeType';
import SSBadgeVisibility from '@sqs/enums/SSBadgeVisibility';
import WebsiteOverlaysUtils from 'shared/utils/WebsiteOverlaysUtils';
import TemplateVersionUtils from 'shared/utils/TemplateVersionUtils';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/website-overlays-manager.css';

/**
 *
 * Initialize all (frontend) overlays and alter
 * their position due to any overlaps.
 *
 * @module squarespace-website-overlays-manager
 */
YUI.add('squarespace-website-overlays-manager', function (Y) {


  /**
   * Initialize all (frontend) overlays and alter
   * their position due to any overlaps.
   *
   * @class       WebsiteOverlaysManager
   * @namespace   Squarespace.Frontend
   * @constructor
   */
  Y.namespace('Squarespace.Frontend').WebsiteOverlaysManager =
  Y.Base.create('websiteOverlaysManager', Y.Base, [], {

    initializer: function () {
      this._body = Y.one(Y.config.doc.body);

      // Do not initialize anything for Cover Pages.
      var pageType = Y.config.win.Static.SQUARESPACE_CONTEXT.pageType;

      // We want to treat 404 pages that point to cover pages like cover pages.
      if (pageType === PageTypes.NOT_FOUND &&
          Y.config.win.Static.SQUARESPACE_CONTEXT.collection &&
          Y.config.win.Static.SQUARESPACE_CONTEXT.collection.type === CollectionTypes.SPLASH_PAGE) {

        pageType = PageTypes.SPLASH_PAGE;
      }

      // For cover pages and lock screens, we only need the preview bar
      if (pageType === PageTypes.SPLASH_PAGE || pageType === PageTypes.LOCK_SCREEN) {
        this.syncPreviewBar();
        return;
      }

      this.syncPreviewBar();
      this._initializeMobileInfoBar();
      this._syncSSBadge();

      this._manageOverlaps();

      this.after('ssBadgeSettingsChange', this._syncSSBadge, this);
      this.after('mobileInfoBarSettingsChange', this.showMobileInfoBar);
    },

    syncPreviewBar: function () {
      if (Y.config.win.SQUARESPACE_LOGIN) {
        return;
      }

      var previewImages = this._body.all('img[data-licensed-asset-preview="true"]');
      var previewBackgrounds = this._body.hasClass('has-preview-getty-imgs');

      if (previewBackgrounds || previewImages.size() > 0 && !this.previewBar) {
        this.previewBar = new Y.Squarespace.LicensedAssets.Widgets.PreviewBar({
          render: true
        });
      } else if (this.previewBar) {
        this.previewBar.destroy();
        this.previewBar = null;
      }
    },

    _syncSSBadge: function () {
      if (TemplateVersionUtils.isSevenOne()) {
        return;
      }

      var ssBadgeSettings = this.get('ssBadgeSettings');
      var badgeType = ssBadgeSettings.type;

      if (badgeType === SSBadgeType.NONE) {

        if (this._ssBadge) {
          this._ssBadge.destroy();
          this._ssBadge = null;
        }

        return;
      }

      var autoHide = ssBadgeSettings.visibility === SSBadgeVisibility.SCROLL;
      var position = ssBadgeSettings.position;
      var devices = ssBadgeSettings.devices;

      if (!this._ssBadge) {
        this._ssBadge = new Y.Squarespace.Widgets.SSBadge({
          render: this._body
        });
      }

      this._ssBadge.setAttrs({
        type: badgeType,
        position: position,
        autoHide: autoHide,
        devices: devices
      });
    },


    _initializeMobileInfoBar: function () {
      var settings = this._getMobileInfoBarSettings();

      if (settings) {
        this._mobileInfoBar = new Y.Squarespace.Widgets.MobileInfoBar({
          mobileInfoBarSettings: settings,
          style: settings.style
        });

        this._mobileInfoBar.render();
      }
    },

    /**
     * Helper utility to force show the mobile info bar.
     *
     * @method showMobileInfoBar
     */
    showMobileInfoBar: function() {
      var settings = this._getMobileInfoBarSettings(true);

      this.hideMobileInfoBar();

      if (settings) {
        this._mobileInfoBar = new Y.Squarespace.Widgets.MobileInfoBar({
          mobileInfoBarSettings: settings,
          style: settings.style
        });

        this._mobileInfoBar.render();
      }
    },

    /**
     * Helper utility to force hide the mobile info bar.
     *
     * @method hideMobileInfoBar
     */
    hideMobileInfoBar: function() {

      if (this._mobileInfoBar) {
        this._mobileInfoBar.destroy();
        this._mobileInfoBar = null;
      }

    },


    _manageOverlaps: function () {

      if (TemplateVersionUtils.isSevenOne()) {
        return;
      }

      var isSSBadgeVisible = this._isSSBadgeVisible();
      var isMobileInfoBarVisible = this._isMobileInfoBarVisible();

      var ssBadgeSettings = this.get('ssBadgeSettings');

      if (ssBadgeSettings) {

        var ssBadgeVisibility = ssBadgeSettings.visibility;

        var isSSBadgeVisibleAlways = (ssBadgeVisibility === SSBadgeVisibility.ALWAYS);

        // Fix overlaps between SSBadge and MobileInfoBar
        if (isSSBadgeVisible && isMobileInfoBarVisible) {

          // If SSBadge is at the bottom and always visible,
          // adjust it's position and then restore on scroll
          if (isSSBadgeVisibleAlways) {
            this._adjustSSBadgeBottomOffset();

            Y.one(Y.config.win).on('scroll', function () {
              var scrollTop = Y.config.doc.body.scrollTop;

              if (scrollTop <= 0) {
                this._adjustSSBadgeBottomOffset();
              } else {
                this._clearSSbadgeBottomOffset();
              }
            }, this);

          }
        }
      }
    },


    _getMobileInfoBarSettings: function (isPreview) {
      var mobileInfoBarSettings = this.get('mobileInfoBarSettings');

      var output = null;

      var isVisible = isPreview || WebsiteOverlaysUtils.shouldShowMobileInformationBar({
        win: Y.config.win,
        isMobile: Y.UA.mobile,
        settings: mobileInfoBarSettings
      });

      if (!Y.Lang.isUndefined(mobileInfoBarSettings) && isVisible) {
        var hiddenState = MobileInfoBarStyle.NONE;

        mobileInfoBarSettings.style = parseInt(mobileInfoBarSettings.style, 10);

        if (mobileInfoBarSettings.style !== hiddenState) {
          output = mobileInfoBarSettings;
        }
      }

      return output;
    },


    _adjustSSBadgeBottomOffset: function () {
      this._ssBadge.set('className', 'sqs-ss-badge-mobile-info-bar-present');
    },


    _clearSSbadgeBottomOffset: function () {
      this._ssBadge.set('className', null);
    },


    _isSSBadgeVisible: function () {
      var ssBadge = this._ssBadge;
      var isVisible = false;

      if (ssBadge) {
        var visibility = ssBadge.get('boundingBox').getStyle('visibility');
        isVisible = (visibility === 'visible');
      }

      return isVisible;
    },


    _isMobileInfoBarVisible: function () {
      var mobileInfoBar = this._mobileInfoBar;
      return mobileInfoBar ? true : false;
    }

  }, {

    ATTRS: {
      /**
       * @attribute ssBadgeSettings
       * @type      Object
       * @default Get ss badge settings from websiteContext
       */
      ssBadgeSettings: {
        getter: function (val) {
          val = val || Y.Object.getValue(Y.config.win.Static, [
            'SQUARESPACE_CONTEXT',
            'websiteSettings'
          ]);

          return {
            visibility: val.ssBadgeVisibility,
            position: val.ssBadgePosition,
            devices: val.ssBadgeDevices,
            type: val.ssBadgeType
          };
        }
      },

      /**
       * @attribute mobileInfoBarSettings
       * @type      Object
       */
      mobileInfoBarSettings: {
        valueFn: function () {
          return Y.Object.getValue(Y.config.win.Static, [
            'SQUARESPACE_CONTEXT',
            'websiteSettings',
            'mobileInfoBarSettings'
          ]);
        }
      }
    }
  });


  Y.config.win.Squarespace.onInitialize(Y, function() {

    var pageFeatures = Y.config.win.Static.SQUARESPACE_CONTEXT.pageFeatures || [];

    if (pageFeatures.indexOf(PageFeatures.OVERLAYS) !== -1) {

      if (Y.Squarespace.Singletons.WebsiteOverlaysManager) {

        // OverlaysManager already exists, just sync preview bar
        // (which may be different between pages)
        Y.Squarespace.Singletons.WebsiteOverlaysManager.syncPreviewBar();

      } else {

        // OverlaysManager doesn't exist, initialize it
        Y.Squarespace.Singletons.WebsiteOverlaysManager = new Y.Squarespace.Frontend.WebsiteOverlaysManager();

      }

    }

  });

}, '1.0', {
  requires: [
    'base',
    'node',
    'squarespace-licensed-assets-preview-bar',
    'squarespace-mobile-info-bar',
    'squarespace-ss-badge'
  ]
});
