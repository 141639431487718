"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.math.log10.js");

var _default = function _default(bytes, decimals) {
  // Thanks stack overflow!
  // http://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
  if (bytes === 0) {
    return '0 Bytes';
  }

  var k = 1024;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var exp = Math.floor(Math.log(bytes) / Math.log(k));
  var size = bytes / Math.pow(k, exp);
  var intLen = Math.ceil(Math.log10(size));
  var totLen = decimals + intLen || intLen;
  return size.toPrecision(totLen) + ' ' + sizes[exp];
};

exports.default = _default;
module.exports = exports.default;