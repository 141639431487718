"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isStage = exports.isQA = exports.isInternal = exports.isDev = exports.getDomain = void 0;

require("core-js/modules/web.url.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/es.array.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

var getDomain = function getDomain() {
  var _ref, _windowOrUrlString$St, _windowOrUrlString$St2, _windowOrUrlString$St3, _windowOrUrlString$lo;

  var windowOrUrlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;

  if (typeof windowOrUrlString === 'string') {
    var url = new URL(windowOrUrlString);
    return url.hostname;
  }

  return (_ref = (_windowOrUrlString$St = (_windowOrUrlString$St2 = windowOrUrlString.Static) === null || _windowOrUrlString$St2 === void 0 ? void 0 : (_windowOrUrlString$St3 = _windowOrUrlString$St2.SQUARESPACE_CONTEXT) === null || _windowOrUrlString$St3 === void 0 ? void 0 : _windowOrUrlString$St3.appDomain) !== null && _windowOrUrlString$St !== void 0 ? _windowOrUrlString$St : (_windowOrUrlString$lo = windowOrUrlString.location) === null || _windowOrUrlString$lo === void 0 ? void 0 : _windowOrUrlString$lo.host) !== null && _ref !== void 0 ? _ref : '';
};

exports.getDomain = getDomain;

var isInternal = function isInternal() {
  var windowOrUrlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;
  return /\.(sqsp|squarespace)\.net$/.test(getDomain(windowOrUrlString));
};

exports.isInternal = isInternal;

var isStage = function isStage() {
  var windowOrUrlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;
  return /stage\.(sqsp|squarespace)\.net$/.test(getDomain(windowOrUrlString));
};

exports.isStage = isStage;

var isQA = function isQA() {
  var windowOrUrlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;
  return /qa[0-9]+\.(sqsp|squarespace)\.net$/.test(getDomain(windowOrUrlString));
};

exports.isQA = isQA;

var isDev = function isDev() {
  var windowOrUrlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;
  return /dev\.(sqsp|squarespace)\.net$/.test(getDomain(windowOrUrlString));
};

exports.isDev = isDev;