// TODO: cleanup tweak events in v6 codebase by requiring this enum.
var TweakEvents = {
  CHANGE: 'tweak:change',
  // TODO: implement in tweak v2
  CLOSE: 'tweak:close',
  // TODO: implement in tweak v2
  SAVE: 'tweak:save',
  // TODO: implement in tweak v2
  RESET: 'tweak:reset',
  // TODO: remove
  AFTER_SHOW: 'tweak:aftershow',
  // TODO: remove
  AFTER_CLOSE: 'tweak:afterclose',
  // TODO: determine if needs to be implemented in tweak v2
  BEFORE_OPEN: 'tweak:beforeopen'
};
export default TweakEvents;