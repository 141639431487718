"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveSessionData = exports.removeSessionData = exports.getSessionData = void 0;

var _isNumber2 = _interopRequireDefault(require("lodash/isNumber"));

var _cookieCutter = _interopRequireDefault(require("@sqs/cookie-cutter"));

// Simple utility methods to create an easy session storage API
// Defaults to localStorage and falls back to cookies
// Can be used to easily expire localStorage data
var hasLocalStorage = function hasLocalStorage() {
  return !!window.localStorage;
};

var saveSessionData = function saveSessionData(key, value, expires) {
  try {
    var data = {
      value: value,
      expires: expires ? expires.getTime() : undefined
    };
    var encodedData = JSON.stringify(data);

    if (hasLocalStorage()) {
      localStorage.setItem(key, encodedData);
    } else if (expires) {
      _cookieCutter.default.set(key, encodedData, {
        path: '/',
        expires: expires.toISOString()
      });
    } else {
      _cookieCutter.default.set(key, encodedData, {
        path: '/'
      });
    }
  } catch (err) {
    console.warn(err);
  }
};

exports.saveSessionData = saveSessionData;

var getSessionData = function getSessionData(key) {
  try {
    var encodedData = hasLocalStorage() ? localStorage.getItem(key) : _cookieCutter.default.get(key);

    if (encodedData) {
      var data = JSON.parse(encodedData);

      if (hasLocalStorage() && (0, _isNumber2.default)(data.expires) && data.expires < new Date().getTime()) {
        localStorage.removeItem(key);
        return null;
      }

      return data.value;
    }
  } catch (err) {
    console.warn(err);
  }

  return null;
};

exports.getSessionData = getSessionData;

var removeSessionData = function removeSessionData(key) {
  try {
    if (hasLocalStorage()) {
      localStorage.removeItem(key);
    } else {
      _cookieCutter.default.set(key, '', {
        expires: new Date(0).toISOString()
      });
    }
  } catch (err) {
    console.warn(err);
  }
};

exports.removeSessionData = removeSessionData;