import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _SlideTypeNames;

import SlideType from '@sqs/enums/SlideType';
/**
 * A client-side mapping from SlideType numeric values to their string values.
 */

var SlideTypeNames = (_SlideTypeNames = {}, _defineProperty(_SlideTypeNames, SlideType.COVER_PAGE, 'cover-page'), _defineProperty(_SlideTypeNames, SlideType.LOCK_SCREEN, 'lock-screen'), _defineProperty(_SlideTypeNames, SlideType.POPUP_OVERLAY, 'popup-overlay'), _SlideTypeNames);
export default SlideTypeNames;