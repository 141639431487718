import { t } from 'shared/i18n';
import SSBadgeDevices from '@sqs/enums/SSBadgeDevices';
import SSBadgePosition from '@sqs/enums/SSBadgePosition';
import SSBadgeType from '@sqs/enums/SSBadgeType';
import debounce from 'lodash/debounce';

/**
 * The 'Made With Squarespace' badge that displays on a users's site when
 * configured to in General Settings.
 * @module squarespace-ss-badge
 */
YUI.add('squarespace-ss-badge', function (Y) {

  var STYLE_STRINGS = {};
  STYLE_STRINGS[SSBadgeType.BLACK] = 'black';
  STYLE_STRINGS[SSBadgeType.WHITE] = 'white';

  var POSITION_STRINGS = {};
  POSITION_STRINGS[SSBadgePosition.TOP_LEFT] = 'top-left';
  POSITION_STRINGS[SSBadgePosition.TOP_CENTER] = 'top-center';
  POSITION_STRINGS[SSBadgePosition.TOP_RIGHT] = 'top-right';
  POSITION_STRINGS[SSBadgePosition.BOTTOM_LEFT] = 'bottom-left';
  POSITION_STRINGS[SSBadgePosition.BOTTOM_CENTER] = 'bottom-center';
  POSITION_STRINGS[SSBadgePosition.BOTTOM_RIGHT] = 'bottom-right';

  var DEVICES_STRINGS = {};
  DEVICES_STRINGS[SSBadgeDevices.ALL] = 'all-devices';
  DEVICES_STRINGS[SSBadgeDevices.DESKTOP_ONLY] = 'desktop-only';

  var DEBOUNCE_WAIT_TIME = 50;

  Y.namespace('Squarespace.Widgets');

  var SSBadge =
  /**
   * The 'Made With Squarespace' badge that displays on a users's site when
   * configured to in General Settings.
   * @class       SSBadge
   * @extends     Squarespace.Widgets.SSWidget
   * @namespace   Squarespace.Widgets
   * @constructor
   */
  Y.Squarespace.Widgets.SSBadge = Y.Base.create('SSBadge',
  Y.Squarespace.Widgets.SSWidget,
  [],
  {
    initializer: function () {
      this._coverNode = Y.Node.create('<div class="sqs-ss-badge-cover"></div>');

      this.after('render', function () {
        Y.Global.fire('website-overlay:rendered', {
          selector: '.' + this.getProperty('CSS_PREFIX') });

      });

      this.resize = debounce(this.handleResize.bind(this), DEBOUNCE_WAIT_TIME);

      Y.config.win.addEventListener('resize', this.resize);
    },

    destructor: function () {
      this._coverNode.remove(true);
      Y.config.win.removeEventListener('resize', this.resize);
    },

    handleResize: function () {
      if (this.isMobile()) {
        this.get('boundingBox').addClass('is-mobile');
      } else {
        this.get('boundingBox').removeClass('is-mobile');
      }
    },

    isMobile: function () {
      // In some instances Y.config.win is not present
      if (!Y.config || !Y.config.win) {
        return Y.UA.mobile;
      }

      var innerWidth = Y.config.win.innerWidth;
      var isSmallerThanDesktop = innerWidth <= 768;

      return Y.UA.mobile || isSmallerThanDesktop;
    },

    renderUI: function () {
      SSBadge.superclass.renderUI.call(this);

      if (this.isMobile()) {
        this.get('boundingBox').addClass('is-mobile');
      }

      this.get('contentBox').append(
      '<a href="' + this.get('goTo') + '" target="_blank">' +
      '<div class="badge-closed"><div class="badge-symbol"></div></div>' +
      '<div class="badge-open"><div class="badge-open-inner">' +
      '<h2>' + t("Powered by Squarespace") +

      '</h2>' +
      '</div></div>' +
      '</a>');


      Y.one('body').append(this._coverNode);
    },

    syncUI: function () {
      SSBadge.superclass.syncUI.call(this);

      var boundingBox = this.get('boundingBox');

      boundingBox.setAttribute('data-type', STYLE_STRINGS[this.get('type')]);
      boundingBox.setAttribute('data-position', POSITION_STRINGS[this.get('position')]);
      boundingBox.setAttribute('data-devices', DEVICES_STRINGS[this.get('devices')]);

      if (this.get('autoHide')) {
        boundingBox.addClass('badge-auto-hide');
        this._checkAutoHide();
      } else {
        boundingBox.removeClass('badge-auto-hide');
        boundingBox.addClass('badge-visible');
      }
    },

    bindUI: function () {
      var contentBox = this.get('contentBox');

      this.after(['typeChange', 'positionChange', 'autoHideChange', 'devicesChange'], this.syncUI, this);

      contentBox.on('mouseenter', function () {
        contentBox.addClass('badge-is-open');
      });

      contentBox.on('mouseleave', function () {
        contentBox.removeClass('badge-is-open');
      });

      this._registerEvent(Y.one(Y.config.win).on('scroll', this._checkAutoHide, this));
    },

    _checkAutoHide: function () {
      if (!this.get('autoHide')) {
        return;
      }

      var boundingBox = this.get('boundingBox');
      var body = Y.one('body');
      var html = Y.one('html');

      var scrollTop = html.get('scrollTop') || body.get('scrollTop');
      var scrollHeight = html.get('scrollHeight') || body.get('scrollHeight');

      var innerHeight = Y.config.win.innerHeight;

      var bottomBadgeInView = scrollTop > scrollHeight - innerHeight - 50;

      // we use a visible class rather than Y.Widget's built-in show/hide
      // because adding a hide class (as opposed to a visibile class)
      // complicates certain transform/translate functionality
      if (this._isPositionTop() && scrollTop < 50 || bottomBadgeInView) {
        boundingBox.addClass('badge-visible');
      } else {
        boundingBox.removeClass('badge-visible');
      }
    },

    _isPositionTop: function () {
      var topPositions = [
      SSBadgePosition.TOP_LEFT,
      SSBadgePosition.TOP_CENTER,
      SSBadgePosition.TOP_RIGHT];


      return topPositions.indexOf(this.get('position')) !== -1;
    } },

  {
    CSS_PREFIX: 'sqs-ss-badge',
    ATTRS: {
      /**
       * The badge type. Dictates the badge color.
       * @attribute type
       * @type      Squarespace.SSBadgeType
       */
      type: {
        value: SSBadgeType.BLACK,
        validator: function (value) {
          if (!Y.Object.hasValue(SSBadgeType, value)) {
            if (__DEV__) {
              console.error('SSBadge: Invalid style. Must be a SSBadgeType.');
            }
            return false;
          }

          return true;
        } },


      /**
       * The position of the badge with regards to the window.
       * @attribute position
       * @type      Squarespace.SSBadgePosition
       */
      position: {
        value: SSBadgePosition.BOTTOM_LEFT,
        validator: function (value) {
          if (!Y.Object.hasValue(SSBadgePosition, value)) {
            if (__DEV__) {
              console.error('SSBadge: Invalid position. Must be a SSBadgePosition.');
            }
            return false;
          }

          return true;
        } },


      /**
       * Whether or not the badge should auto-hide when not needed.
       * @attribute autoHide
       * @type      Boolean
       */
      autoHide: {
        value: true,
        validator: Y.Squarespace.AttrValidators.isBoolean },


      /**
       * Which devices to show this badge on.
       * @attribute devices
       * @type      Squarespace.SSBadgeDevices
       */
      devices: {
        value: SSBadgeDevices.ALL,
        validator: function (value) {
          if (!Y.Object.hasValue(SSBadgePosition, value)) {
            if (__DEV__) {
              console.error('SSBadge: Invalid devices. Must be a SSBadgeDevices.');
            }
            return false;
          }

          return true;
        } },


      goTo: {
        valueFn: function () {
          var queryParams = Y.QueryString.stringify({
            channel: 'word_of_mouth',
            subchannel: 'customer',
            source: 'badge',
            campaign: Y.Object.getValue(Static, ['SQUARESPACE_CONTEXT', 'website', 'id']) });


          return 'https://www.squarespace.com?' + queryParams;
        },
        validator: Y.Squarespace.AttrValidators.isString,
        readOnly: true } } });




}, '1.0', {
  requires: [
  'base',
  'node',
  'squarespace-attr-validators',
  'squarespace-ss-widget'] });