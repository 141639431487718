import { get } from 'lodash';

/**
 * Is context for production environment?
 *
 * @return {Boolean}
 */
const isProductionEnvironment = () => {
  const appDomain = get(window, 'Static.SQUARESPACE_CONTEXT.appDomain', '');
  return appDomain && (appDomain.indexOf('sqsp.com') !== -1 || appDomain.indexOf('squarespace.com') !== -1);
};

export default {
  isProductionEnvironment,
};
