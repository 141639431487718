import { t } from 'shared/i18n';
import * as weekdayUtils from 'shared/i18n/weekdayUtils';
/**
 * Renders business hours
 *
 * @module squarespace-business-hours
 */
YUI.add('squarespace-business-hours', function (Y) {

  /**
   * Business hours widget
   *
   * @class BusinessHours
   * @constructor
   * @namespace Squarespace.Widgets
   * @extends Squarespace.Widgets.SSWidget
   */
  Y.namespace('Squarespace.Widgets').BusinessHours =
  Y.Base.create('businessHours', Y.Squarespace.Widgets.SSWidget, [], {

    _setDefaultBusinessHours: function (val) {
      var settings = this.get('websiteSettings');

      if (Y.Lang.isNull(val)) {// @todo: fix this so it doesn't grab hours from two possible sources
        val = settings.businessHours ? settings.businessHours : settings.get('businessHours');
      }

      return val;
    } },

  {

    CSS_PREFIX: 'sqs-business-hours',
    HANDLEBARS_TEMPLATE: 'business-hours.html',

    ATTRS: {
      websiteSettings: {
        valueFn: function () {
          return Y.Squarespace.Context ? // @todo: fix this so that it listens to the store instead of Y.SQSP.Context
          Y.Squarespace.Context.getInstance().get('websiteSettings') :
          Static.SQUARESPACE_CONTEXT.websiteSettings;
        } },


      businessHours: {
        value: null,
        setter: '_setDefaultBusinessHours' },


      /**
       * Strings
       * @attribute strings
       * @type      {Object}
       */
      strings: {
        value: {
          store: {
            open: t("We are currently<br><span>open</span>"),


            closed: t("We are currently<br><span>closed</span>") },




          daysAbbreviated: weekdayUtils.getWeekdaysShortData() } } } });






}, '1.0', {
  requires: [
  'base',
  'node',
  'squarespace-business-hours-template',
  'squarespace-business-hours-utils',
  'squarespace-ss-widget'] });