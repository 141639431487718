"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _network = _interopRequireDefault(require("@sqs/network"));

var MultilingualAPI = {
  fetchSettings: function fetchSettings() {
    return _network.default.get('/api/multilingual/settings');
  },
  getStatus: function getStatus() {
    return _network.default.get('/api/multilingual/status');
  },
  saveLanguages: function saveLanguages(languages) {
    return _network.default.put('/api/multilingual/languages', {
      languages: languages
    });
  },
  disconnectWeglot: function disconnectWeglot() {
    return _network.default.delete('/api/multilingual/connection');
  }
};
var _default = MultilingualAPI;
exports.default = _default;
module.exports = exports.default;