"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.promise.js");

require("core-js/modules/es.object.to-string.js");

var _loadjs = _interopRequireDefault(require("loadjs"));

/**
 * @param {string} url
 * @param {object} [options={}]
 * @param {boolean} [options.async=true] explicitly set to false to
 * synchronously load a script. Browsers default to true, so only "false" has
 * any effect.
 * @param {DocumentElement} [options.doc=document] explicitly set to
 * the frame's document if you need a script to run in frame!! See the
 * ./GoogleReCaptchaAPI.ts usage.
 * @param {string} [options.id] id to add to the <script> node
 * @return {Promise} resolve/rejects based on loadJs success
 */
var _default = function _default(url) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    async: true,
    doc: document
  },
      _ref$async = _ref.async,
      async = _ref$async === void 0 ? true : _ref$async,
      _ref$doc = _ref.doc,
      doc = _ref$doc === void 0 ? document : _ref$doc;

  return new Promise(function (resolve, reject) {
    (0, _loadjs.default)(url, {
      before: function before(_, scriptEl) {
        doc.body.appendChild(scriptEl);
        /* return `false` to bypass default DOM insertion mechanism */

        return false;
      },
      success: function success() {
        return resolve();
      },
      error: function error() {
        return reject();
      },
      async: async
    });
  });
};

exports.default = _default;
module.exports = exports.default;