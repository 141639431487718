"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _network = _interopRequireDefault(require("@sqs/network"));

var ENDPOINT = '/api/site-header-footer';
var SiteHeaderFooterAPI = {
  /**
   * @return {Promise} returns request in progress to avoid requesting same
   * thing twice. Resolves with returned data.
   */
  load: function load() {
    return _network.default.get(ENDPOINT).then(function (_ref) {
      var data = _ref.data;
      return data;
    });
  },

  /**
   * @return {Promise}
   */
  save: function save(_ref2) {
    var header = _ref2.header,
        footer = _ref2.footer;
    return _network.default.put(ENDPOINT, {
      header: header,
      footer: footer
    }).then(function (_ref3) {
      var data = _ref3.data;
      return data;
    });
  }
};
var _default = SiteHeaderFooterAPI;
exports.default = _default;
module.exports = exports.default;