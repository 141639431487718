import _defineProperty from "@babel/runtime/helpers/defineProperty";
import ExternalServiceProviders from '@sqs/enums/ExternalServiceProviders';
/**
 * These Connected Accounts can be added as external link navigation items, with
 * a dynamically-populated url from the site owner's connected account.
 *
 * This contains configuration data needed by various navigation classes to
 * create the items and their dialogs.
 */

var ConnectedAccountLinkConfigurations = _defineProperty({}, ExternalServiceProviders.CHOWNOW, {
  'serviceId': ExternalServiceProviders.CHOWNOW,
  'displayName': 'ChowNow',
  'defaultLinkTitle': 'Order Now',
  'slug': 'chownow'
});

export default ConnectedAccountLinkConfigurations;