import substituteString from 'shared/utils/formatting/substituteString';
import dateFormat from 'shared/utils/dateFormat';
import CollectionTypes from '@sqs/enums/CollectionTypes';
import RecordType from '@sqs/enums/RecordType';
import FolderBehavior from '@sqs/enums/FolderBehavior';
import { GalleryOptions } from 'shared/constants/GalleryOptions';
import isBlankWithoutTags from '@sqs/utils/string/isBlankWithoutTags';
import { formatDateTime, t, pluralize } from 'shared/i18n';
import humanizeDate from 'shared/utils/humanizeDate';
import * as TemplateHelpers from 'shared/utils/TemplateHelpers';

/**
 * Template Helpers
 * A universal list of template helpers
 *
 * Please start extracting this to src/shared/utils/TemplateHelpers
 *
 * @module squarespace-template-helpers
 */
YUI.add('squarespace-template-helpers', function (Y) {

  var TemplateContextUtils = {
    get: function (context, field) {
      if (typeof context.get === 'function') {
        return context.get(field);
      }
      return context[field];

    } };


  var FORMATTERS = {
    'html': Y.Squarespace.Escaping.escapeForHtml,
    'htmltag': Y.Squarespace.Escaping.escapeForHtmlTag,
    'htmlattr': Y.Squarespace.Escaping.escapeForHtmlTag,

    'str': function (s) {
      if (s === null || typeof s === 'undefined') {return '';}
      try {
        return s.toString();
      } catch (e) {
        return '[JSONT: Can\'t format variable as string (typeof: ' + typeof s + ').]';
      }
    },

    'raw': function (x) {
      return x;
    },

    'capitalize': function (x) {
      return x.toUpperCase();
    },

    'safe': function (x) {
      if (!x) {return '';}
      return x.replace(/<.*?>/g, '');
    },

    'json': function (x) {
      return Y.JSON.stringify(x).replace(/<\/script>/g, '</scr"+"ipt>');
    },

    'json-pretty': function (x) {
      return Y.JSON.stringify(x, null, 2).replace(/<\/script>/g, '</scr"+"ipt>');
    },

    'smartypants': function (x) {
      // Change straight quotes to curly and double hyphens to em-dashes.
      x = x.replace(/(^|[-\u2014\s(\["])'/g, '$1\u2018');
      x = x.replace(/'/g, '\u2019');
      x = x.replace(/(^|[-\u2014/\[(\u2018\s])"/g, '$1\u201c');
      x = x.replace(/"/g, '\u201d');
      x = x.replace(/--/g, '\u2014');
      return x;
    },

    'slugify': function (txt) {
      return !txt ? '' : txt.replace(/[^\-a-zA-Z0-9\s]+/ig, '').replace(/\s/g, '-').toLowerCase();
    },

    'url-encode': function (str) {
      return encodeURIComponent(str);
    },

    'activate-twitter-links': function (text) {

      var LINKS_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      var TWEETS_REGEX = /(^| )@([a-zA-Z0-9_]+)/ig;
      var HASHTAG_REGEX = /(^| )#([a-zA-Z0-9_]+)/ig;

      return text.replace(LINKS_REGEX, '<a target="new" href="$1">$1</a>').
      replace(TWEETS_REGEX, '$1<a target="new" href="http://www.twitter.com/$2/">@$2</a>').
      replace(HASHTAG_REGEX, function (match) {
        return '<a target="new" href="http://www.twitter.com/search/' +
        encodeURIComponent(match) + '">' + match + '</a>';
      });

    },

    'count': function count(x) {
      var ret = x.length;
      if (!Y.Lang.isValue(ret) && typeof x === 'object') {
        ret = 0;
        for (var i in x) {if (Object.prototype.hasOwnProperty.call(x, i)) {ret++;}}
      }
      return ret || 0;
    },

    'audio-player': function (item) {
      return "<script>Y.use('squarespace-audio-player-frontend');</script>" +
      '<div class="squarespace-audio-player" data-audio-asset-url="' +
      item.structuredContent.audioAssetUrl + '" data-item-id="' + item.id +
      '" id="audio-player-' + item.id + '"></div>';
    },

    'social-button': function (item, context) {
      var website = context._LookUpStack('website');

      // To make this work with gallery collections, check the 'mainImageId' field which is only present on collections.
      var mainImageId = item.systemDataId || item.mainImageId || '';
      var assetUrl = item.assetUrl || (item.mainImage ? item.mainImage.assetUrl : '') || '';
      if (website && website.shareButtonOptions && Object.keys(website.shareButtonOptions).length !== 0) {
        return "<script>Y.use('squarespace-social-buttons');</script><div class=\"squarespace-social-buttons " +
        'button-style" data-system-data-id="' + mainImageId + '" data-asset-url="' + assetUrl +
        '" data-record-type="' + item.recordType + '" data-full-url="' + item.fullUrl + '" data-title="' +
        Y.Squarespace.Escaping.escapeForHtmlTag(item.title) + '"></div>';
      }
    },

    'social-button-inline': function (item, context) {
      var website = context._LookUpStack('website');

      // To make this work with gallery collections, check the 'mainImageId' field which is only present on collections.
      var mainImageId = item.systemDataId || item.mainImageId || '';
      var assetUrl = item.assetUrl || (item.mainImage ? item.mainImage.assetUrl : '') || '';
      if (website && website.shareButtonOptions && Object.keys(website.shareButtonOptions).length !== 0) {
        return "<script>Y.use('squarespace-social-buttons');</script><span class=\"squarespace-social-buttons " +
        'inline-style" data-system-data-id="' + mainImageId + '" data-asset-url="' + assetUrl + '" ' +
        'data-record-type="' + item.recordType + '" data-full-url="' + item.fullUrl + '" data-title="' +
        Y.Squarespace.Escaping.escapeForHtmlTag(item.title) + '"></span>';
      }
    },

    'twitter-follow-button': function (connectedAccount) {
      // If the connected account is old and doesn't have a userName set,
      // try to use the end of their profileUrl. This should be https://twitter.com/RyanCGee
      // for example.
      var userName = connectedAccount.userName || connectedAccount.profileUrl.split('/').pop();

      return substituteString(
      '<script>Y.use(\'squarespace-follow-buttons\', function(Y) { Y.on(\'domready\', function() { ' +
      'Y.Squarespace.FollowButtonUtils.renderAll(); }); });</script><div class="squarespace-follow-button" ' +
      'data-username="{userName}"></div>',
      { userName: userName });

    },

    'comments': function (item, context) {
      var settings = context._LookUpStack('websiteSettings');
      if (settings && settings.disqusShortname) {
        return '<div class="squarespace-comments" id="disqus_thread"></div>';
      }

      return '<div class="squarespace-comments" id="comments-' + item.id + '"></div>';
    },

    'comment-link': function (item, context) {
      var settings = context._LookUpStack('websiteSettings');
      if (settings && settings.disqusShortname) {
        return '<a href="' + item.fullUrl + '" class="sqs-comment-link sqs-disqus-comment-link" data-id="' +
        item.id + '"></a>';
      }
      return '<a href="' + item.fullUrl + '#comments-' + item.id + '" class="sqs-comment-link" data-id="' +
      item.id + '">' +
      item.publicCommentCount === 0 ? t("No") :



      pluralize({ one: "{%n} Comment", other: "{%n} Comments" },


      item.publicCommentCount) +



      '</a>';
    },

    'like-button': function (item, context) {
      var settings = context._LookUpStack('websiteSettings');
      if (settings && settings.simpleLikingEnabled) {
        return '<span class="sqs-simple-like" data-item-id="' + item.id + '" data-like-count="' +
        item.likeCount + '">' +
        '<span class="like-icon"></span>' +
        '<span class="like-count"></span>' +
        '</span>';
      }
    },

    'comment-count': function (item) {
      var publicCommentCount = item.publicCommentCount;

      return publicCommentCount === 0 ? t("No Comment") : pluralize({ one: "{%n} Comment", other: "{%n} Comments" },






      publicCommentCount);



    },

    'image-meta': function (x) {
      if (!x) {
        return;
      }
      var focalPoint = '0.5,0.5';
      if (x.mediaFocalPoint) {
        focalPoint = x.mediaFocalPoint.x + ',' + x.mediaFocalPoint.y;
      }

      // NOTE: WHEN WE GET NODE WORKED OUT, WE SHOULD GIVE THIS A SHOT.
      // IT SOLVES A LOT OF BROWSER ISSUES
      // -naz
      // var canvas = document.createElement('canvas');
      // canvas.width = x.originalSize.split('x')[0];
      // canvas.width = x.originalSize.split('x')[1];
      // var src = canvas.toDataURL();
      //
      // return "src="' + src + '" .....

      var alt = TemplateHelpers.getImageAlt(x);
      if (alt) {
        alt = Y.Squarespace.Escaping.escapeForHtmlTag(alt);
      }
      return 'data-image="' + x.assetUrl + '" data-src="' + x.assetUrl + '" data-image-dimensions="' + (
      x.originalSize || '') + '" data-image-focal-point="' + focalPoint + '" alt="' + alt + '" ';
    },

    'height': function (x) {
      var wh = x.split('x');
      if (wh.length !== 2) {return 'Invalid source parameter.  Pass in \'originalSize\'.';}

      var height = parseInt(wh[1], 10);

      return height;
    },

    'width': function (x) {
      var wh = x.split('x');
      if (wh.length !== 2) {return 'Invalid source parameter.  Pass in \'originalSize\'.';}

      var width = parseInt(wh[0], 10);

      return width;
    },

    'percentage-format': function (percentage) {
      var formatted = percentage.toFixed(3);
      var length = formatted.length;

      if (formatted.charAt(length - 1) === '0') {
        return formatted.substring(0, length - 1);
      }

      return formatted;
    },

    'google-calendar-url': function (x) {
      var url = 'http://www.google.com/calendar/event?action=TEMPLATE';
      var formatStr = 'YYYYMMDD[T]HHMMSS[Z]';

      // hacky way to show UTC time since the substituteString() does not take a display timezone
      var startDate = new Date(x.startDate);
      startDate.setHours(startDate.getHours() + startDate.getTimezoneOffset() / 60);
      var start = formatDateTime(startDate, formatStr);

      var endDate = new Date(x.endDate);
      endDate.setHours(endDate.getHours() + endDate.getTimezoneOffset() / 60);
      var end = formatDateTime(endDate, formatStr);

      var location;
      if (x.location) {
        location = x.location.addressLine1 + ', ' + x.location.addressLine2 + ', ' + x.location.addressCountry;
      }

      url += '&text=' + encodeURI(x.title);
      url += '&dates=' + start + '/' + end;
      if (location) {
        url += '&location=' + location;
      }
      return url;
    },

    'AbsUrl': function (value, context) {
      return TemplateContextUtils.get('base-url') + '/' + value;
    },

    'item-classes': function (value, context) {
      if (context.hash) {// sign its handlebars
        context = this;
      }

      var classes = ['hentry'];

      var promotedBlockType = TemplateContextUtils.get(context, 'promotedBlockType');
      if (promotedBlockType) {
        classes.push('promoted');
        classes.push(FORMATTERS.slugify('promoted-block-' + promotedBlockType));
      }

      var categories = TemplateContextUtils.get(context, 'categories');
      if (categories) {
        for (var i = 0; i < categories.length; i++) {
          classes.push(FORMATTERS.slugify('category-' + categories[i]));
        }
      }

      var tags = TemplateContextUtils.get(context, 'tags');
      if (tags) {
        for (var j = 0; j < tags.length; j++) {
          classes.push(FORMATTERS.slugify('tag-' + tags[j]));
        }
      }

      var author = TemplateContextUtils.get(context, 'author');
      if (!!author && !!author.displayName) {
        classes.push(FORMATTERS.slugify('author-' + author.displayName));
      }

      classes.push('post-type-' + TemplateContextUtils.get(context, 'recordTypeLabel'));

      classes.push('article-index-' + TemplateContextUtils.get(context, '@index'));

      if (TemplateContextUtils.get(context, 'starred')) {
        classes.push('featured');
      }

      // product classes
      if (value.recordType === RecordType.STORE_ITEM) {

        if (Y.Squarespace.Commerce.onSale(value)) {
          classes.push('on-sale');
        }

      }

      return classes.join(' ');
    },

    'round': function (value, context, args) {
      return Math.round(parseFloat(value));
    },

    'iter': function (context) {
      return TemplateContextUtils.get(context, '@index');
    },

    // COMMERCE FORMATTERS
    'product-status': function (item) {
      if (Y.Squarespace.Commerce.soldOut(item)) {
        return '<div class="product-mark sold-out">sold out</div>';
      } else if (Y.Squarespace.Commerce.onSale(item)) {
        return '<div class="product-mark sale">sale</div>';
      }
    },

    'money-string': function (value) {
      return Y.Squarespace.Commerce.moneyString(value);
    },

    'product-price': function (item) {
      return '<div class="product-price">' + Y.Squarespace.Commerce.priceString(item) + '</div>';
    },

    'from-price': function (item) {
      return Y.Squarespace.Commerce.fromPrice(item);
    },

    'normal-price': function (item) {
      return Y.Squarespace.Commerce.normalPrice(item);
    },

    'sale-price': function (item) {
      return Y.Squarespace.Commerce.salePrice(item);
    },

    'variant-descriptor': function (variant) {
      return Y.Squarespace.Commerce.variantFormat(variant);
    },

    'color-weight': function (hexcolor) {
      if (hexcolor && hexcolor.length > 0 && hexcolor.length <= 7) {
        hexcolor = hexcolor.replace('#', '');
        return parseInt(hexcolor, 16) > 0xffffff / 2 ? 'light' : 'dark';
      }
      return '';

    },

    'summary-form-field': function (item) {
      return Y.Squarespace.Commerce.summaryFormFieldString(item);
    },

    timesince: function (value) {
      if (!Y.Lang.isNumber(value)) {
        return 'Invalid date.';
      }
      var humanizedDate = humanizeDate(value).capitalize();
      return (
        '<span class="timesince" data-date="' + value + '">' +
        humanizedDate +
        '</span>');

    },

    pluralize: function (value, args) {
      var singular;
      var plural;

      switch (args.length) {
        case 0:
          singular = '';
          plural = 's';
          break;
        case 1:
          singular = '';
          plural = args[0];
          break;
        case 2:
          singular = args[0];
          plural = args[1];
          break;
        default:
          throw new Error('pluralize got too many args');}


      return value === 1 ? singular : plural;
    } };



  /*
   * PREDICATES
   */

  // NOTE: PARAMETERIC PREDICATES REQUIRES A "?" at the end of the predicate.

  var PARAMETRIC_PREDICATES = [
  {
    name: 'collectionTypeNameEquals?',
    func: function (value, context, pred_args) {// eslint-disable-line camelcase
      return context.get('typeName') === pred_args[0]; // eslint-disable-line camelcase
    } }];



  var PREDICATES = {

    'has-multiple?': function (x) {
      return x.length > 1;
    },

    'main-image?': function (x) {
      return !!x.mainImageId || !!x.systemDataId;
    },

    'child-images?': function (x) {
      if (!!x.items && x.items.length > 0) {
        var firstChild = x.items[0];

        if (!!firstChild.mainImageId || !!firstChild.systemDataId) {
          return true;
        }
      }

      return false;
    },

    'location?': function (x) {
      return x.location && x.location.mapLat && x.location.mapLng;
    },

    'excerpt?': function (x) {
      var excerptText = x.excerpt && (x.excerpt.html || x.excerpt) || '';
      return !isBlankWithoutTags(excerptText);
    },

    'comments?': function (x, context) {
      var settings = context._LookUpStack('websiteSettings');
      var commentsOn = x.commentState === 1;

      // If comments are disabled for a single post, we still have
      // to load comments JS in the case where the post has comments
      // visible. The thread is just read-only.
      if (!commentsOn && x.publicCommentCount > 0) {
        commentsOn = true;
      }

      // If the owner has disabled comments across their site,
      // we render and load nothing.
      if (settings && !settings.commentsEnabled) {
        commentsOn = false;
      }

      return commentsOn;
    },

    'collection?': function (x) {
      return x.collection;
    },

    'collection-page?': function (x) {
      return x.collection && x.collection.type === CollectionTypes.COLLECTION_TYPE_PAGE;
    },

    'passthrough?': function (x) {
      return x.passthrough && x.sourceUrl && x.sourceUrl !== '';
    },

    'event?': function (x) {
      return x.recordType === RecordType.EVENT;
    },

    'same-day?': function (x, context) {
      try {
        var s = new window.TimezoneJS.Date(x.startDate, context.get('website.timeZone'));
        var e = new window.TimezoneJS.Date(x.endDate, context.get('website.timeZone'));
        return s.getYear() === e.getYear() && s.getMonth() === e.getMonth() && s.getDate() === e.getDate();
      } catch (ex) {
        return false;
      }
    },

    'external-link?': function (x) {
      return !!x.externalLink;
    },

    'folder?': function (x) {
      return x.collection && x.collection.folder;
    },

    'index?': function (x) {
      var folderBehavior = Y.Object.getValue(x, ['collection', 'folder', 'folderBehavior']);
      return folderBehavior === FolderBehavior.INDEX;
    },

    'variation?': function (x) {
      var folderBehavior = Y.Object.getValue(x, ['collection', 'folder', 'folderBehavior']);
      return folderBehavior === FolderBehavior.VARIATION;
    },

    'singular?': function (x) {return x === 1;},

    'plural?': function (x) {return x !== 1;},

    'disqus?': function (x, context) {
      var settings = context._LookUpStack('websiteSettings');
      return settings && settings.disqusShortname;
    },

    'serviceNameEmail?': function (x, context) {
      return x.serviceName === 'email';
    },

    'debug?': function (x, context) {
      try {
        return context.get('debug');
      } catch (err) {
        if (err.name === 'UndefinedVariable') {
          return false;
        }
        throw err;

      }
    },
    'calendar-view?': function (x, context) {
      var calendarView = context._LookUpStack('calendarView');
      return calendarView === true; // want to return clean true/false no undefined stuff
    },

    // COMMERCE PREDICATES
    'has-variants?': function (item) {
      return Y.Squarespace.Commerce.hasVariants(item);
    },

    'varied-prices?': function (item) {
      return Y.Squarespace.Commerce.variedPrices(item);
    },

    'on-sale?': function (item) {
      return Y.Squarespace.Commerce.onSale(item);
    },

    'sold-out?': function (item) {
      return Y.Squarespace.Commerce.soldOut(item);
    } };



  var PREFIX_FORMATTERS = [
  {
    name: 'output',
    func: function (value, context, args) {
      return args.join(' ');
    } },


  TemplateHelpers.prefixFormatters.video(Y),

  {
    /*
     * Usage: {@|image-color (position: topLeft|topRight|bottomLeft|bottomRight) (css property)}
     *
     * Examples:
     * {@|image-color}
     *    => data-color-topleft="#cfcfcf" data-color-topright="#d6d6d6" data-color-bottomleft="#cccccc"
     *    data-color-bottomright="#d4d4d4" data-color-center="#d39bad"
     * {@|image-color topLeft}
     *    => #cfcfcf
     * {@|image-color topLeft background-color}
     *    => background-color: #cfcfcf;
     */
    name: 'image-color',
    func: function (x, context, args) {
      var result = '';
      if (x.colorData) {
        if (args.length > 0) {
          var color = x.colorData[args[0] + 'Average'];
          if (color) {
            if (args.length === 2) {
              result = args[1] + ': #' + color;
            } else {
              result = '#' + color;
            }
          } else {
            result = substituteString(
            '{sub1} not found: valid position-- topLeft, topRight, bottomLeft, bottomRight, center',
            { sub1: args[0] });

          }
        } else {
          result = TemplateHelpers.colorDataToDataAttrs(x.colorData);
        }
      }
      return result;
    } },


  {
    name: 'child-image-meta',
    func: function (x, context, args) {
      var child = x.items[args && args[0] ? args[0] : 0];
      var focalPoint = '0.5,0.5';

      if (child.mediaFocalPoint) {
        focalPoint = child.mediaFocalPoint.x + ',' + child.mediaFocalPoint.y;
      }

      // NOTE: WHEN WE GET NODE WORKED OUT, WE SHOULD GIVE THIS A SHOT.
      // IT SOLVES A LOT OF BROWSER ISSUES
      // -naz
      // var canvas = document.createElement('canvas');
      // canvas.width = x.originalSize.split('x')[0];
      // canvas.width = x.originalSize.split('x')[1];
      // var src = canvas.toDataURL();
      //
      // return "src="' + src + '" .....

      // ALT text = title || description || filename (any of these could be undefined)
      var alt = TemplateHelpers.getImageAlt(child);
      if (alt) {
        alt = Y.Squarespace.Escaping.escapeForHtmlTag(alt);
      }
      return 'data-image="' + child.assetUrl + '" data-src="' + child.assetUrl + '" data-image-dimensions="' + (
      child.originalSize || '') + '" data-image-focal-point="' + focalPoint + '" alt="' + alt + '" ';
    } }];



  /*
  * Build content items from constants
  */
  function buildContentStringCheck(type, blockType) {
    if (blockType) {
      return function (input) {
        return input.recordType === type || input.promotedBlockType === blockType;
      };
    }
    return function (input) {
      return input.recordType === type;
    };

  }

  var ContentStringConstants = {
    'text': RecordType.TEXT,
    'image': RecordType.IMAGE,
    'quote': RecordType.QUOTE,
    'gallery': RecordType.GALLERY,
    'link': RecordType.LINK,
    'chat': RecordType.CHAT,
    'audio': RecordType.AUDIO,
    'video': RecordType.VIDEO,
    'review': RecordType.REVIEW,
    'store_item': RecordType.STORE_ITEM,
    'event': RecordType.EVENT,
    'thread': RecordType.THREAD,
    'tweet': RecordType.TWEET,
    'rss': RecordType.RSS,
    'geo': RecordType.CHECKIN,
    'delicious': RecordType.DELICIOUS,
    'kbarticle': RecordType.KBARTICLE };


  Object.keys(ContentStringConstants).forEach(function (type) {
    PREDICATES[type + '?'] = buildContentStringCheck(ContentStringConstants[type]);
  });

  PREDICATES['external-video?'] = buildContentStringCheck(RecordType.VIDEO, 'video');
  PREDICATES['video?'] = buildContentStringCheck(RecordType.VIDEO, 'video');
  PREDICATES['image?'] = buildContentStringCheck(RecordType.IMAGE, 'image');
  PREDICATES['quote?'] = buildContentStringCheck(RecordType.QUOTE, 'quote');
  PREDICATES['link?'] = buildContentStringCheck(RecordType.LINK, 'link');
  PREDICATES['quote?'] = buildContentStringCheck(RecordType.QUOTE, 'quote');
  PREDICATES['gallery?'] = buildContentStringCheck(RecordType.GALLERY, 'gallery');


  /*
   * Build promoted block type checks
   */
  function buildPromotedBlockTypeCheck(type) {
    return function (input) {
      return input.promotedBlockType === type;
    };
  }

  var blockTypes =
  ['map', 'embed', 'image', 'code', 'quote', 'twitter', 'link', 'video', 'foursquare', 'instagram', 'form'];
  for (var i = 0; i < blockTypes.length; i++) {
    var blockType = blockTypes[i];
    PREDICATES['promoted' + blockType.capitalize() + '?'] = buildPromotedBlockTypeCheck(blockType);
  }

  /*
   * Folder behavior predicates.
   */
  // PREDICATES['index?'] = function(x) { return x.folderBehavior == FolderBehavior.INDEX; };
  PREDICATES['redirect?'] = function (x) {return x.folderBehavior === FolderBehavior.REDIRECT;};
  PREDICATES['clickable?'] = function (input, context, pred_args) {// eslint-disable-line camelcase
    var folderBehavior = context._LookUpStack('folderBehavior');
    if (typeof folderBehavior === 'undefined') {return true;}
    return folderBehavior === FolderBehavior.INDEX || folderBehavior === FolderBehavior.REDIRECT;
  };

  function buildGalleryOptionCheck(opt, value) {
    return function (input, context, pred_args) {// eslint-disable-line camelcase
      return context._LookUpStack('options')[opt] === value;
    };
  }

  for (var opt in GalleryOptions) {
    var config = GalleryOptions[opt];
    switch (config.type) {
      case 'select':
        for (var name in config.options) {
          PREDICATES['gallery-' + opt + '-' + name + '?'] = buildGalleryOptionCheck(opt, name);
        }
        break;
      case 'boolean':
        PREDICATES['gallery-' + opt + '?'] = buildGalleryOptionCheck(opt, true);
        break;
      case 'slider':
      case 'multiOption':
        // do nothing
        break;
      default:
        if (__DEV__) {
          console.log('Unknown type: ' + config.type + ', (opt: ' + opt + ')');
        }
        break;}

  }

  PREDICATES['gallery-meta?'] = function (input, context, pred_args) {// eslint-disable-line camelcase
    return context._LookUpStack('options').controls || context._LookUpStack('options').indicators;
  };

  /*
   * Handlebar specific helpers
   */
  var HANDLEBARS_HELPERS = {
    'debug': function () {
      return function (optionalValue) {
        var response = 'DEBUG:\n========================\n';
        response += JSON.stringify(this || optionalValue, null, 2);
        response += 'END DEBUG:\n========================\n';

        return response;
      };
    } };



  // ------------------------------------------------------------------------
  // REGISTER THE TEMPLATE HELPERS
  // ------------------------------------------------------------------------

  Y.namespace('Squarespace');

  // HANDLEBAR SPECIFIC HELPERS
  Y.Squarespace.HANDLEBARS_HELPERS = HANDLEBARS_HELPERS;


  // REGISTER THE PREDICATES {.collection-type-page?} ... {.end}
  Y.Squarespace.TEMPLATE_PREDICATES = PREDICATES;
  Y.Squarespace.TEMPLATE_PARAMETRIC_PREDICATES = PARAMETRIC_PREDICATES;

  // REGISTER THE FORMATTERS {title|htmlattr}.
  Y.Squarespace.TEMPLATE_FORMATTERS = FORMATTERS;
  Y.Squarespace.TEMPLATE_PREFIX_FORMATTERS = PREFIX_FORMATTERS;

  Y.Squarespace.TEMPLATE_FORMATTERS.date = function (context, value, formatDate) {
    if (!Y.Lang.isNumber(value)) {
      return 'Invalid date.';
    }

    return dateFormat(new Date(value), formatDate);
  };

  Object.keys(Y.Squarespace.TEMPLATE_FORMATTERS).forEach(function (formatter) {
    Y.Handlebars.registerHelper(formatter, function (args) {
      return Y.Squarespace.TEMPLATE_FORMATTERS[formatter].
      apply(this, [this].concat(Array.prototype.slice.call(arguments)));
    });
  });

  Object.keys(Y.Squarespace.TEMPLATE_PREDICATES).forEach(function (predicate) {
    Y.Handlebars.registerHelper('if-' + predicate.replace('?', ''), function (options) {
      return Y.Handlebars.helpers.if.call(this, function () {
        return Y.Squarespace.TEMPLATE_PREDICATES[predicate](this);
      }, options);
    });
  });

  Object.keys(Y.Squarespace.HANDLEBARS_HELPERS).forEach(function (helperName) {
    Y.Handlebars.registerHelper(helperName, Y.Squarespace.HANDLEBARS_HELPERS[helperName]);
  });

}, '1.0', { requires: [
  'handlebars-base',
  'json',
  'squarespace-commerce-utils',
  'squarespace-escaping-utils',
  'substitute'] });