"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SSBadgeVisibility */

/* Java class defined in repository squarespace-v6 */
var SSBadgeVisibility;

(function (SSBadgeVisibility) {
  SSBadgeVisibility["ALWAYS"] = 1;
  SSBadgeVisibility["SCROLL"] = 2;
})(SSBadgeVisibility || (SSBadgeVisibility = {}));

var _default = SSBadgeVisibility;
exports["default"] = _default;
module.exports = exports.default;