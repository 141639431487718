import { weekdays } from './index';

// these keys end up being consumed by webapp/templates-v6/handlebars/business-hours.html
// which expects the following values exactly -- [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
// changing the business-hours template will require a cache invalidation

type WeekdayKey = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
const TEMPLATE_WEEKDAY_KEYS: WeekdayKey[] = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
];

export const getTemplateWeekdayKeys = () => {
  return TEMPLATE_WEEKDAY_KEYS;
};

export const getWeekdaysShortData = () => {
  const weekdaysShort = weekdays({ 'width': 'abbreviated' });
  return TEMPLATE_WEEKDAY_KEYS.reduce((acc: Record<WeekdayKey, number>, key: WeekdayKey, i) => {
    acc[key] = weekdaysShort[i];
    return acc;
  }, {} as Record<WeekdayKey, number>);
};
