/**
 * @see {@link https://www.adobe.io/apis/creativecloud/adobe-fonts/docs.html}
 *
 * @TODO consider switching to Frame's api/fonts/typekit/credentials to get
 * this data out of the codebase.
 *
 * Authorized for
 *    localhost
 *    squarespace.com,
 *    *.squarespace.com
 *    squarespace.net
 *    *.squarespace.net,
 *    sqsp.net
 *    *.sqsp.net
 */
var AUTH_ID = 'ss'; // eslint-disable-next-line max-len

var AUTH_TOKEN = 'BInhlQ%2Bl4i%2FTK2LNzmpe2QC%2Bdl6Qc7iJa15oy7OfSGK%2BY7lwDRam4FlQCIzQGEqAiJZo10MshaDLZWgX%2FckdPK0bOW%2FAfpkj2O5MO1d7Aul7v1RGoa7OBxfdciGAxZqjHAPKKo0fqkxxKF%2F9mM9JjA%3D%3D';
export default {
  AUTH_ID: AUTH_ID,
  AUTH_TOKEN: AUTH_TOKEN
};