import { t } from 'shared/i18n';
/**
 * The module containing Squarespace.LicensedAssets.Widgets.PreviewBar.
 * @module squarespace-licensed-assets-preview-bar
 */
YUI.add('squarespace-licensed-assets-preview-bar', function (Y) {

  Y.namespace('Squarespace.LicensedAssets.Widgets');

  /**
   * Shows a bar at the top of the page (frontend) if the current page
   * has at least one preview image.
   *
   * @class PreviewBar
   * @constructor
   * @namespace Squarespace.LicensedAssets.Widgets
   * @extends Squarespace.Widgets.SSWidget
   */
  Y.Squarespace.LicensedAssets.Widgets.PreviewBar =
  Y.Base.create('LicensedAssetPreviewBar', Y.Squarespace.Widgets.SSWidget,
  [],
  {},
  {
    CSS_PREFIX: 'sqs-licensed-asset-preview-bar',
    TEMPLATE:
    '<span>' + t("This page is using preview images from Getty Images. Please purchase them to remove the watermark.") +







    '</span>' });


}, '1.0', {
  requires: [
  'base',
  'squarespace-ss-widget'] });