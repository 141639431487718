/**
 * @return {string}
 */
export const colorDataToDataAttrs = (data) => {
  return data.topLeftAverage && data.topRightAverage &&
    data.bottomLeftAverage && data.bottomRightAverage &&
    data.centerAverage ? ` data-color-topleft="#${data.topLeftAverage}"
  data-color-topright="#${data.topRightAverage}"
  data-color-bottomleft="#${data.bottomLeftAverage}"
  data-color-bottomright="#${data.bottomRightAverage}"
  data-color-center="#${data.centerAverage}" ` : '';
};

/**
 * Remove anything in <> including the <>
 * @return {string}
 */
export const stripTags = (str) => str.replace(/<(?:.|\n)*?>/gm, '');

/**
 * ALT text = title || description || filename
 * (any of these could be undefined)
 *
 * Run this through Y.Squarespace.Escaping.escapeForHtmlTag if you plan to use
 * it to construct a tag
 *
 * @return {string} unescaped alt text
 */
export const getImageAlt = (data = {}) => {
  if (data.title && data.title.length > 0) {
    return data.title;
  }
  if (data.body && data.body.length > 0) {
    return stripTags(data.body);
  }
  if (data.filename && data.filename.length > 0) {
    return data.filename;
  }
  return '';
};

export const prefixFormatters = {};
prefixFormatters.video = (Y) => ({
  name: 'video',
  func: function videoPrefixFormatter(x, context, args) {
    const oembed = x.oembed || {};
    const {
      assetUrl,
      colorData,
      mediaFocalPoint,
    } = x;

    let loadFalse = false;
    let useColorData = false;
    if (args) {
      for (let i = 0; i < args.length; i++) {
        const arg = args[i];
        if (arg === 'load-false') {
          loadFalse = true;
        } else if (arg === 'color-data') {
          useColorData = true;
        }
      }
    }

    // opening div tag
    const loadFalseAttr = loadFalse ? 'data-load="false" ' : '';
    const htmlDataAttr = Y.Squarespace.Escaping.escapeForHtmlTag(oembed.html);
    let returnString = `<div class="sqs-video-wrapper"
      ${loadFalseAttr}
      data-html="${htmlDataAttr}"
      data-provider-name="${oembed.providerName}">`;

    if (x.overlay) {
      let overlayString = '<div class="sqs-video-overlay';
      if (!!x.mainImageId || !!x.systemDataId) {
        overlayString += '" style="opacity: 0;">';
        overlayString += '<img ' +
          'data-load="false" ' +
          'data-image="' + assetUrl + '" ' +
          'data-src="' + assetUrl + '" ' +
          'data-image-dimensions="' + (x.originalSize || '') + '" ' +
          'data-image-focal-point="' +
          (mediaFocalPoint ? mediaFocalPoint.x + ',' + mediaFocalPoint.y : '0.5,0.5') + '" ';

        if (useColorData && colorData) {
          overlayString += colorDataToDataAttrs(colorData);
        }
        overlayString += '/>';

      } else {
        overlayString += ' no-thumb" style="opacity: 0;">';
      }

      overlayString +=
              '<div class="sqs-video-opaque"></div>' +
              '<div class="sqs-video-icon"></div>';
      overlayString += '</div>';
      returnString += overlayString;
    }
    returnString += '</div>';
    return returnString;
  }
});
