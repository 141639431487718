"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SSBadgeDevices */

/* Java class defined in repository squarespace-v6 */
var SSBadgeDevices;

(function (SSBadgeDevices) {
  SSBadgeDevices["ALL"] = 1;
  SSBadgeDevices["DESKTOP_ONLY"] = 2;
})(SSBadgeDevices || (SSBadgeDevices = {}));

var _default = SSBadgeDevices;
exports["default"] = _default;
module.exports = exports.default;