import SliceType from '@sqs/enums/SliceType';
/**
 * A client-side mapping from SliceType string values to their numeric values.
 * See SliceType.java.
 */

var SliceTypeByName = {
  'heading': SliceType.HEADING,
  'sub-heading': SliceType.SUB_HEADING,
  'body': SliceType.BODY,
  'image': SliceType.IMAGE,
  'gallery': SliceType.GALLERY,
  'video': SliceType.VIDEO,
  'social-icons': SliceType.SOCIAL_ICONS,
  'buttons': SliceType.BUTTONS,
  'navigation': SliceType.NAVIGATION,
  'custom-form': SliceType.CUSTOM_FORM,
  'newsletter': SliceType.NEWSLETTER,
  'album': SliceType.ALBUM,
  'map': SliceType.MAP,
  'lock': SliceType.LOCK,
  'password': SliceType.PASSWORD,
  'twitter': SliceType.TWITTER,
  'logo': SliceType.LOGO,
  'action': SliceType.ACTION,
  'popup-overlay-action': SliceType.POPUP_OVERLAY_ACTION,
  'temp-lock-inject': SliceType.TEMP_LOCK_INJECT
};
export default SliceTypeByName;