"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCenteredPopUpOptions = void 0;

require("core-js/modules/es.array.concat.js");

var getCenteredPopUpOptions = function getCenteredPopUpOptions(_ref) {
  var w = _ref.w,
      h = _ref.h;
  var top = window.outerHeight / 2 + window.screenY - h / 2;
  var left = window.outerWidth / 2 + window.screenX - w / 2;
  return "width=".concat(w, ",height=").concat(h, ",top=").concat(top, ",left=").concat(left);
};

exports.getCenteredPopUpOptions = getCenteredPopUpOptions;