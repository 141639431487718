import _startCase from "lodash/startCase";
import _camelCase from "lodash/camelCase";
import _mapValues from "lodash/mapValues";
import _invert from "lodash/invert";
import BlockType from '@sqs/enums/BlockType';

var BlockTypeKeys = _invert(BlockType);

var BlockTypeNames = _mapValues(BlockTypeKeys, _camelCase);

var BlockTypeLabels = _mapValues(BlockTypeKeys, _startCase);

export default {
  /**
   * inverted key-value pair of BlockType
   * e.g. { 47 : 'HORIZONTAL_RULE' }
   *
   * @type {Object}
   */
  BlockTypeKeys: BlockTypeKeys,

  /**
   * BlockType inverted with lower camel case names
   * e.g. { 47: 'horizontalRule' }
   *
   * @type {Object}
   */
  BlockTypeNames: BlockTypeNames,

  /**
   * BlockType inverted with humanized labels
   * e.g. { 47: 'Horizontal Rule' }
   *
   * @type {Object}
   */
  BlockTypeLabels: BlockTypeLabels
};