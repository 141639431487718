import "core-js/modules/es.array.join.js";

/**
 * Apply this classname to any node. Clicking on this node will not change the
 * currently focused LayoutEngine Block
 */
export var BLOCK_FOCUS_IGNORE_CLASSNAME = 'sqs-prevent-block-focus-change';
/**
 * Apply this classname to any focusable node. Pressing <Esc> when this node
 * is focused will not trigger EscManager
 */

export var ESCMANAGER_IGNORE_CLASSNAME = 'escmanager-ignore';
/**
 * Classnames that block layout engine interaction from happening
 */

export var NONINTERACTIVE_CLASSNAMES = [BLOCK_FOCUS_IGNORE_CLASSNAME, ESCMANAGER_IGNORE_CLASSNAME];
/**
 * Space separated classname
 */

export var NONINTERACTIVE_CLASSNAME = NONINTERACTIVE_CLASSNAMES.join(' ');
export var TEXT_BLOCK = 'sqs-block-html';