"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.assign.js");

var _network = _interopRequireDefault(require("@sqs/network"));

var SITE_USER_XSRF_TOKEN = 'X-SiteUser-XSRF-Token';
var userSessionAxios = Object.assign(_network.default, {
  setXsrfToken: function setXsrfToken(token) {
    // Axios sometimes turns null headers into empty objects, leading to an inconvenient "[object Object]" later on
    // https://github.com/axios/axios/issues/2223
    if (token) {
      userSessionAxios.defaults.headers[SITE_USER_XSRF_TOKEN] = token;
    }
  }
});
var _default = userSessionAxios;
exports.default = _default;
module.exports = exports.default;