"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isTabKeyWithShift = exports.isTabKey = exports.isSpaceBar = exports.isSlashKey = exports.isNumericKey = exports.isEscapeKey = exports.isEnterKey = exports.isDeleteKey = exports.isCommaKey = exports.isBackspaceKey = exports.isArrowUpKey = exports.isArrowRightKey = exports.isArrowLeftKey = exports.isArrowDownKey = exports.isAlphanumericKey = exports.hasShiftModifierKey = void 0;
var KEYCODE_TAB = 9;
var KEYCODE_ENTER = 13;
var KEYCODE_UP = 38;
var KEYCODE_DOWN = 40;
var KEYCODE_LEFT = 37;
var KEYCODE_RIGHT = 39;
var KEYCODE_BACKSPACE = 8;
var KEYCODE_DELETE = 46;
var KEYCODE_ZERO = 48;
var KEYCODE_NINE = 57;
var KEYCODE_A = 65;
var KEYCODE_Z = 90;
var KEYCODE_ESC = 27;
var KEYCODE_COMMA = 188;
var KEYCODE_SLASH = 191;
var KEYCODE_SPACEBAR = 32;

var isTabKey = function isTabKey(e) {
  return e.keyCode === KEYCODE_TAB && !e.shiftKey;
};

exports.isTabKey = isTabKey;

var isTabKeyWithShift = function isTabKeyWithShift(e) {
  return e.keyCode === KEYCODE_TAB && e.shiftKey;
};

exports.isTabKeyWithShift = isTabKeyWithShift;

var isArrowDownKey = function isArrowDownKey(e) {
  return e.keyCode === KEYCODE_DOWN;
};

exports.isArrowDownKey = isArrowDownKey;

var isArrowUpKey = function isArrowUpKey(e) {
  return e.keyCode === KEYCODE_UP;
};

exports.isArrowUpKey = isArrowUpKey;

var isArrowLeftKey = function isArrowLeftKey(e) {
  return e.keyCode === KEYCODE_LEFT;
};

exports.isArrowLeftKey = isArrowLeftKey;

var isArrowRightKey = function isArrowRightKey(e) {
  return e.keyCode === KEYCODE_RIGHT;
};

exports.isArrowRightKey = isArrowRightKey;

var isEnterKey = function isEnterKey(_ref) {
  var keyCode = _ref.keyCode;
  return keyCode === KEYCODE_ENTER;
};

exports.isEnterKey = isEnterKey;

var isBackspaceKey = function isBackspaceKey(_ref2) {
  var keyCode = _ref2.keyCode;
  return keyCode === KEYCODE_BACKSPACE;
};

exports.isBackspaceKey = isBackspaceKey;

var isDeleteKey = function isDeleteKey(_ref3) {
  var keyCode = _ref3.keyCode;
  return keyCode === KEYCODE_DELETE;
};

exports.isDeleteKey = isDeleteKey;

var isEscapeKey = function isEscapeKey(_ref4) {
  var keyCode = _ref4.keyCode;
  return keyCode === KEYCODE_ESC;
};

exports.isEscapeKey = isEscapeKey;

var isNumericKey = function isNumericKey(_ref5) {
  var keyCode = _ref5.keyCode;
  return KEYCODE_ZERO <= keyCode && keyCode <= KEYCODE_NINE;
};

exports.isNumericKey = isNumericKey;

var isAlphanumericKey = function isAlphanumericKey(e) {
  var keyCode = e.keyCode;
  return isNumericKey(e) || KEYCODE_A <= keyCode && keyCode <= KEYCODE_Z;
};

exports.isAlphanumericKey = isAlphanumericKey;

var isCommaKey = function isCommaKey(_ref6) {
  var keyCode = _ref6.keyCode;
  return keyCode === KEYCODE_COMMA;
};

exports.isCommaKey = isCommaKey;

var isSpaceBar = function isSpaceBar(_ref7) {
  var keyCode = _ref7.keyCode;
  return keyCode === KEYCODE_SPACEBAR;
};

exports.isSpaceBar = isSpaceBar;

var isSlashKey = function isSlashKey(_ref8) {
  var keyCode = _ref8.keyCode;
  return keyCode === KEYCODE_SLASH;
};

exports.isSlashKey = isSlashKey;

var hasShiftModifierKey = function hasShiftModifierKey(_ref9) {
  var shiftKey = _ref9.shiftKey;
  return shiftKey;
};

exports.hasShiftModifierKey = hasShiftModifierKey;