/**
Constant Constants
@property Constants
*/
var Constants = {
  hiddenNavigationName: '_hidden',
  memberAreaNavigationName: '_memberareas',
  memberAreaNavigationTestAttr: 'navlist-member_areas',
  MEMBER_AREA_NAVIGATION_TYPE: 'member-area',
  README_PAGE_NAME: 'readme',
  MAX_SMUGMUG_GALLERY_COUNT: 20,
  MAX_SMUGMUG_GALLERY_SIZE: 50,
  MAX_BLOCKS_PER_LAYOUT: 60,
  MAX_TRACKS_IN_ALBUM: 60,
  MAX_ITEMS_IN_USER_ORDERABLE_COLLECTION: 250,
  NESTED_CATEGORIES_LIMIT: 10000,
  MAX_PRODUCTS_IN_COLLECTION: 200,
  SQUARESPACE_SEVEN_PREVIEW_COOKIE: 'squarespace_seven_preview',
  SQUARESPACE_SIX_OVERRIDE_COOKIE: 'squarespace_six_override',
  PREVIEW_FRAME_EXPANDED_COOKIE: 'seven_frame_expanded',
  DISABLE_USER_SCRIPTS_COOKIE: 'disable-user-scripts',
  IGNORED_ITEM_ANNOTATION_SELECTORS: ['sqs-simple-like', 'sqs-add-to-cart-button', 'product-variants', 'product-quantity-input', 'sqs-suppress-edit-mode'],
  REGISTERED_ACTIONS_FOR_NEXT_LOAD_STORE_KEY: 'sqs_actions_for_next_load',
  DAMASK_URL_PREVIEW_SEPARATOR: '|',

  /**
   Constant that controls the max length of some
   user input
   @property  MaxTagLength
   */
  MAX_TAG_LENGTH: 50,

  /**
   Constant that controls the max length of some
   user input
   @property  MaxCategoryLength
   */
  MAX_CATEGORY_LENGTH: 25,

  /**
   Magic password field value, used when a field has a password but
   we never use the real value. Server have the same constant to identify
   if value has changed or not.
   Not the most elegant approach, would be better to have some some more
   functionallity to identify a change then using constants.
   */
  PASSWORD_MASK: '__MASKED_FIELD__'
};
export default Constants;