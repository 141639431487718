var MEDIA_TYPES = {
  IMAGE_EMPTY: 'imageEmpty',
  IMAGE_FILE: 'imageFile',
  IMAGE_LICENSED: 'imageLicensed',
  IMAGE_REUSED: 'imageReused',
  // Asset resuse works differently from the old image reuse.
  // IMAGE_REUSE should be removed when Asset Picker launches
  ASSET_REUSED: 'assetReused',
  VIDEO: 'video'
};
export default MEDIA_TYPES;