import PopupOverlayStyle from '@sqs/enums/PopupOverlayStyle';
import CollectionTypes from '@sqs/enums/CollectionTypes';
import PageTypes from '@sqs/enums/PageTypes';
import MobileInfoBarStyle from '@sqs/enums/MobileInfoBarStyle';
import SSBadgeType from '@sqs/enums/SSBadgeType';
import isNumber from 'lodash/isNumber';
import { getLocalStorageData } from 'shared/utils/LocalStorageUtils';
import { PopupOverlayLocalStorage } from '@sqs/websites-constants';
import isEmpty from 'lodash/isEmpty';
import TemplateVersionUtils from 'shared/utils/TemplateVersionUtils';

function shouldTriggerOverlayView(settings = {}) {
  const stored = getLocalStorageData(PopupOverlayLocalStorage.KEY);

  if (stored && !isEmpty(stored)) {
    const version = stored[PopupOverlayLocalStorage.VERSION];

    if (version && version < settings.version) {
      return true;
    }

    const submitted = stored[PopupOverlayLocalStorage.SUBMITTED];
    if (submitted && settings.showUntilSignup) {
      return false;
    }

    const currentDate = Date.now();
    const dateClosed = stored[PopupOverlayLocalStorage.CLOSED_DATE];

    if (dateClosed) {
      const expirationDate = new Date(dateClosed);
      expirationDate.setDate(expirationDate.getDate() + settings.displayFrequency);

      if (expirationDate < currentDate && settings.displayFrequency !== -1) {
        return true;
      }
    }

    return false;
  }

  return true;
}

function shouldShowPopupOverlay({ settings = {}, context = {}, isMobile = false, urlSearch = '' }) {
  const is404CoverPage = context.pageType === PageTypes.NOT_FOUND &&
    context.collection &&
    context.collection.type === CollectionTypes.SPLASH_PAGE;

  const isErrorLockPage = is404CoverPage ||
    context.pageType === PageTypes.LOCK_SCREEN;

  if (((settings.enableMobile === false) && isMobile) ||
      (!settings.style || settings.style === PopupOverlayStyle.NONE) ||
      isErrorLockPage ||
      urlSearch.includes('sqsscreenshot=true')) {
    return false;
  }

  return shouldTriggerOverlayView(settings);
}

/**
 * Should the Squarespace Badge be shown?
 *
 * @method shouldShowSSBadge
 * @param  {Object} options.websiteSettings The website settings
 * @return {Boolean} Should the badge be shown?
 */
function shouldShowSSBadge({ websiteSettings }) {
  if (TemplateVersionUtils.isSevenOne()) {
    return false;
  }

  if (!websiteSettings.hasOwnProperty('ssBadgeType')) {
    return false;
  }

  return websiteSettings.ssBadgeType !== SSBadgeType.NONE;
}

/**
 * Should the mobile information bar be shown?
 * @method shouldShowMobileInformationBar
 * @param  {Window} options.win The window where we want to show
 * @param  {isMobile} options.isMobile Is it mobile? Uses Y.UA.mobile
 * @param  {Object} options.settings The mobile info bar settings
 * @return {boolean} Should the mobile information bar be shown?
 */
function shouldShowMobileInformationBar({ win, isMobile, settings }) {
  if (!isMobile) { // probably uses Y.UA.Mobile
    return false;
  }

  /**
   * CMS-24346 - Bumped up to support ipads
   */
  const width = win.innerWidth;
  const isMinimumResolution = width <= 1024;
  if (!isMinimumResolution) {
    return false;
  }

  if (!settings || !isNumber(settings.style)) {
    return false;
  }

  if (settings.style === MobileInfoBarStyle.NONE) {
    return false;
  }

  return true;
}

/**
 * Should the licensed asset bar be shown? This requires a YUI wrapped node so that we
 * can do .all() instead of querySelectorAll
 *
 * @method shouldShowLicensedAssetsBar
 * @param  {Object} options.YNodeBody The body wrapped in Y.Node
 * @return {Boolean} should the licensed asset bar be shown?
 */
function shouldShowLicensedAssetsBar({ YNodeBody }) {
  const previewImages = YNodeBody.all('img[data-licensed-asset-preview="true"]');
  const hasPreviewBackgrounds = YNodeBody.hasClass('has-preview-getty-imgs');

  return !previewImages.isEmpty() || hasPreviewBackgrounds;
}

function shouldShowCookieBanner({ settings = {}, urlSearch = '' }) {
  if (urlSearch.includes('sqsscreenshot=true')) {
    return false;
  }
  return settings.isCookieBannerEnabled;
}

export default {
  shouldShowPopupOverlay,
  shouldShowMobileInformationBar,
  shouldShowLicensedAssetsBar,
  shouldShowSSBadge,
  shouldShowCookieBanner
};
