import isUndefined from 'lodash/isUndefined';
import getTimeOffsetToWebsiteTimezone from 'shared/utils/getTimeOffsetToWebsiteTimezone';
import * as weekdayUtils from 'shared/i18n/weekdayUtils';
import BusinessHoursConstants from 'shared/constants/BusinessHoursConstants';
import BusinessHoursUtils from 'shared/utils/BusinessHoursUtils';

/**
 * Business hours utils
 *
 * @module squarespace-business-hours-utils
 */
YUI.add('squarespace-business-hours-utils', function (Y) {
  // Used to retrieve dates from BusinessHours
  var WEEKDAYS = weekdayUtils.getTemplateWeekdayKeys();

  function getDayLabel(date) {
    if (Y.Lang.isUndefined(date)) {
      date = new Date();
    }

    var day = date.getDay();
    return WEEKDAYS[day];
  }


  function getPreviousDayLabel(label) {
    var pos;

    Y.Array.some(WEEKDAYS, function (text, index) {
      if (text === label) {
        pos = index - 1;
        return true;
      }
    });

    pos = pos === -1 ? 6 : pos;
    return WEEKDAYS[pos];
  }


  function getTimestamp(date, websiteTzOffset) {
    var getTimeOffset = getTimeOffsetToWebsiteTimezone;

    if (Y.Lang.isUndefined(date)) {
      date = new Date();
    }

    var hours = date.getHours() * 60;
    var minutes = date.getMinutes();

    if (Y.Lang.isUndefined(websiteTzOffset)) {
      websiteTzOffset = getTimeOffset(date);
    }

    return hours + minutes + websiteTzOffset;
  }


  function detectFormat(text) {
    var mode;

    if (text.match(/am|pm/i)) {
      mode = BusinessHoursConstants.HOUR_12;
    } else {
      mode = BusinessHoursConstants.HOUR_24;
    }

    return mode;
  }


  function addPadding(val) {
    return ('0' + val).slice(-2);
  }


  function getLastRange(ranges) {
    var length = ranges.length;
    return ranges[length - 1];
  }


  function getDailyRanges(today, hours) {
    var day = hours[today];
    var output;

    if (Y.Lang.isUndefined(day) || !Y.Lang.isArray(day.ranges)) {
      output = [];
    } else {
      output = day.ranges.slice(0);
    }

    var previousDayLabel = getPreviousDayLabel(today);
    var previousDay = hours[previousDayLabel];

    var last;

    if (previousDay && Y.Lang.isArray(previousDay.ranges)) {
      last = getLastRange(previousDay.ranges);

      if (last.from > last.to) {
        output.unshift({
          from: 0,
          to: last.to
        });
      }
    }

    if (day && Y.Lang.isArray(day.ranges)) {
      last = getLastRange(day.ranges);

      if (last.from > last.to) {
        output.unshift({
          from: last.from,
          to: BusinessHoursConstants.MAX_TIMESTAMP
        });
      }
    }

    return output;
  }


  function formatTimestamp(timestamp, mode) {
    var hours = Math.floor(timestamp / 60);
    var minutes = timestamp % 60;
    var meridiem = 'am';
    var output;

    if (mode === BusinessHoursConstants.HOUR_12) {

      if (hours === 24) {
        hours = 12;
      } else if (hours >= 12) {
        hours -= 12;
        meridiem = 'pm';
      }

      if (minutes) {
        minutes = addPadding(minutes);
      }

      hours = hours ? hours : 12;
      output = hours + (minutes ? ':' + minutes : '') + meridiem;

    } else {

      if (hours === 24) {
        hours = 0;
      }

      hours = addPadding(hours);
      minutes = addPadding(minutes);
      output = hours + ':' + minutes;
    }

    return output;
  }

  /**
   * Show days in plain text.
   *
   * @param  {Object} days
   * @return {String}
   */
  Y.namespace('Squarespace.BusinessHoursUtils').plainTextDays = function (days) {
    var dayLabels = weekdayUtils.getWeekdaysShortData();
    var utils = Y.Squarespace.BusinessHoursUtils;
    var output = '';

    // Labels starting on a Monday
    var labels = WEEKDAYS.slice();
    labels = labels.concat(labels.shift());

    Y.Array.each(labels, function (label) {
      output += dayLabels[label] + ': ';
      output += utils.formatDay(days[label], {
        markup: false
      });
      output += '<br>';
    });

    return output;
  };


  /**
   * Format day ranges.
   *
   * @param  {Object} day
   * @param  {Object} config
   * @return {String}
   *
   * @method formatDay
   */
  Y.namespace('Squarespace.BusinessHoursUtils').formatDay = function (day, config) {

    var addMarkup = function (value) {
      return config.markup ? value : '';
    };

    config = Y.merge({
      closed: BusinessHoursConstants.LANG.closed,
      allDay: BusinessHoursConstants.LANG.allDay,
      delimeter: ' - ',
      markup: true
    }, config);

    var output;

    if (day && Y.Lang.isArray(day.ranges)) {
      var from;
      var to;
      var mode = detectFormat(day.text);

      output = Y.Array.reduce(day.ranges, '', function (prev, range, index, items) {
        if (BusinessHoursUtils.isClosedText(day.text) || (isUndefined(range.to) && isUndefined(range.from))) {
          prev = addMarkup('<div class="closed">' + config.closed + '</div>');
        } else if (range.from === range.to || (range.from === 0 && range.to === 1440)) {
          prev += addMarkup('<div>');

          // Convert values like 12am to 12am to 'All Day',
          // but keep original text if it's 'Open' or 'All Day'
          if (!BusinessHoursUtils.isAllDayText(day.text)) {
            prev += config.allDay;
          } else {
            prev += day.text;
          }

          prev += addMarkup('</div>');
        } else {
          from = formatTimestamp(range.from, mode);
          to = formatTimestamp(range.to, mode);

          prev += addMarkup('<div>');
          prev += from + config.delimeter + to;
          prev += addMarkup('</div>');

          if (!config.markup && index < (items.length - 1)) {
            prev += ', ';
          }
        }

        return prev;

      });

    } else {
      output = addMarkup('<div class="closed">');
      output += config.closed;
      output += addMarkup('</div>');
    }

    return output;
  };


  /**
   * Checks if store is currently
   * open or closed
   *
   * @method isStoreOpen
   * @for Squarespace.BusinessHourUtils
   * @param ranges {Object} Business hours
   * @param date {Date} Date to check
   * @return {Boolean}
   */
  Y.namespace('Squarespace.BusinessHoursUtils').isStoreOpen = function (hours, date, websiteTzOffset) {
    var timestamp = getTimestamp(date, websiteTzOffset);
    var isOpen = false;

    if (Y.Lang.isUndefined(hours)) {
      hours = Y.Squarespace.Context ? // @todo: fix this so that it listens to the store instead of Y.SQSP.Context
        Y.Squarespace.getInstance().get('websiteSettings').get('businessHours') :
        Static.SQUARESPACE_CONTEXT.websiteSettings.businessHours;
    }

    var today = getDayLabel(date);
    var ranges = getDailyRanges(today, hours);

    Y.Array.some(ranges, function (range) {
      // Open all Day
      if (Y.Lang.isUndefined(range.from)) {
        isOpen = false;
        return false;
      }
      if (range.from === range.to) {
        isOpen = true;
        return true;
      }
      if (timestamp >= range.from && timestamp <= range.to) {
        isOpen = true;
        return true;
      }
    });

    return isOpen;
  };


  /**
   * An HB helper to check if a store is
   * currently open or not.
   *
   * @param  {Object} context
   * @param  {Object} options
   * @return {String} Markup to render
   */
  Y.Handlebars.registerHelper('isStoreOpen', function (context, options) {
    var isOpen = Y.Squarespace.BusinessHoursUtils.isStoreOpen(context);

    if (isOpen) {
      return options.fn(this);
    }
    return options.inverse(this);
  });


  /**
   * An HB helper for rendering daily hours.
   *
   * @param  {Object} day
   * @param  {Object} options
   * @return {String} Markup to render
   */
  Y.Handlebars.registerHelper('dailyHours', function (day, options) {
    var utils = Y.Squarespace.BusinessHoursUtils;
    var hash = options.hash;

    var config = {
      closed: hash.closed,
      allDay: hash.allDay,
      delimeter: hash.delimeter
    };

    if (Y.Lang.isUndefined(config.closed)) {
      delete config.closed;
    }

    if (Y.Lang.isUndefined(config.allDay)) {
      delete config.allDay;
    }

    if (Y.Lang.isUndefined(config.delimeter)) {
      delete config.delimeter;
    }

    var output = utils.formatDay(day, config);

    return new Y.Handlebars.SafeString(output);
  });

}, '1.0', {
  requires: [
    'base',
    'handlebars-base',
    'node',
    'squarespace-attr-validators'
  ]
});
