import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "core-js/modules/es.array.join.js";
import { BLOCK_FOCUS_IGNORE_CLASSNAME } from './LayoutEngineClassnames';
export var EMPTY_DATA = {
  body: {
    raw: false,
    layout: {
      columns: 12,
      rows: []
    }
  }
};
/**
 * Properties pertaining to YUI Attribute change event. You may find these on
 * `e.details` if the event was fired with SetDetails.
 */

export var AttrChangeProps = {
  /**
   * The change is from a WYSIWYG editor
   */
  FROM_WYSIWYG: 'fromWysiwyg',

  /**
   * The change is from a dialog editor, e.g. YUI dialog or block schema jsf
   * editor
   */
  FROM_DIALOG: 'fromDialog' // Other things to consider are, hypothetically, FROM_UNDOREDO,
  // FROM_EXTENSION

};
/**
 * Objects to use as or extend existing event.details object on YUI events
 * related to Attribute Setting.
 *
 * @example
 *  model.set('cool', false, FROM_DIALOG);
 *  model.setAttrs({ cool: false }, Object.assign(setDetails, FROM_DIALOG));
 */

export var SetDetails = {
  FROM_DIALOG: _defineProperty({}, AttrChangeProps.FROM_DIALOG, true),
  FROM_WYSIWYG: _defineProperty({}, AttrChangeProps.FROM_WYSIWYG, true)
};
export var BlockFocusIgnoredSelectors = [".".concat(BLOCK_FOCUS_IGNORE_CLASSNAME), '#avpw_holder', '.dialog-choose-image-picker-url', '.dialog-content-type.flyout', '.dialog-minimal-video-editor.flyout', '.dialog-screen-overlay', '.image-picker-overlay', '.ReactModal__Overlay', '.sqs-image-picker', '.sqs-item-view', '.sqs-widgets-confirmation', '.sqs-widgets-confirmation-overlay', '.sqs-wysiwyg-toolbar-mobile', '.sqsp-tooltip', '.workflow-flyout', '.block-editor-schemas-code', // Portalled CC dialogs
'.CCLinkEditorModal', '.CCLinkEditorPopOver', '.CCModalOverlay', '.CCPageDropDown', // Portalled rosetta dropdowns
'[data-dropdown-overlay]', '[data-dropdown-container]'].join(', ');