import BusinessHoursConstants from 'shared/constants/BusinessHoursConstants';

const BusinessHoursUtils = {

  isAllDayText(val) {
    if (val) {
      const checkVal = val.toLocaleLowerCase().trim();
      const checkOpen = BusinessHoursConstants.LANG.open.toLocaleLowerCase().trim();
      const checkAllDay = BusinessHoursConstants.LANG.allDay.toLocaleLowerCase().trim();

      return (checkVal === checkOpen || checkVal === checkAllDay);
    }
    return false;
  },

  isClosedText(val) {
    if (val) {
      const checkVal = val.toLocaleLowerCase().trim();
      const checkClosed = BusinessHoursConstants.LANG.closed.toLocaleLowerCase().trim();

      return (checkVal === checkClosed);
    }
    return false;
  },

  /**
   * Validates that the minutes provided are in a valid format
   */
  minuteValidator(minutes = '00') {
    if (minutes.length !== 2) {
      throw new Error('Invalid minutes');
    }

    minutes = parseInt(minutes, 10);

    if (isNaN(minutes) || minutes < 0 || minutes > 59) {
      throw new Error('Invalid minutes');
    }

    return minutes;
  },

  /**
   * Validates that the hours provided are in a valid format
   */
  hourValidator(hours, mode) {
    if (!hours && hours !== 0) {
      throw new Error('Missing hours');
    }
    hours = parseInt(hours, 10);
    if (isNaN(hours)) {
      throw new Error('Invalid hour');
    }
    if (mode === BusinessHoursConstants.HOUR_24 && (hours < 0 || hours > 23)) {
      throw new Error('Invalid hour');
    } else if (mode === BusinessHoursConstants.HOUR_12 && (hours < 1 || hours > 12)) {
      throw new Error('Invalid hour');
    }
    return hours;
  },

  /**
   * Accepts a string, returns an object with both the meridiem (am/pm) and mode
   */
  getMeridiemAndMode(val) {
    const result = {};
    let chars = val;
    chars = chars.replace(/[0-9]| /g, '');
    chars = chars.replace(/:/, '');

    if (chars === 'am' || chars === 'pm') {
      result.meridiem = chars;
      result.mode = BusinessHoursConstants.HOUR_12;
    } else if (chars === '') {
      result.mode = BusinessHoursConstants.HOUR_24;
    } else {
      throw new Error('Unwanted characters');
    }
    return result;
  },

  /**
   * Converts a representation of hours to 24-hour mode
   * @param  {Number}  hours
   * @param  {Number}  mode      1 for 12-hour, 2 for 24 hour
   * @param  {String}  meridiem  'am' or 'pm'
   * @param  {String}  timeType  Whether it is the 'start' or the 'end' time
   * @return {Number}            The 24 hour representation of the hour
   */
  convertTo24HourMode(hours, mode, meridiem, timeType) {
    // Convert to 24hr for range validation purposes

    if (mode === BusinessHoursConstants.HOUR_12) {
      if (meridiem === 'pm') {
        return (hours === 12) ? hours : hours + 12;
      }
      if (timeType === 'end' && hours === 12) {
        return 24;
      }
      return (hours === 12) ? 0 : hours;
    }

    return (timeType === 'end' && hours === 0) ? 24 : hours;
  },

  /**
   * Returns an object describing the value
   * if valid, false otherwise. Supports both
   * 24hour and 12hour formats.
   *
   * @method isTime
   * @param val      {String}  The value to check
   * @param timeType {String}  Whether it is the 'start' or the 'end' time
   */
  parseTime(val, timeType) {
    let valid = true;
    let hours;
    let minutes;
    let meridiem;
    let mode;

    try {
      val = val.toLowerCase();

      const modeObj = this.getMeridiemAndMode(val);
      meridiem = modeObj.meridiem;
      mode = modeObj.mode;

      if (mode === BusinessHoursConstants.HOUR_24 && !val.match(':')) {
        throw new Error('Invalid format');
      }

      // Check that the value actually makes sense
      const time = val.replace(meridiem, '').split(':');

      // Validate hour
      hours = time[0];
      hours = this.hourValidator(hours, mode);

      // Validate minutes
      minutes = time[1];
      minutes = this.minuteValidator(minutes);
    } catch (e) {
      valid = false;
    }

    if (valid) {
      const hours24 = this.convertTo24HourMode(hours, mode, meridiem, timeType);

      return {
        mode: mode,
        timestamp: parseInt((hours24 * 60) + minutes, 10)
      };
    }

    return null;
  },

  /**
   * Checks if it's a valid time range.
   * Supports both 24hour and 12hour formats.
   *
   * Examples
   * --------
   * 8am to 5pm
   * 8am - 5pm
   * 8am – 5pm
   * 08:00 until 17:00
   * 08:00 till 17:00
   * 08:00 til 17:00
   *
   * @method: isTimeRange
   * @param {String} val The value to check
   * @return {Object} A range object
   *   @property {Number} from
   *   @property {Number} to
   */
  parseTimeRange(val) {
    let from;
    let to;

    try {
      // Case insensitive delimiters
      const items = val.split(/ to | till | til | until |-|—/i);

      // There should only be 2 valid items
      if (items.length !== 2 || !items[0] || !items[1]) {
        throw new Error('Range is not correct');
      }

      from = this.parseTime(items[0].trim(), BusinessHoursConstants.TIME_TYPE.start);
      to = this.parseTime(items[1].trim(), BusinessHoursConstants.TIME_TYPE.end);

      if (!from || !to) {
        throw new Error('Incorrect time');
      }

      if (from.mode !== to.mode) {
        throw new Error('Inconsistent formats');
      }

    } catch (e) {
      return false;
    }

    return { from, to };
  },

  /**
   * Checks if it's a valid set of comma
   * delimited time ranges.
   *
   * Examples
   * --------
   * 8am - 5pm, 6pm - 10pm
   * 07:00 - 11:00, 13:00 - 20:00
   *
   * @param {String} val The value to check
   * @return {Bool} [description]
   */
  parseMultipleTimeRange(val) {
    let valid = true;
    const data = {
      text: val,
      ranges: []
    };

    // Allow 'Open' or 'All Day'
    if (this.isAllDayText(val)) {
      val = '12am - 12am';
    }
    // Allow 'Closed'
    if (this.isClosedText(val)) {
      val = null;
    }

    try {

      if (val) {
        const ranges = val.split(',');
        let latest;
        let isLast;

        ranges.forEach((range, index) => {
          range = this.parseTimeRange(range.trim());
          isLast = index === (ranges.length - 1);

          if (!range || (!isLast && (range.from.timestamp >= range.to.timestamp))) {
            throw new Error('Invalid range');
          }

          if (data.ranges.length > 0) {
            latest = data.ranges[data.ranges.length - 1];

            if (latest.to.timestamp >= range.from.timestamp) {
              throw new Error('Invalid multiple ranges');
            }

            if (latest.to.mode !== range.to.mode) {
              throw new Error('Inconsistent formats');
            }
          }

          data.ranges.push(range);
        });
      }
    } catch (e) {
      valid = false;
    }

    // Cleanup data
    data.ranges.forEach(range => {
      range.to = range.to.timestamp;
      range.from = range.from.timestamp;
    });

    return valid ? data : false;
  }
};

export default BusinessHoursUtils;
