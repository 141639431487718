/**
 * Some blocks have variants. These are those variants
 * The mutants start at 1000
 */
var BlockVariantTypes = {
  GALLERY_SLIDESHOW: 1000,
  GALLERY_CAROUSEL: 1002,
  GALLERY_GRID: 1003,
  GALLERY_STACK: 1004,
  SUMMARY_WALL: 1005,
  SUMMARY_CAROUSEL: 1006,
  SUMMARY_LIST: 1007,
  SUMMARY_GRID: 1008,
  CHART_BAR: 1009,
  CHART_LINE: 1010,
  CHART_PIE: 1011,
  IMAGE_INLINE: 1012,
  IMAGE_POSTER: 1013,
  IMAGE_CARD: 1014,
  IMAGE_OVERLAP: 1015,
  IMAGE_COLLAGE: 1016,
  IMAGE_STACK: 1017,
  TOCK_RESERVATIONS: 1018,
  TOCK_EVENTS: 1019
};
export default BlockVariantTypes;