import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _SliceTypeNames;

import SliceType from '@sqs/enums/SliceType';
/**
 * A client-side mapping from SliceType numeric values to their string values.
 */

var SliceTypeNames = (_SliceTypeNames = {}, _defineProperty(_SliceTypeNames, SliceType.HEADING, 'heading'), _defineProperty(_SliceTypeNames, SliceType.SUB_HEADING, 'sub-heading'), _defineProperty(_SliceTypeNames, SliceType.BODY, 'body'), _defineProperty(_SliceTypeNames, SliceType.IMAGE, 'image'), _defineProperty(_SliceTypeNames, SliceType.GALLERY, 'gallery'), _defineProperty(_SliceTypeNames, SliceType.VIDEO, 'video'), _defineProperty(_SliceTypeNames, SliceType.SOCIAL_ICONS, 'social-icons'), _defineProperty(_SliceTypeNames, SliceType.BUTTONS, 'buttons'), _defineProperty(_SliceTypeNames, SliceType.NAVIGATION, 'navigation'), _defineProperty(_SliceTypeNames, SliceType.CUSTOM_FORM, 'custom-form'), _defineProperty(_SliceTypeNames, SliceType.NEWSLETTER, 'newsletter'), _defineProperty(_SliceTypeNames, SliceType.ALBUM, 'album'), _defineProperty(_SliceTypeNames, SliceType.MAP, 'map'), _defineProperty(_SliceTypeNames, SliceType.LOCK, 'lock'), _defineProperty(_SliceTypeNames, SliceType.PASSWORD, 'password'), _defineProperty(_SliceTypeNames, SliceType.TWITTER, 'twitter'), _defineProperty(_SliceTypeNames, SliceType.LOGO, 'logo'), _defineProperty(_SliceTypeNames, SliceType.ACTION, 'action'), _defineProperty(_SliceTypeNames, SliceType.POPUP_OVERLAY_ACTION, 'popup-overlay-action'), _defineProperty(_SliceTypeNames, SliceType.TEMP_LOCK_INJECT, 'temp-lock-inject'), _SliceTypeNames);
export default SliceTypeNames;