/**
 * Alignment Postions
 *
 *  -----------
 * | TL  TC  TR |
 * |            |
 * | CL  CC  TR |
 * |            |
 * | BL  BC  BR |
 *  -----------
 */
var Positions = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_CENTER: 'TOP_CENTER',
  TOP_RIGHT: 'TOP_RIGHT',
  CENTER_LEFT: 'CENTER_LEFT',
  CENTER_CENTER: 'CENTER_CENTER',
  CENTER_RIGHT: 'CENTER_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_CENTER: 'BOTTOM_CENTER',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT'
};
export default Positions;