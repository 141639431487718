"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SSBadgePosition */

/* Java class defined in repository squarespace-v6 */
var SSBadgePosition;

(function (SSBadgePosition) {
  SSBadgePosition["TOP_LEFT"] = 1;
  SSBadgePosition["TOP_CENTER"] = 2;
  SSBadgePosition["TOP_RIGHT"] = 3;
  SSBadgePosition["BOTTOM_LEFT"] = 4;
  SSBadgePosition["BOTTOM_CENTER"] = 5;
  SSBadgePosition["BOTTOM_RIGHT"] = 6;
})(SSBadgePosition || (SSBadgePosition = {}));

var _default = SSBadgePosition;
exports["default"] = _default;
module.exports = exports.default;