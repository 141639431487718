"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SSBadgeType */

/* Java class defined in repository squarespace-v6 */
var SSBadgeType;

(function (SSBadgeType) {
  SSBadgeType["NONE"] = 1;
  SSBadgeType["BLACK"] = 2;
  SSBadgeType["WHITE"] = 3;
})(SSBadgeType || (SSBadgeType = {}));

var _default = SSBadgeType;
exports["default"] = _default;
module.exports = exports.default;