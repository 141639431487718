"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.alphabetical = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es.array.sort.js");

var getStringValue = function getStringValue(item) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  if (typeof item === 'string') {
    return item;
  }

  if (typeof item[key] === 'string') {
    return item[key];
  }

  console.warn("Value is neither a string nor an object containing a string at ".concat(key), item);
  return '';
};

/**
 * @param {(string[]|object[])} arr
 * @param {object} [opts={}]
 * @param {string} [opts.key] optional key if arr is an arr of objects
 * @return {(string[]|object[])} sorted (by key if object) case-insensitive
 */
var alphabetical = function alphabetical(arr) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _toConsumableArray2.default)(arr).sort(function (a, b) {
    var lowerA = getStringValue(a, opts.key).toLowerCase();
    var lowerB = getStringValue(b, opts.key).toLowerCase();
    return lowerA < lowerB ? -1 : lowerA > lowerB ? 1 : 0;
  });
};

exports.alphabetical = alphabetical;