import MapView from '@sqs/site-rendering/Maps/MapView';
import MobileInfoBarStyle from '@sqs/enums/MobileInfoBarStyle';
import getDocScrollNode from '@sqs/utils/scroll/getDocScrollNode';
import { t } from 'shared/i18n';
import debounce from 'lodash/debounce';
import { containFocus } from '@sqs/utils/focus';

import { Keyboard } from '@sqs/universal-utils';

var isEnterKey = Keyboard.isEnterKey;
var isSpaceBar = Keyboard.isSpaceBar;
var isEscapeKey = Keyboard.isEscapeKey;

/**
 * Displays business notifications on mobiles only when
 * Configuration > Overlays > Mobile Info Information Bar
 * is enabled.
 *
 * @module squarespace-mobile-info-bar
 */
YUI.add('squarespace-mobile-info-bar', function (Y) {

  var CONTENT_LOCATION = 'location';
  var CONTENT_HOURS = 'businessHours';

  /**
   * Mobile Info bar
   *
   * @constructor
   * @namespace Squarespace.Widgets
   * @extends   Squarespace.Widgets.SSWidget
   */
  var Bar =
  Y.namespace('Squarespace.Widgets').MobileInfoBar =
  Y.Base.create('MobileInfoBar', Y.Squarespace.Widgets.SSWidget, [], {

    initializer: function () {
      this._bb = this.get('boundingBox');
      this._cb = this.get('contentBox');
      this._scrollNode = Y.one(getDocScrollNode(Y.config.doc));
      this._content = {};
      this._activeContent = null;

      this.after('render', function () {
        Y.Global.fire('website-overlay:rendered', {
          selector: '.' + this.getProperty('CSS_PREFIX') });

      });
    },

    bindUI: function () {
      Bar.superclass.bindUI.call(this);

      var triggerClass = '.' + this.getClassName('trigger[data-overlay]');
      var overlayClass = '.' + this.getClassName('overlay');
      var contentClass = '.' + this.getClassName('overlay', 'content');
      var closeClass = '.' + this.getClassName('overlay', 'close');

      this._overlay = this._cb.one(overlayClass);
      this._overlayContent = this._overlay.one(contentClass);
      this._overlayClose = this._overlay.one(closeClass);

      this._cb.delegate('click', this._handleClose, triggerClass, this);
      this._cb.delegate('keydown', function (e) {
        if (isEnterKey(e) || isSpaceBar(e)) {
          this._handleClose(e);
        }
      }, triggerClass, this);
      this._overlayClose.on('keydown', function (e) {
        if (isEnterKey(e) || isSpaceBar(e)) {
          this._handleClose(e);
        }
      }, this);
      this._overlayClose.on('click', this._handleClose, this);

      // Auto hide on scroll
      var win = Y.one(Y.config.win);
      this._registerEvent(
      win.on(
      'scroll',
      debounce(this._autoHideOrClose, 100, { leading: true, trailing: true }),
      this));



      // Disable scroll on touch
      this._overlay.on('touchmove', this._disableScroll, this);

      // Orientation change event
      if (window.orientation !== undefined) {
        Y.one(Y.config.win).on('orientationchange', this._onOrientationChange, this);
      }
    },

    syncUI: function () {
      Bar.superclass.syncUI.call(this);
      this._addStyleClass(this);
    },

    _onOrientationChange: function () {
      this._updateOverlay();
    },

    /**
     * Runs debounced
     */
    _autoHideOrClose: function () {
      if (!this._bb.getDOMNode()) {
        return;
      }

      // auto close map or hours
      if (this.activeScreen) {
        this._toggleOverlay(this._overlayClose);
      }

      // auto hide the info bar
      var scrollTop = this._scrollNode.get('scrollTop');
      var hiddenClass = this.getClassName('hide');
      var isHidden = this._bb.hasClass(hiddenClass);
      var isTop = scrollTop <= 0;

      if (isHidden && isTop) {
        this._bb.removeClass(hiddenClass);
      } else if (!isHidden && !isTop) {
        this._bb.addClass(hiddenClass);
      }
    },

    _addStyleClass: function (wrapper) {
      if (this._isBarDark()) {
        var bb = wrapper.get('boundingBox');
        bb.addClass(wrapper.getClassName('dark'));
      }
    },

    _isBarDark: function () {
      var style = this.get('style');
      var dark = MobileInfoBarStyle.DARK;
      return style === dark;
    },

    /**
     * @param {Event} e
     */
    _handleClose: function (e) {
      var button = e.currentTarget;
      this._toggleOverlay(button);
    },

    /**
     * Open or close a mobile info panel (hours, map)
     *
     * @param {Element} button (or action) that triggered the open or close
     */
    _toggleOverlay: function (button) {
      if (!button) {
        return;
      }
      var id = button.getAttribute('data-type');

      if (this._bb.hasClass(this._getShowOverlayClassName())) {
        this._hideOverlay();
      } else {
        this._renderOverlay(id);
      }
    },

    _onKeyUp: function (e) {
      if (isEscapeKey(e)) {
        e.stopPropagation();
        this._hideOverlay();
      }
    },

    _getShowOverlayClassName: function () {
      return this.getClassName('show', 'overlay');
    },

    _renderOverlay: function (id) {
      this._bb.addClass(this._getShowOverlayClassName());
      this._activeContent = this._isContentAvailable(id);

      if (!this._activeContent) {
        this._activeContent = this._createContent(id);
        this._content[id] = this._activeContent;
      }

      this._adjustCloseButton(id);
      this._activeContent.wrapper.setStyle('display', 'block');
      this._updateOverlay();

      this.activeScreen = id;
      this.revertFocusContainment = containFocus({
        container: this._overlay.getDOMNode(),
        root: Y.config.doc.body });

      this._overlay.setAttribute('aria-label', this._activeContent.title);

      // keyup is the event that Esc Manager uses,
      // so propagation should be stopped on it.
      // Closing the modal on keyup, not keydown,
      // for consistency with other modals.
      Y.one(Y.config.win.document.documentElement).on('keyup', this._onKeyUp, this);
    },

    _hideOverlay: function () {
      this._bb.removeClass(this._getShowOverlayClassName());
      this._activeContent.wrapper.setStyle('display', null);
      this.activeScreen = null;
      if (this.revertFocusContainment) {
        this.revertFocusContainment();
        this.revertFocusContainment = null;
      }
      Y.one(Y.config.win.document.documentElement).detach('keyup', this._onKeyUp);
    },

    _adjustCloseButton: function (id) {
      var isBarDark = this._isBarDark();
      var darkClass = this.getClassName('overlay', 'close', 'dark');

      if (id === CONTENT_LOCATION && !isBarDark) {
        this._overlayClose.addClass(darkClass);
      } else {
        this._overlayClose.removeClass(darkClass);
      }
    },

    _updateOverlay: function () {
      var className = this.getClassName('show', 'overlay');

      if (this._bb.hasClass(className)) {
        var scrollHeight = this._overlayContent.get('scrollHeight');
        var clientHeight = this._overlayContent.get('clientHeight');

        if (scrollHeight > clientHeight) {
          this._overlayContent.setStyle('overflow', 'scroll');
        } else {
          this._overlayContent.setStyle('overflow', null);
        }
      }
    },

    _createContent: function (id) {
      var wrapper,
      title;

      if (id === CONTENT_LOCATION) {
        wrapper = this._renderLocation();
        title = this.get('strings.labels.location');
      } else if (id === CONTENT_HOURS) {
        wrapper = this._renderBusinessHours();
        title = this.get('strings.labels.hours');
      }

      return {
        wrapper: wrapper,
        title: title };

    },

    _renderLocation: function () {
      var mapTemplate = this.get('mapTemplate');
      var wrapper = Y.Node.create(mapTemplate);

      this._overlayContent.appendChild(wrapper);

      wrapper = this._renderAddress(wrapper);
      wrapper = this._renderMap(wrapper);

      return wrapper;
    },

    _renderAddress: function (wrapper) {
      var addressClassname = '.' + this.getClassName('address');
      var address = this._getAddress();

      wrapper.one(addressClassname).setHTML(address);

      return wrapper;
    },

    _getAddress: function () {
      var location = this.get('location');
      var url = '';
      var html = '';

      var attributes = [
      'addressTitle',
      'addressLine1',
      'addressLine2',
      'addressCountry'];


      var data;

      Y.Array.each(attributes, function (attr) {
        data = location[attr];

        if (data) {
          html += '<div data-type="' + attr + '">' + data + '</div>';
          url += data + ' ';
        }
      });

      // Add a link to google maps
      if (url) {
        url = encodeURIComponent(url);
        url = '//maps.google.com?q=' + url;

        var urlClassname = this.getClassName('address', 'link');
        html += '<a rel="noopener" target="_blank" class="' + urlClassname + '" href="' + url + '"></a>';
      }

      return html;
    },


    _renderMap: function (wrapper) {
      var mapClassname = '.' + this.getClassName('map');
      var location = this.get('location');

      /*
        Remove any marker data left behind from older sites that could override
        the mapLat + mapLng, putting the marker in the wrong place.
       */
      delete location.markerLat;
      delete location.markerLng;

      // Map does not render when zoom is undefined
      if (Y.Lang.isUndefined(location.mapZoom)) {
        location.mapZoom = 12;
      }

      var mapNode = wrapper.one(mapClassname);

      /*
        [SIX-13024] Mobile Info Bar - Location map doesn't appear
        correctly on pages with an existing Map Block.
        Triggering a resize fixes this issue.
         Note: no map color style is passed here,
        and the default is grayscale:
        https://code.squarespace.net/projects/WEBM/repos/site-rendering/browse/lib/Maps/MapView.js?at=96298a8899347bec580503c9fcd5ade844698205#20
        There's a focus outline color in the corresponding LESS file
        that is supposed to contrast with the map's color scheme,
        so if the color scheme value changes here,
        CSS also needs to be updated.
       */

      MapView.createView({}, location, mapNode.getDOMNode()).
      then(function (mapView) {
        mapView.redraw();
      });

      return wrapper;
    },

    _renderBusinessHours: function () {
      var businessHours = new Y.Squarespace.Widgets.BusinessHours();

      this._addStyleClass(businessHours);
      businessHours.render(this._overlayContent);

      return businessHours.get('boundingBox');
    },

    _isContentAvailable: function (id) {
      return this._content[id];
    },

    _setDefaultContactEmail: function (val) {
      var settings = this.get('websiteSettings');
      var options = this.get('mobileInfoBarSettings');

      if (Y.Lang.isNull(val) && options.isContactEmailEnabled) {
        val = settings.contactEmail;
      }

      return val;
    },

    _setDefaultContactPhoneNumber: function (val) {
      var settings = this.get('websiteSettings');
      var options = this.get('mobileInfoBarSettings');

      if (Y.Lang.isNull(val) && options.isContactPhoneNumberEnabled) {
        val = settings.contactPhoneNumber;
      }

      return val;
    },

    _setDefaultLocation: function (val) {
      var website = this.get('website');
      var options = this.get('mobileInfoBarSettings');

      if (Y.Lang.isNull(val) && options.isLocationEnabled) {
        val = website.location;
      }

      return val;
    },

    _setDefaultBusinessHours: function (val) {
      var settings = this.get('websiteSettings');
      var options = this.get('mobileInfoBarSettings');

      if (Y.Lang.isNull(val) && options.isBusinessHoursEnabled) {
        val = settings.businessHours;
      }

      return val;
    },

    _disableScroll: function (e) {
      var contentClass = this.getClassName('overlay', 'content');

      var target = e.target;
      var ancestor = target.ancestor('.' + contentClass);
      var content = ancestor || target;
      var prevent = false;

      if (content) {
        prevent = this._overlayContent.getStyle('overflow') !== 'scroll';
      } else {
        prevent = true;
      }

      if (prevent) {
        e.preventDefault();
      }
    } },

  {

    CSS_PREFIX: 'sqs-mobile-info-bar',
    HANDLEBARS_TEMPLATE: 'mobile-info-bar.html',

    ATTRS: {
      mobileInfoBarSettings: {
        valueFn: function () {
          return Y.Object.getValue(Static, [
          'SQUARESPACE_CONTEXT',
          'websiteSettings',
          'mobileInfoBarSettings']);

        } },


      websiteSettings: {
        valueFn: function () {
          return Y.Object.getValue(Static, [
          'SQUARESPACE_CONTEXT',
          'websiteSettings']);

        } },


      website: {
        valueFn: function () {
          return Y.Object.getValue(Static, [
          'SQUARESPACE_CONTEXT',
          'website']);

        } },


      mapTemplate: {
        value: '<div>' +
        '<div class="sqs-mobile-info-bar-map-wrapper">' +
        '<div class="sqs-mobile-info-bar-map"></div>' +
        '<div class="sqs-mobile-info-bar-address"></div>' +
        '</div>' +
        '</div>' },


      contactEmail: {
        value: null,
        setter: '_setDefaultContactEmail' },


      contactPhoneNumber: {
        value: null,
        setter: '_setDefaultContactPhoneNumber' },


      location: {
        value: null,
        setter: '_setDefaultLocation' },


      businessHours: {
        value: null,
        setter: '_setDefaultBusinessHours' },


      // Light or Dark
      style: {
        value: null },


      strings: {
        value: {
          labels: {
            email: t("Email"),


            phone: t("Call"),


            location: t("Map"),


            hours: t("Hours"),


            close: t("Close") } } } } });








}, '1.0', {
  requires: [
  'base',
  'node',
  'squarespace-business-hours',
  'squarespace-damask-context-globals',
  'squarespace-mobile-info-bar-template',
  'squarespace-ss-widget'] });