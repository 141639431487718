/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-business-hours-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["business-hours.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, stack2, options, functionType="function", self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

function program1(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n    ";
  stack2 = ((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.store)),stack1 == null || stack1 === false ? stack1 : stack1.open)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n  ";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n    ";
  stack2 = ((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.store)),stack1 == null || stack1 === false ? stack1 : stack1.closed)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n  ";
  return buffer;
  }

  buffer += "\n<div class=\"sqs-business-hours-store\">\n  ";
  options = {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data};
  stack2 = ((stack1 = helpers.isStoreOpen || depth0.isStoreOpen),stack1 ? stack1.call(depth0, depth0.businessHours, options) : helperMissing.call(depth0, "isStoreOpen", depth0.businessHours, options));
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n</div>\n\n<div class=\"sqs-business-hours-day\">\n  <div class=\"sqs-business-hours-day-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.daysAbbreviated)),stack1 == null || stack1 === false ? stack1 : stack1.monday)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  <div class=\"sqs-business-hours-day-hours\">\n    ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers.dailyHours || depth0.dailyHours),stack1 ? stack1.call(depth0, ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.monday), options) : helperMissing.call(depth0, "dailyHours", ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.monday), options)))
    + "\n  </div>\n</div>\n\n<div class=\"sqs-business-hours-day\">\n  <div class=\"sqs-business-hours-day-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.daysAbbreviated)),stack1 == null || stack1 === false ? stack1 : stack1.tuesday)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  <div class=\"sqs-business-hours-day-hours\">\n    ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers.dailyHours || depth0.dailyHours),stack1 ? stack1.call(depth0, ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.tuesday), options) : helperMissing.call(depth0, "dailyHours", ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.tuesday), options)))
    + "\n  </div>\n</div>\n\n<div class=\"sqs-business-hours-day\">\n  <div class=\"sqs-business-hours-day-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.daysAbbreviated)),stack1 == null || stack1 === false ? stack1 : stack1.wednesday)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  <div class=\"sqs-business-hours-day-hours\">\n    ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers.dailyHours || depth0.dailyHours),stack1 ? stack1.call(depth0, ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.wednesday), options) : helperMissing.call(depth0, "dailyHours", ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.wednesday), options)))
    + "\n  </div>\n</div>\n\n<div class=\"sqs-business-hours-day\">\n  <div class=\"sqs-business-hours-day-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.daysAbbreviated)),stack1 == null || stack1 === false ? stack1 : stack1.thursday)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  <div class=\"sqs-business-hours-day-hours\">\n    ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers.dailyHours || depth0.dailyHours),stack1 ? stack1.call(depth0, ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.thursday), options) : helperMissing.call(depth0, "dailyHours", ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.thursday), options)))
    + "\n  </div>\n</div>\n\n<div class=\"sqs-business-hours-day\">\n  <div class=\"sqs-business-hours-day-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.daysAbbreviated)),stack1 == null || stack1 === false ? stack1 : stack1.friday)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  <div class=\"sqs-business-hours-day-hours\">\n    ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers.dailyHours || depth0.dailyHours),stack1 ? stack1.call(depth0, ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.friday), options) : helperMissing.call(depth0, "dailyHours", ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.friday), options)))
    + "\n  </div>\n</div>\n\n<div class=\"sqs-business-hours-day\">\n  <div class=\"sqs-business-hours-day-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.daysAbbreviated)),stack1 == null || stack1 === false ? stack1 : stack1.saturday)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  <div class=\"sqs-business-hours-day-hours\">\n    ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers.dailyHours || depth0.dailyHours),stack1 ? stack1.call(depth0, ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.saturday), options) : helperMissing.call(depth0, "dailyHours", ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.saturday), options)))
    + "\n  </div>\n</div>\n\n<div class=\"sqs-business-hours-day\">\n  <div class=\"sqs-business-hours-day-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.daysAbbreviated)),stack1 == null || stack1 === false ? stack1 : stack1.sunday)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n  <div class=\"sqs-business-hours-day-hours\">\n    ";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers.dailyHours || depth0.dailyHours),stack1 ? stack1.call(depth0, ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.sunday), options) : helperMissing.call(depth0, "dailyHours", ((stack1 = depth0.businessHours),stack1 == null || stack1 === false ? stack1 : stack1.sunday), options)))
    + "\n  </div>\n</div>\n";
  return buffer;
  });})()

  var filename = 'business-hours.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
