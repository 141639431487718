"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.OauthServiceProvider */

/* Java class defined in repository cms-legacy-data */
var ExternalServiceProviders;

(function (ExternalServiceProviders) {
  ExternalServiceProviders["APPLEPODCAST"] = 56;
  ExternalServiceProviders["BANDSINTOWN"] = 29;
  ExternalServiceProviders["BEHANCE"] = 30;
  ExternalServiceProviders["CHOWNOW"] = 49;
  ExternalServiceProviders["CODEPEN"] = 31;
  ExternalServiceProviders["DISCORD_UNAUTH"] = 81;
  ExternalServiceProviders["DRIBBBLE"] = 18;
  ExternalServiceProviders["DROPBOX"] = 9;
  ExternalServiceProviders["DROPBOX2"] = 40;
  ExternalServiceProviders["DROPBOX2017"] = 55;
  ExternalServiceProviders["EMAIL"] = 20;
  ExternalServiceProviders["FACEBOOK"] = 2;
  ExternalServiceProviders["FACEBOOK_UNAUTH"] = 60;
  ExternalServiceProviders["FIVEHUNDREDPIX"] = 16;
  ExternalServiceProviders["FIVEHUNDREDPIX_UNAUTH"] = 67;
  ExternalServiceProviders["FLICKR"] = 3;
  ExternalServiceProviders["FLICKR_UNAUTH"] = 63;
  ExternalServiceProviders["FOURSQUARE"] = 1;
  ExternalServiceProviders["FOURSQUARE_UNAUTH"] = 72;
  ExternalServiceProviders["GITHUB"] = 23;
  ExternalServiceProviders["GITHUB_UNAUTH"] = 73;
  ExternalServiceProviders["GOODREADS"] = 52;
  ExternalServiceProviders["GOOGLE"] = 5;
  ExternalServiceProviders["GOOGLEAUTH2"] = 21;
  ExternalServiceProviders["GOOGLEDOCS2"] = 22;
  ExternalServiceProviders["GOOGLEPLAY"] = 28;
  ExternalServiceProviders["GOOGLESEARCHCONSOLE"] = 57;
  ExternalServiceProviders["GOOGLE_SPREADSHEET"] = 6;
  ExternalServiceProviders["HOUZZ"] = 42;
  ExternalServiceProviders["IMDB"] = 51;
  ExternalServiceProviders["INSTAGRAM"] = 10;
  ExternalServiceProviders["INSTAGRAM_2019"] = 77;
  ExternalServiceProviders["INSTAGRAM_UNAUTH"] = 64;
  ExternalServiceProviders["ITUNES"] = 27;
  ExternalServiceProviders["LINKEDIN"] = 14;
  ExternalServiceProviders["LINKEDIN_UNAUTH"] = 65;
  ExternalServiceProviders["MAILCHIMP"] = 13;
  ExternalServiceProviders["MEDIUM"] = 32;
  ExternalServiceProviders["MEETUP"] = 33;
  ExternalServiceProviders["PAYPAL_LIVE_BILLING"] = 48;
  ExternalServiceProviders["PAYPAL_LIVE_ONBOARDING"] = 46;
  ExternalServiceProviders["PAYPAL_SANDBOX_BILLING"] = 47;
  ExternalServiceProviders["PAYPAL_SANDBOX_ONBOARDING"] = 45;
  ExternalServiceProviders["PINTEREST"] = 19;
  ExternalServiceProviders["PINTEREST_UNAUTH"] = 61;
  ExternalServiceProviders["RDIO"] = 34;
  ExternalServiceProviders["REDDIT"] = 43;
  ExternalServiceProviders["SMUGMUG"] = 17;
  ExternalServiceProviders["SMUGMUG_UNAUTH"] = 75;
  ExternalServiceProviders["SNAPCHAT"] = 50;
  ExternalServiceProviders["SOUNDCLOUD"] = 25;
  ExternalServiceProviders["SOUNDCLOUD_UNAUTH"] = 70;
  ExternalServiceProviders["SPOTIFY"] = 26;
  ExternalServiceProviders["SPOTIFY_UNAUTH"] = 71;
  ExternalServiceProviders["SQUARE"] = 76;
  ExternalServiceProviders["SQUARE_SANDBOX"] = 78;
  ExternalServiceProviders["STITCHER"] = 53;
  ExternalServiceProviders["STRIPE"] = 15;
  ExternalServiceProviders["STUMBLEUPON"] = 44;
  ExternalServiceProviders["THEDOTS"] = 58;
  ExternalServiceProviders["TIDAL"] = 54;
  ExternalServiceProviders["TIKTOK_UNAUTH"] = 82;
  ExternalServiceProviders["TRIPADVISOR"] = 59;
  ExternalServiceProviders["TUMBLR"] = 8;
  ExternalServiceProviders["TUMBLR_UNAUTH"] = 66;
  ExternalServiceProviders["TWITCH"] = 35;
  ExternalServiceProviders["TWITTER"] = 4;
  ExternalServiceProviders["TWITTER_UNAUTH"] = 62;
  ExternalServiceProviders["URL"] = 74;
  ExternalServiceProviders["VEVO"] = 36;
  ExternalServiceProviders["VIMEO"] = 12;
  ExternalServiceProviders["VIMEO_UNAUTH"] = 68;
  ExternalServiceProviders["VINE"] = 37;
  ExternalServiceProviders["VSCO"] = 38;
  ExternalServiceProviders["WEGLOT"] = 80;
  ExternalServiceProviders["XERO"] = 24;
  ExternalServiceProviders["YELP"] = 39;
  ExternalServiceProviders["YOUTUBE"] = 11;
  ExternalServiceProviders["YOUTUBE_UNAUTH"] = 69;
  ExternalServiceProviders["FACEBOOK_COMMERCE"] = 79;
})(ExternalServiceProviders || (ExternalServiceProviders = {}));

var _default = ExternalServiceProviders;
exports["default"] = _default;
module.exports = exports.default;