"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.PageType */

/* Java class defined in repository squarespace-v6 */
var PageTypes;

(function (PageTypes) {
  PageTypes["MAIN_CONTENT"] = 1;
  PageTypes["CONTENT_COLLECTION"] = 1;
  PageTypes["PAGE"] = 2;
  PageTypes["SPLASH_PAGE"] = 3;
  PageTypes["CONTENT_ITEM"] = 50;
  PageTypes["NOT_FOUND"] = 100;
  PageTypes["ERROR"] = 101;
  PageTypes["SEARCH"] = 102;
  PageTypes["LOCK_SCREEN"] = 103;
  PageTypes["POPUP_OVERLAY"] = 104;
  PageTypes["PROTECTED_CONTENT"] = 105;
  PageTypes["MEMBER_AREA_ACCESS_DENIED"] = 106;
  PageTypes["SHOW_CART"] = 200;
  PageTypes["CHECKOUT"] = 201;
  PageTypes["ORDER_CONFIRMED"] = 202;
  PageTypes["DONATE"] = 203;
  PageTypes["CONTRIBUTION_CONFIRMED"] = 204;
  PageTypes["COMMERCE_CART_V2"] = 205;
  PageTypes["SUBSCRIPTION_CONFIRMED"] = 206;
  PageTypes["ORDER_RECEIVED"] = 207;
  PageTypes["MEMBERSHIP_CONFIRMED"] = 208;
  PageTypes["REVIEWS_REQUEST"] = 209;
  PageTypes["DIGITAL_PRODUCT_COMPOSER_PREVIEW"] = 210;
  PageTypes["NEWSLETTER_UNSUBSCRIBE"] = 300;
  PageTypes["COMMERCE_EMAIL_PREVIEW"] = 301;
})(PageTypes || (PageTypes = {}));

var _default = PageTypes;
exports["default"] = _default;
module.exports = exports.default;