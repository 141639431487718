/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-mobile-info-bar-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["mobile-info-bar.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <div class=\"sqs-mobile-info-bar-trigger\" data-type=\"contactEmail\">\n      <a href=\"mailto:";
  if (stack1 = helpers.contactEmail) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.contactEmail; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\">\n        <span class=\"sqs-mobile-info-bar-trigger-icon\"></span>\n        <span class=\"sqs-mobile-info-bar-trigger-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.labels)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n      </a>\n    </div>\n  ";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <div class=\"sqs-mobile-info-bar-trigger\" data-type=\"contactPhoneNumber\">\n      <a href=\"tel:";
  if (stack1 = helpers.contactPhoneNumber) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.contactPhoneNumber; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\">\n        <span class=\"sqs-mobile-info-bar-trigger-icon\"></span>\n        <span class=\"sqs-mobile-info-bar-trigger-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.labels)),stack1 == null || stack1 === false ? stack1 : stack1.phone)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n      </a>\n    </div>\n  ";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <div\n      tabindex=\"0\"\n      role=\"button\"\n      class=\"sqs-mobile-info-bar-trigger\"\n      data-type=\"location\"\n      data-overlay=\"true\"\n    >\n      <span class=\"sqs-mobile-info-bar-trigger-icon\"></span>\n      <span class=\"sqs-mobile-info-bar-trigger-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.labels)),stack1 == null || stack1 === false ? stack1 : stack1.location)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n    </div>\n  ";
  return buffer;
  }

function program7(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <div\n      tabindex=\"0\"\n      role=\"button\"\n      class=\"sqs-mobile-info-bar-trigger\"\n      data-type=\"businessHours\"\n      data-overlay=\"true\"\n    >\n      <span class=\"sqs-mobile-info-bar-trigger-icon\"></span>\n      <span class=\"sqs-mobile-info-bar-trigger-label\">"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.labels)),stack1 == null || stack1 === false ? stack1 : stack1.hours)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n    </div>\n  ";
  return buffer;
  }

  buffer += "\n<div class=\"sqs-mobile-info-bar-triggers\">\n\n  ";
  stack1 = helpers['if'].call(depth0, depth0.contactEmail, {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n\n  ";
  stack1 = helpers['if'].call(depth0, depth0.contactPhoneNumber, {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n\n  ";
  stack1 = helpers['if'].call(depth0, depth0.location, {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n\n  ";
  stack1 = helpers['if'].call(depth0, depth0.businessHours, {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n</div>\n\n<div\n  class=\"sqs-mobile-info-bar-overlay\"\n  role=\"dialog\"\n>\n  <div\n    tabindex=\"0\"\n    role=\"button\"\n    aria-label=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.strings),stack1 == null || stack1 === false ? stack1 : stack1.labels)),stack1 == null || stack1 === false ? stack1 : stack1.close)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"\n    class=\"sqs-mobile-info-bar-overlay-close\"\n  >\n  </div>\n  <div class=\"sqs-mobile-info-bar-overlay-content\"></div>\n</div>\n";
  return buffer;
  });})()

  var filename = 'mobile-info-bar.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
