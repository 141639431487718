import Flag from '@sqs/enums/Flag';
import { legacyV6Flags as BetaFeaturesUtils } from '@sqs/universal-flags';
var FormBuilderFieldNames = {
  text: ['description', 'placeholder', 'required'],
  textarea: ['description', 'placeholder', 'required'],
  email: ['description', 'placeholder', 'required'],
  currency: ['description', 'currencySymbol', 'required'],
  number: ['description', 'placeholder', 'required'],
  website: ['description', 'placeholder', 'required'],
  password: ['description', 'required'],
  twitter: ['description', 'required'],
  date: ['description', 'required'],
  time: ['description', 'required'],
  name: ['description', 'required'],
  address: ['description', 'required'],
  phone: ['description', 'showCountryCode', 'required'],
  section: ['description', 'underline'],
  radio: ['description', 'options', 'required'],
  checkbox: ['description', 'options', 'required'],
  select: ['description', 'options', 'required'],
  likert: ['description', 'options', 'required'],
  hidden: ['key', 'value']
};

if (BetaFeaturesUtils.isFeatureEnabled(Flag.VANILLA_FORM_HANDLER)) {
  FormBuilderFieldNames.textarea.push('maxLength');
}

export default FormBuilderFieldNames;