import get from 'lodash/get';
import getTimeOffsetToWebsiteTimezone from './getTimeOffsetToWebsiteTimezone';
/**
 * Return a copy of a Date that if offset so that displaying it, or using its get* methods
 * will show the time as if in the website's timezone. This is neccesary since all dates/times
 * are shown in the website timezone and javascript only supports the local timezone.
 *
 * @method shiftForWebsiteTimezoneDisplay
 * @param {Date|String|Number} date date input
 * @return {Date} Datetime shifted in website's timezone
 **/
export default (date) => {
  if (!date) {
    return null;
  }

  if (!get(window.Static, 'SQUARESPACE_CONTEXT.tzData')) {
    return new Date(date);
  }

  const websiteTime = new Date(date);
  const offsetToSiteTimezone = getTimeOffsetToWebsiteTimezone(websiteTime);

  websiteTime.setMinutes(websiteTime.getMinutes() + offsetToSiteTimezone);
  return websiteTime;
};