"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInIframe = exports.getFrameElement = void 0;

/**
 * Given a window, returns whether it is
 * within an iframe
 * @param win
 */
var isInIframe = function isInIframe(win) {
  return win && win !== win.top;
};
/**
 * Given a HTML document - if the document is within
 * an iframe, returns the <iframe /> element.
 * @param doc
 */


exports.isInIframe = isInIframe;

var getFrameElement = function getFrameElement(doc) {
  return doc.defaultView && isInIframe(doc.defaultView) ? doc.defaultView.frameElement : null;
};

exports.getFrameElement = getFrameElement;