"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlacePredictions = exports.getDetails = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _network = _interopRequireDefault(require("@sqs/network"));

/**
 * Define constants for the endpoints requests are made
 * against for each type of Google Places query.
 *
 * These endpoints are internal and served by v6, attaching
 * our API keys to requests as required.
 */
var AUTOCOMPLETE_URL = '/api/google-maps/places/autocomplete';
var DETAILS_URL = '/api/google-maps/places/details';

var logRequestOptimizationGuidance = function logRequestOptimizationGuidance(params, googleTrackingContext) {
  if (__DEV__ && !params.sessiontoken) {
    console.error('Please use `sessiontoken` when using Google Places API to reduce API cost');
  }

  if (__DEV__ && (!googleTrackingContext || !googleTrackingContext.productPage || !googleTrackingContext.productSection)) {
    console.error('Please provide a tracking context when using Google Places API details to track cost');
  }
};
/**
 * @typedef {Object} GoogleTrackingContext
 * @property {String} productPage - The area of the product where the event occurred,
 *  e.g. frontsite, template-store, editor, etc.
 * @property {String} productSection - The page of the product where the event occurred.
 * A page is usually defined by its own URL, and is something the user can navigate back/forwards to.
 */

/**
 * Get autocomplete results
 * @param {Object} params
 * @param {GoogleTrackingContext} googleTrackingContext
 */


var getPlacePredictions = function getPlacePredictions(params, googleTrackingContext) {
  logRequestOptimizationGuidance(params, googleTrackingContext);
  return _network.default.get(AUTOCOMPLETE_URL, {
    params: (0, _objectSpread2.default)({}, googleTrackingContext, params)
  });
};
/**
 * Get place details
 * @param {Object} params
 * @param {GoogleTrackingContext} googleTrackingContext
 */


exports.getPlacePredictions = getPlacePredictions;

var getDetails = function getDetails(params, googleTrackingContext) {
  logRequestOptimizationGuidance(params, googleTrackingContext);

  if (!googleTrackingContext || !googleTrackingContext.productPage || !googleTrackingContext.productSection) {
    console.error('Please provide a tracking context when using Google Places API details to track cost');
  }

  return _network.default.get(DETAILS_URL, {
    params: (0, _objectSpread2.default)({}, googleTrackingContext, params)
  });
};

exports.getDetails = getDetails;