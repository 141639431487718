"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

/**
 * Safely set DataTransfer effect properties
 * Mutates the given dataTransfer object
 */
function _default(dataTransfer, effect) {
  // IE11 guard, direct access to effectAllowed is not permitted
  try {
    dataTransfer.dropEffect = effect;
    dataTransfer.effectAllowed = effect;
  } catch (err) {
    /* */
  }
}

module.exports = exports.default;