"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.MobileInfoBarStyle */

/* Java class defined in repository squarespace-v6 */
var MobileInfoBarStyle;

(function (MobileInfoBarStyle) {
  MobileInfoBarStyle["NONE"] = 1;
  MobileInfoBarStyle["LIGHT"] = 2;
  MobileInfoBarStyle["DARK"] = 3;
})(MobileInfoBarStyle || (MobileInfoBarStyle = {}));

var _default = MobileInfoBarStyle;
exports["default"] = _default;
module.exports = exports.default;