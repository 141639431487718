"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.slides.SlideType */

/* Java class defined in repository cms-legacy-data */
var SlideType;

(function (SlideType) {
  SlideType["COVER_PAGE"] = 1;
  SlideType["LOCK_SCREEN"] = 2;
  SlideType["POPUP_OVERLAY"] = 3;
})(SlideType || (SlideType = {}));

var _default = SlideType;
exports["default"] = _default;
module.exports = exports.default;