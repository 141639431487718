"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.slides.SliceType */

/* Java class defined in repository cms-legacy-data */
var SliceType;

(function (SliceType) {
  SliceType["HEADING"] = 1;
  SliceType["SUB_HEADING"] = 2;
  SliceType["BODY"] = 3;
  SliceType["IMAGE"] = 4;
  SliceType["GALLERY"] = 5;
  SliceType["VIDEO"] = 6;
  SliceType["SOCIAL_ICONS"] = 7;
  SliceType["BUTTONS"] = 8;
  SliceType["NAVIGATION"] = 9;
  SliceType["CUSTOM_FORM"] = 10;
  SliceType["NEWSLETTER"] = 11;
  SliceType["ALBUM"] = 12;
  SliceType["MAP"] = 13;
  SliceType["LOCK"] = 17;
  SliceType["PASSWORD"] = 18;
  SliceType["TWITTER"] = 19;
  SliceType["LOGO"] = 14;
  SliceType["ACTION"] = 15;
  SliceType["FORM"] = 16;
  SliceType["POPUP_OVERLAY_ACTION"] = 20;
  SliceType["TEMP_LOCK_INJECT"] = 100;
})(SliceType || (SliceType = {}));

var _default = SliceType;
exports["default"] = _default;
module.exports = exports.default;