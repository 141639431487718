"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.blocks.BlockType */

/* Java class defined in repository cms-legacy-data */
var BlockType;

(function (BlockType) {
  BlockType["NAVIGATION"] = 1;
  BlockType["HTML"] = 2;
  BlockType["LOGO"] = 3;
  BlockType["MAP"] = 4;
  BlockType["IMAGE"] = 5;
  BlockType["TWITTER"] = 6;
  BlockType["JOURNAL"] = 7;
  BlockType["GALLERY"] = 8;
  BlockType["FORM"] = 9;
  BlockType["GEO"] = 10;
  BlockType["INDEX"] = 11;
  BlockType["COLLECTION_LINK"] = 12;
  BlockType["LINK"] = 13;
  BlockType["TAGCLOUD"] = 14;
  BlockType["COMMENTS"] = 16;
  BlockType["FOLDER"] = 17;
  BlockType["MENU"] = 18;
  BlockType["SOCIAL_LINKS"] = 19;
  BlockType["SUMMARY"] = 20;
  BlockType["SPACER"] = 21;
  BlockType["EMBED"] = 22;
  BlockType["CODE"] = 23;
  BlockType["FOURSQUARE"] = 24;
  BlockType["INSTAGRAM"] = 25;
  BlockType["CALENDAR"] = 26;
  BlockType["POSTS_BY_AUTHOR"] = 27;
  BlockType["POSTS_BY_TAG"] = 28;
  BlockType["POSTS_BY_CATEGORY"] = 29;
  BlockType["POSTS_BY_MONTH"] = 30;
  BlockType["QUOTE"] = 31;
  BlockType["VIDEO"] = 32;
  BlockType["SEARCH"] = 33;
  BlockType["AUDIO"] = 41;
  BlockType["FIVEHUNDREDPIX"] = 42;
  BlockType["PRODUCT"] = 43;
  BlockType["MARKDOWN"] = 44;
  BlockType["FLICKR"] = 45;
  BlockType["AMAZON"] = 46;
  BlockType["HORIZONTAL_RULE"] = 47;
  BlockType["SOCIAL_ACCOUNT_LINKS"] = 48;
  BlockType["RSS"] = 49;
  BlockType["OPENTABLE"] = 50;
  BlockType["NEWSLETTER"] = 51;
  BlockType["DONATION"] = 52;
  BlockType["BUTTON"] = 53;
  BlockType["SOCIAL_ACCOUNT_LINKS_V2"] = 54;
  BlockType["SUMMARY_V2"] = 55;
  BlockType["SOUNDCLOUD"] = 56;
  BlockType["EMAIL_FOOTER"] = 57;
  BlockType["IN_BROWSER_MESSAGE_LINK"] = 58;
  BlockType["TOURDATES"] = 59;
  BlockType["ALBUM"] = 60;
  BlockType["ARCHIVE"] = 61;
  BlockType["CHART"] = 62;
  BlockType["ZOLA"] = 63;
  BlockType["ACUITY"] = 65;
  BlockType["OPENTABLE_V2"] = 66;
  BlockType["MEMBER_AREA"] = 67;
  BlockType["TOCK"] = 68;
  BlockType["ACCORDION"] = 69;
  BlockType["MARQUEE"] = 70;
})(BlockType || (BlockType = {}));

var _default = BlockType;
exports["default"] = _default;
module.exports = exports.default;