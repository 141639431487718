"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.PopupOverlayStyle */

/* Java class defined in repository squarespace-v6 */
var PopupOverlayStyle;

(function (PopupOverlayStyle) {
  PopupOverlayStyle["NONE"] = 1;
  PopupOverlayStyle["SHOW"] = 2;
})(PopupOverlayStyle || (PopupOverlayStyle = {}));

var _default = PopupOverlayStyle;
exports["default"] = _default;
module.exports = exports.default;