import _objectSpread from "@babel/runtime/helpers/objectSpread";
import "core-js/modules/es.object.values.js";
// What classes are set and unset by the system into the frame.
// And should be persisted between page navigation.
var persistedFrameClasses = {
  SQS_DEVICE_VIEW_PHONE: 'sqs-device-view-phone',
  SQS_DEVICE_VIEW_TABLET: 'sqs-device-view-tablet',
  IS_EXPANDED: 'is-expanded'
}; // Include the `values` as part of module. For caching as used where we don't
// have access to Object.values()

var persistedFrameClassesValues = Object.values(persistedFrameClasses);
export default _objectSpread({}, persistedFrameClasses, {
  values: persistedFrameClassesValues
});