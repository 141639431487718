function saveToLocalStorage(key, value) {
  if (localStorage) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }

    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.warn(e);
    }
  }
}

function getLocalStorageData(key) {
  let output = null;

  if (localStorage) {
    try {
      const raw = localStorage.getItem(key);
      output = JSON.parse(raw);
    } catch (e) {
      output = null;
    }
  }

  return output;
}

export {
  getLocalStorageData,
  saveToLocalStorage
};