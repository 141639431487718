"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPosition = exports.debug = exports.bind = void 0;

var _iframe = require("./iframe");

/**
 * Our global starting point for coords
 * to begin from.
 */
var coords = {
  lastX: 100,
  lastY: 100,
  client: {
    lastX: 100,
    lastY: 100
  }
};
/**
 * Returns the last registered position
 * of the mouse within the page, accounting
 * for if the event occured across an iframe
 * for which event listeners have been bound
 *
 * @return {MouseCoords}
 */

var getPosition = function getPosition() {
  return coords;
};
/**
 * Binds mouse move listeners on the provided
 * document. Mouse events do not bubble cross frame
 * so to have a global coordinate if a consumer of
 * this utility has multiple frames, then bind needs
 * to be called on each.
 * @param doc
 */


exports.getPosition = getPosition;

var bind = function bind(doc) {
  var _doc$defaultView;

  var element = (0, _iframe.getFrameElement)(doc);

  var updateCoords = function updateCoords(e) {
    var _ref = (element === null || element === void 0 ? void 0 : element.getBoundingClientRect()) || {},
        _ref$left = _ref.left,
        left = _ref$left === void 0 ? 0 : _ref$left,
        _ref$top = _ref.top,
        top = _ref$top === void 0 ? 0 : _ref$top;

    coords = {
      lastX: e.pageX + left,
      lastY: e.pageY + top,
      client: {
        lastX: e.clientX + left,
        lastY: e.clientY + top
      }
    };
  }; // We only care for single touches (not manipulation for zoom)
  // when we have a single touch, we extract the single Touch that
  // contains coords


  var handleTouchMove = function handleTouchMove(e) {
    if (e.changedTouches.length === 1) {
      updateCoords(e.changedTouches[0]);
    }
  }; // Add our listeners, and create a corresponding
  // helper to remove them


  doc.addEventListener('mousemove', updateCoords);
  doc.addEventListener('touchmove', handleTouchMove);

  var unbind = function unbind() {
    doc.removeEventListener('mousemove', updateCoords);
    doc.removeEventListener('touchmove', handleTouchMove);
  }; // By default, clean up after ourselves before unload


  (_doc$defaultView = doc.defaultView) === null || _doc$defaultView === void 0 ? void 0 : _doc$defaultView.addEventListener('beforeunload', unbind); // An return the unbind, so can be called at any lifecycle point

  return unbind;
};

exports.bind = bind;

var debug = function () {
  var debugNode = null;
  var animationFrame;
  return function () {
    var isDebugging = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    if (!isDebugging) {
      var _debugNode, _debugNode$parentNode;

      cancelAnimationFrame(animationFrame);
      (_debugNode = debugNode) === null || _debugNode === void 0 ? void 0 : (_debugNode$parentNode = _debugNode.parentNode) === null || _debugNode$parentNode === void 0 ? void 0 : _debugNode$parentNode.removeChild(debugNode);
      debugNode = null;
      return;
    }

    if (!debugNode) {
      // Create our debug node and continually update
      debugNode = document.createElement('div');
      debugNode.setAttribute('data-mouse', 'true');
      debugNode.style.position = 'fixed';
      debugNode.style.width = '50px';
      debugNode.style.height = '50px';
      debugNode.style.borderRadius = '50%';
      debugNode.style.background = 'rgba(247, 202, 24, 0.5)';
      debugNode.style.marginLeft = '-25px';
      debugNode.style.marginTop = '-25px';
      debugNode.style.zIndex = Math.pow(10, 5) + '';
      debugNode.style.pointerEvents = 'none';
      document.body.appendChild(debugNode);

      var updateNodePosition = function updateNodePosition() {
        var _getPosition = getPosition(),
            lastX = _getPosition.lastX,
            lastY = _getPosition.lastY;

        var node = debugNode;
        node.style.top = "".concat(lastY, "px");
        node.style.left = "".concat(lastX, "px");
        animationFrame = requestAnimationFrame(updateNodePosition);
      };

      updateNodePosition();
    }
  };
}();

exports.debug = debug;