"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.Feature */

/* Java class defined in repository squarespace-v6 */
var Features;

(function (Features) {
  Features["ADD_PAGES"] = 1;
  Features["ADD_CONTRIBUTORS"] = 2;
  Features["ADD_COMMERCE_PRODUCTS"] = 3;
  Features["DEVELOPER_SDK"] = 4;
  Features["MOBILE_PORTFOLIO"] = 5;
  Features["COMMERCE"] = 6;
  Features["ACCOUNTING"] = 7;
  Features["SHIPSTATION_INTEGRATION"] = 8;
  Features["ABANDONED_CART_RECOVERY"] = 9;
  Features["REAL_TIME_SHIPPING_RATES"] = 10;
  Features["COMMERCE_TRANSACTION_FEE"] = 11;
  Features["FREE_GOOGLE_APPS_INBOX"] = 12;
  Features["GOOGLE_AD_WORDS_CREDIT"] = 13;
  Features["VAT_COLLECTION_BETA"] = 14;
  Features["MULTICURRENCY_BETA"] = 15;
  Features["STRICT_GOOGLE_APPS_FREE_INBOX_POLICY"] = 16;
  Features["CRM"] = 17;
  Features["AUTO_TRIGGERED_DISCOUNTS"] = 18;
  Features["ACUITY"] = 19;
  Features["COMMERCE_CHECKOUT_ON_DOMAIN"] = 20;
  Features["POPUP_OVERLAY"] = 21;
  Features["SUPPORT_CALLBACKS"] = 22;
  Features["FREE_GOOGLE_APPS_INBOX_WITHIN_ONE_YEAR"] = 23;
  Features["FREE_DOMAIN_WITHIN_ONE_YEAR"] = 24;
  Features["ANALYTICS_COMMERCE_ADVANCED"] = 25;
  Features["MOBILE_INFO_BAR"] = 26;
  Features["ANNOUNCEMENT_BAR"] = 27;
  Features["CODE_INJECTION"] = 28;
  Features["SITE_USER_ACCOUNTS"] = 29;
  Features["BLOCK_ACUITY"] = 30;
  Features["BLOCK_DONATION"] = 31;
  Features["BLOCK_OPENTABLE"] = 32;
  Features["BLOCK_AMAZON"] = 33;
  Features["BLOCK_MENU"] = 34;
  Features["CHOW_NOW_INTEGRATION"] = 35;
  Features["GOOGLE_ANALYTICS_INTEGRATION"] = 36;
  Features["MAILCHIMP_INTEGRATION"] = 37;
  Features["BLOCK_CODE"] = 38;
  Features["ANALYTICS_COMMERCE_BASIC"] = 39;
  Features["GIFT_CARDS"] = 40;
  Features["FORMS_API"] = 41;
  Features["ORDERS_API"] = 42;
  Features["LIMITED_USE_DISCOUNTS"] = 43;
  Features["ANALYTICS_BUSINESS"] = 44;
  Features["PRODUCTS_ON_INSTAGRAM"] = 45;
  Features["BLOCK_CODE_WITH_MEDIA"] = 46;
  Features["COMMERCE_POSTAL_CODE_SHIPPING"] = 47;
  Features["FACEBOOK_PIXEL"] = 48;
  Features["MARKETING_CAMPAIGN_TRACKER"] = 49;
  Features["COMMERCE_SUBSCRIPTIONS"] = 50;
  Features["FACEBOOK_DYNAMIC_ADS"] = 51;
  Features["LIMITED_AVAILABILITY_ALERTS"] = 52;
  Features["INVENTORY_API"] = 53;
  Features["COMMERCE_RESTOCK_NOTIFICATIONS"] = 54;
  Features["COMMERCE_RELATED_PRODUCTS"] = 55;
  Features["COMMERCE_POINT_OF_SALE"] = 56;
  Features["COMMERCE_BULK_MANAGER"] = 57;
  Features["TRANSACTIONS_API"] = 58;
  Features["EXTERNAL_LOCATION_MANAGEMENT"] = 59;
  Features["IN_APP_LIVE_CHAT"] = 60;
  Features["COVID_TEMP_GIFT_CARDS_MESSAGING"] = 61;
  Features["PRODUCTS_API"] = 62;
  Features["AUTOMATIC_TAX_CALCS"] = 63;
  Features["COMMERCE_AFTERPAY"] = 64;
  Features["PROFILES_API"] = 65;
  Features["COMMERCE_NATIVE_PRODUCT_REVIEWS"] = 66;
  Features["COMMERCE_IMPORTED_PRODUCT_REVIEWS"] = 67;
  Features["COMMERCE_CLEARPAY"] = 68;
})(Features || (Features = {}));

var _default = Features;
exports["default"] = _default;
module.exports = exports.default;