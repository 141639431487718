/**
  Holds the UITemplates class and some HB template formatters
  This file creates a singleton accessible through Y.Squarespace.UITemplates
  @module squarespace-ui-templates
*/
YUI.add('squarespace-ui-templates', function(Y) {

  /**
    A small, singleton utility class that wraps rendering of precompiled
    handlebars templates.
    @class UITemplates
    @singleton
    @namespace Squarespace
  */
  var UITemplates = Y.Base.create('UITemplates', Y.Base, [],
    // prototype
    {
      /**
        @method render
        @param name {String} The filename of the template in templates-v6/handlebars
        @param context {Object} The context (HB variable values) to render the
          template against
        @return {String} The rendered handlebars template as a string.
      */
      render: function(name, context) {
        var template = this.getCompiledTemplate(name);
        if (!template) {
          throw new Error(this.name + ': Could not find UITemplate by the name of: ' + name + '. Recompile templates?');
        }
        return template(context || {});
      },

      /**
        Renders a given template and returns it in a node or document fragment
        format
        @method renderAsNodeOrDocFrag
        @param name {String} The filename of the template in templates-v6/handlebars
        @param context {Object} The context (HB variable values) to render the
          template against
        @return {Node|DocumentFragment} A node or document fragment created from
          the rendered template string
      */
      renderAsNodeOrDocFrag: function(name, context) {
        return Y.Node.create(this.render(name, context));
      },

      // sugar methods
      /**
        A sugar method for retrieving the compiled template with a specific file
        name
        @method getCompiledTemplate
        @param name {String} The file name of the compiled template function you
          want
        @return {Function} The compiled template function by that filename or
          undefined if it doesn't exist.
      */
      getCompiledTemplate: function(name) {
        var template = this.get('compiledTemplates')[name];
        if (!template) {
          throw new Error(this.name + ': A template by the name ' + name + ' does not exist.');
        }
        return template;
      }
    },
    // static properties
    {
      ATTRS: {
        /**
          @attribute compiledTemplates
          @description An object mapping filenames to compiled template functions
          @type Object
          @default {}
          @readOnly
        */
        compiledTemplates: {
          readOnly: true,
          getter: function() {
            var templates = Y.Handlebars.templates;
            if (!Y.Lang.isObject(templates)) {
              throw new Error(
                this.name + ': No templates are available. Have you loaded your template module?'
              );
            }
            return templates;
          }
        }
      }
    }
  );

  Y.namespace('Squarespace').UITemplates = new UITemplates();

}, '1.0', {
  requires: [
    'base',
    'handlebars-base'
  ]
});
