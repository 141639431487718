"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.PageFeature */

/* Java class defined in repository squarespace-v6 */
var PageFeatures;

(function (PageFeatures) {
  PageFeatures["EDITABLE"] = 1;
  PageFeatures["TWEAKABLE"] = 2;
  PageFeatures["FULL_PAGE_CART"] = 3;
  PageFeatures["OVERLAYS"] = 4;
  PageFeatures["ECOMMERCE_GOOGLE_ANALYTICS_TRACKED"] = 5;
})(PageFeatures || (PageFeatures = {}));

var _default = PageFeatures;
exports["default"] = _default;
module.exports = exports.default;