/**
 * 'What time is it?' turns out to be a really hard question.
 * The purpose of this module is to find the offset from GMT/UTC/Zulu at a specific time in a timezone.
 * And some other information about a moment in a location.
 *
 * Essential reading on how the Tz database works: http://www.cstdbill.com/tzdb/tz-how-to.html
 * This program was created by reading this document.
 *
 * The relies on a custom compacted and parsed version of the database
 * served in the static context.
 * Unlike large libraries, we don't load tons of unnessesary timezone data.
 * Only the website's timezone since 2004-ish.
 *
 * This also doesn't have the bug timezone-js has.
 * Timezone-js cannot represent dates that do not exist on the local machine (spring forward)
 *
 * Use for getting the GMT/UTC offset of any time anywhere. Unless you've disabled historical data in the parser,
 * in which case values won't be accuate for pre-2004 dates.
 **/

import fill from 'lodash/fill';

// Milliseconds in a day
const A_DAY_MS = 1000 * 60 * 60 * 24;

const DAYS_IN_WEEK = 7;

// Mapping 3-letter month names to their numbers 0-11

const moStrToInt = {
  'Jan': 0,
  'Feb': 1,
  'Mar': 2,
  'Apr': 3,
  'May': 4,
  'Jun': 5,
  'Jul': 6,
  'Aug': 7,
  'Sep': 8,
  'Oct': 9,
  'Nov': 10,
  'Dec': 11
};

// Mapping 3-letter weekday names to their numbers 0-6, starting on Sunday
const wkdyStrToInt = {
  'Sun': 0,
  'Mon': 1,
  'Tue': 2,
  'Wed': 3,
  'Thu': 4,
  'Fri': 5,
  'Sat': 6
};

/**
 * Parse the standard way of showing a clock time in the database into its component parts
 * e.g. 2:00w would make {hour: 2, min: 0, totalMins: 60, type: 'w'}
 *
 * @param  {String} saveString
 * @return {Object} - {hour, min, totalMins, type}
 */
const parseClock = function(saveString) {
  const typePos = saveString.search(/[sguzw]/);
  const timeSplit = saveString.split(':');
  const hour = timeSplit[0] ? parseInt(timeSplit[0], 10) : 0;
  const min = timeSplit[1] ? parseInt(timeSplit[1], 10) : 0;
  const totalMins = hour * 60 + min;

  return {
    hour,
    min,
    totalMins,
    type: (typePos === -1 ? 'w' : saveString.substr(-1, 1))
  };
};

/**
 * A class that holds the zone data and can calculate information for an instant.
 */
class TimezoneOffsetCalculator {
  /**
   * Instatiate the calculator with decompacted zones and rules for a single timezone.
   * @param  {Array} zones - ordered zone information as specified in the db
   * @param  {Object} rules - rule objects keyed by rule name
   */
  constructor(zones, rules) {
    this.allZones = zones;
    this.allRules = rules;
  }

  /**
   * Get information about an instant in time
   *
   * The only public method here.
   *
   * @param  {Number|Date} time - ms since epoch or Date
   * @return {Object}
   *         standardUTCOffset - Minutes the zone is ahead of UTC usually, without daylight savings.
   *         utcOffset - Minutes ahead of standard time (daylight savings)
   *         abbreviation - The active abbreviation, e.g. EST, EDT, LMT, GMT, BST. May be null.
   */
  getInfo(time) {
    if (time instanceof Date) {
      time = time.getTime();
    }
    const activeZoneData = this.findActiveZone(time);
    const activeRule = this.findActiveRule(activeZoneData, time);
    const standardUTCOffset = activeZoneData.off;

    let saveMins;

    if (activeRule) {
      saveMins = parseClock(activeRule.save).totalMins;
    } else {
      saveMins = 0;
    }

    const abbreviation = this.findAbbr(activeZoneData, activeRule, saveMins);

    const utcOffset = standardUTCOffset + saveMins;

    return {
      standardUTCOffset,
      utcOffset,
      abbreviation
    };
  }

  /**
   * Like getInfo, but uses the local machine's information.
   * standardUTCOffset will equal utcOffset and will represent the machine's offset at that time.
   */
  static getFallbackInfo(time) {
    const currentOffset = -(new Date(time)).getTimezoneOffset();
    return {
      standardUTCOffset: currentOffset,
      utcOffset: currentOffset,
      abbreviation: null
    };
  }

  /** find the zone that's active (hasn't hit it's "until" limit) and return it */
  findActiveZone(time) {
    //assume zones are sorted by until - ensure this in the parser
    // walk through rules from oldest to newest until we get the first match
    const zoneLines = this.allZones;

    const activeZone = zoneLines.find((line) => {
      return !line.until || line.until * 1000 >= time;
    });

    if (activeZone !== null) {
      return activeZone;
    }

    throw new Error('All the zones loaded ended before this time. ' +
      'This should not happen - check that zones were loaded.');

  }

  /**
   * Find which exact rule applies at this year and time of year
   * There can only be one (or none)! (Can't have both EST and EDT)
   */
  findActiveRule(activeZoneData, time) {
    // there may be no rules
    if (!activeZoneData) {
      return null;
    }

    if (!activeZoneData.rules) {
      return null;
    }

    //figure out what year it is
    const date = new Date(time);
    const currentYear = date.getUTCFullYear();

    const allFollowedRules = this.allRules[activeZoneData.rules];
    let applicableRules = this.findRulesForYear(currentYear, allFollowedRules);

    // now we want to toss out rules that haven't started yet
    applicableRules = this.filterRulesRough(activeZoneData, applicableRules, date);

    if (applicableRules.length > 0) {
      //sort the rules by the rough time they start this year
      applicableRules.sort(this.getRuleComparator(currentYear, activeZoneData));

      // optimization: if this time isn't near a DST changeover, it may be obvious what the rule is
      const roughStartTime = this.getRuleExactStartTime(activeZoneData, currentYear,
        applicableRules[applicableRules.length - 1]);

      if (date.getTime() > roughStartTime + A_DAY_MS) {
        return applicableRules[applicableRules.length - 1];
      }
    }

    // a parallel array of years in which applicableRules applied
    let years = new Array(applicableRules.length);
    fill(years, currentYear);

    // then we need at least 2 rules.
    // We need a penultimate rule to see if the last rule applied
    // (because it may happen on wall clock time of the previous rule)
    // Either we'll wind up tossing the last one because the next-to-last one is right, or the last one will be right
    if (applicableRules.length < 2) {
      // in this case there aren't 2 rules, so we go back a year for more rules
      //KNOWN BUG: I'm assuming last year has the same zone, which may not be, but is very rare
      const lastYearsRules = this.findRulesForYear(currentYear - 1, allFollowedRules);
      lastYearsRules.sort(this.getRuleComparator(currentYear - 1, activeZoneData));

      const lastYears = new Array(lastYearsRules.length);
      fill(lastYears, currentYear - 1);

      applicableRules = lastYearsRules.concat(applicableRules);
      years = lastYears.concat(years);
    }

    // filter the rules again, more accurately this time
    applicableRules = applicableRules.filter((val, i, arr) => {
      //can't get exact time for first one, oh well. The db never requires going back more than 1 year
      if (i === 0) {
        return true;
      }

      const exactStartTime = this.getRuleExactStartTime(activeZoneData, years[i], val, arr[i - 1]);
      return exactStartTime <= date.getTime();
    });

    if (applicableRules.length === 0) {
      return null;
    }
    return applicableRules[applicableRules.length - 1];
  }

  /**
   * Given a set of rules, returns which ones applied in the given year
   * @param   {Number} year
   * @param   {Array} rulesSet
   * @private
   * @return  {Array} subset of rules that apply in `year`
   */
  findRulesForYear(year, rulesSet) {

    //filter for the rules that apply in this year
    return rulesSet.filter((rule) => {
      if (rule.from > year) {
        return false; // rule hasn't started yet
      }
      if (typeof rule.to === 'number' ) {
        return rule.to >= year; //a limited rule, matches

      } else if (typeof rule.to === 'string') {

        if (rule.to === 'max') {
          return true; // rule doesn't end

        } else if (rule.to === 'only' && rule.from === year) {
          return true;

        }
      }
      return false;
    });

  }

  /**
   * Return a new array of rules having removed rules that definitely haven't started by a rough calculation
   * @private
   * @param  {Array} applicableRules
   * @param  {Date} date around which rules are to be evaluated
   * @return {Array}
   */
  filterRulesRough(activeZoneData, applicableRules, date) {
    // first, we toss out the ones that DEFINITELY haven't stated yet
    return applicableRules.filter((val, i, arr) => {
      //optimization: check months before doing the big calculation
      const monthDiff = date.getUTCMonth() - moStrToInt[val.in];
      if (monthDiff < -1) {
        return false;
      }

      // we do this by having a 24hour buffer, just in case
      const roughStartTime = this.getRuleExactStartTime(activeZoneData, date.getUTCFullYear(), val);
      return roughStartTime - A_DAY_MS <= date.getTime();

    });
  }

  /** compare rules within a given year */
  compareRules(a, b, year, activeZoneData) {
    const monthDiff = moStrToInt[a.in] - moStrToInt[b.in];
    if (monthDiff !== 0) {
      return monthDiff;
    }

    // two rules in the same month?! Crazy.
    const ruleAStart = this.getRuleExactStartTime(activeZoneData, year, a, null);
    const ruleBStart = this.getRuleExactStartTime(activeZoneData, year, b);
    return ruleAStart - ruleBStart;
  }

  /**
   * a useful currying function to get a comparator for a given year
   * @param   {Number} year
   * @private
   * @return  {Function}
   */
  getRuleComparator(year, activeZoneData) {
    return (a, b) => this.compareRules(a, b, year, activeZoneData);
  }

  /**
   * Get the ms since epoch that a rule becomes effective in a given year
   * If prev rule is not specified this will not be the EXACT time, but is good enough for sorting
   * Rule is assumed to be active in year.
   *
   * @param   {Number} year
   * @param   {Object} rule
   * @param   {Object} [prevRule]
   * @private
   * @return  {Number}
   */
  getRuleExactStartTime(activeZoneData, year, rule, prevRule) {
    const at = parseClock(rule.at);

    //What exact utc time does this rule start? Well that usually depends on the last active rule.
    const offset = this.getClockOffset(at.type, activeZoneData, prevRule);

    //It's a day of the month
    if (typeof rule.on === 'number') {
      return this.getDateRuleStartTime(year, rule) - offset;

    //e.g. lastSun
    } else if (rule.on.substr(0, 4) === 'last') {
      return this.getLastDayRuleStartTime(year, rule) - offset;
    }

    // e.g. Wed>=8
    return this.getBeforeAfterDateRuleStartTime(year, rule) - offset;
  }

  /** Helper to getRuleExactStartTime */
  getDateRuleStartTime(year, rule) {
    //It's a day of the month
    const at = parseClock(rule.at);

    const effectiveDate = new Date(Date.UTC(year, moStrToInt[rule.in], rule.on, at.hour, at.min));
    return effectiveDate.getTime();
  }

  /** Helper to getRuleExactStartTime */
  getLastDayRuleStartTime(year, rule) {
    // e.g. lastSun
    const at = parseClock(rule.at);

    const targetWkD = wkdyStrToInt[rule.on.substr(4, 3)];
    const inMonthInt = moStrToInt[rule.in];

    //get the last day of the month
    let effectiveDate = new Date(Date.UTC(year, inMonthInt + 1, 1, at.hour - 24, at.min));

    let targetDate = effectiveDate.getUTCDate();
    if (effectiveDate.getUTCDay() < targetWkD) {
      targetDate -= DAYS_IN_WEEK;
    }
    targetDate -= effectiveDate.getUTCDay() - targetWkD;

    effectiveDate = new Date(Date.UTC(year, inMonthInt, targetDate, at.hour, at.min));

    return effectiveDate.getTime();
  }

  /** Helper to getRuleExactStartTime */
  getBeforeAfterDateRuleStartTime(year, rule) {
    // >= or <=
    const at = parseClock(rule.at);
    const operator = rule.on.substr(3, 2);
    const targetWkD = wkdyStrToInt[rule.on.substr(0, 3)];
    let targetDate = parseInt(rule.on.substr(5, 2), 10);
    const inMonthInt = moStrToInt[rule.in];
    let effectiveDate = new Date(Date.UTC(year, inMonthInt, targetDate, at.hour, at.min));

    if (operator === '<=') {

      if (effectiveDate.getUTCDay() < targetWkD) {
        targetDate -= DAYS_IN_WEEK;
      }

      targetDate -= effectiveDate.getUTCDay() - targetWkD;
      effectiveDate = new Date(Date.UTC(year, inMonthInt, targetDate, at.hour, at.min));

    } else if (operator === '>=') {
      targetDate += targetWkD - effectiveDate.getUTCDay();
      if (effectiveDate.getUTCDay() > targetWkD) {
        targetDate += DAYS_IN_WEEK;
      }

      effectiveDate = new Date(Date.UTC(year, inMonthInt, targetDate, at.hour, at.min));
    } else {
      throw rule.on + ' did not match a valid on string';
    }

    return effectiveDate.getTime();
  }

  /**
   * Calculate the local offset from UTC for a clock type under a rule.
   * Corresponds with the u,g,z,s,w suffixes for clocks from the database.
   * [ugz] -> offset is 0, s -> offset is standard offset, w or none -> offset is total offset
   * @private
   * @param  {String} type in [ugzsw]
   * @param  {Object} activeZone
   * @param  {Object} [rule] omitting will make wall clock time off by the daylight savings amount.
   * Good enough for sorting
   * @return {Number} offset in milliseconds from UTC
   */
  getClockOffset(type, activeZone, rule) {
    let minsResult;
    if (type === 'u' || type === 'g' || type === 'z') {
      minsResult = 0;
    } else if (type === 's') {
      minsResult = activeZone.off;
    } else if (type === 'w' || !type) {
      minsResult = rule ? activeZone.off + parseClock(rule.save).totalMins : activeZone.off;
    } else {
      throw 'Unknown clock type ' + type;
    }
    return minsResult * 60 * 1000; // mins to ms
  }

  /**
   * Logic to find the active abbreviation
   * @private
   * @return {String}
   */
  findAbbr(activeZoneData, activeRule, saveMins) {
    if (!activeZoneData.format) { //there isn't one
      return null;
    }

    if (activeZoneData.format.indexOf('/') !== -1) { //uses STANDARD/DAYLIGHT notation
      const abbrs = activeZoneData.format.split('/');

      return saveMins === 0 ? abbrs[0] : abbrs[1];

    } else if (activeRule && activeRule.letter) { //uses letter replacement
      return activeZoneData.format.replace('%s', activeRule.letter);
    }

    return activeZoneData.format; //Doesn't change
  }
}


export default TimezoneOffsetCalculator;