/**
 * Device view type constants
 *
 * @property DeviceViewType
 */
var DeviceViewType;

(function (DeviceViewType) {
  DeviceViewType["DESKTOP"] = "desktop";
  DeviceViewType["PHONE"] = "phone";
  DeviceViewType["TABLET"] = "tablet";
})(DeviceViewType || (DeviceViewType = {}));

export default DeviceViewType;