/**
 * The format functionality.
 */
import memoize from 'lodash/memoize';

const splitOnTokens = /({[^}]+})/g;
const matchTokenName = /{([^}]+)}/;

const isString = value => typeof value === 'string';

/**
 * Split a string on tokens; essentially precompiling the template.
 * tokenize("Hello, {friend}! My name is {name}.")
 * // => ['Hello, ', '{friend}', '! My name is ', '{name}']
 * @param  {string} The string to tokenize.
 * @return {Array} The string represented as a simple array.
 */
const tokenize = memoize(function(str) {
  return str.split(splitOnTokens);
});

const getKey = (temp, repl) => {
  return temp + '-' + JSON.stringify(repl);
};

/**
 * Given a template, make all the replacements at once and return
 * a string.
 * replaceTokens('Hello, ', '{friend}', '! My name is ', '{name}'], { name: 'Jacob' })
 * // => 'Hello {friend}! My name is Jacob'
 * @param  {Array} templ The template array.
 * @param  {Object} replacements A hash of replacements to make.
 * @return {string} The re-hydrated string.
 */
const replaceTokens = memoize(function(templ, replacements) {
  if (typeof replacements === 'object' && replacements !== null) {
    templ = templ.map(part => {
      const match = part.match(matchTokenName);
      if (match && replacements.hasOwnProperty(match[1])) {
        return replacements[match[1]] + '';
      }
      return part;
    });
  }
  return templ.join('');
}, getKey);

/**
 * Given a string and an object, format the string as we'd like.
 * Currently this templating language is super dumb, intentionally so.
 * The only replacements allowed are `{key}` substitutions.
 * @param  {string}  str          The string to format
 * @param  {Object}  extras       An object of replacements.
 * @return {string}               The formatted string.
 */
export default (str, extras = {}) => {
  if (!extras || !isString(str)) {
    return str;
  }
  const tokenizedTemplate = tokenize(str);

  return replaceTokens(tokenizedTemplate, extras);
};
