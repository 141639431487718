"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "UserSessionApiAxios", {
  enumerable: true,
  get: function get() {
    return _UserSessionApiAxios.default;
  }
});
exports.default = void 0;

var _UserSessionApiAxios = _interopRequireDefault(require("./UserSessionApiAxios"));

var SITE_USERS_ROOT = '/api/site-users/account/session';
var UserSessionApi = {
  // TODO enforce this can only be called with https
  login: function login(params) {
    return _UserSessionApiAxios.default.post(SITE_USERS_ROOT, {
      email: params.email,
      password: params.password,
      rememberMe: params.rememberMe
    }).then(function (_ref) {
      var data = _ref.data;
      return _UserSessionApiAxios.default.setXsrfToken(data.xsrfToken);
    });
  },
  logout: function logout() {
    var _this = this;

    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return this.callLogoutEndpoint(params).then(function (response) {
      // retry logging out if crumb is not correct
      if (response.data && response.data.crumbFail) {
        return _this.callLogoutEndpoint(params);
      }

      return response;
    });
  },
  callLogoutEndpoint: function callLogoutEndpoint(params) {
    return _UserSessionApiAxios.default.delete(SITE_USERS_ROOT, {
      params: {
        cloneCart: !!params.cloneCart
      }
    });
  }
};
var _default = UserSessionApi;
exports.default = _default;