import memoize from 'lodash/memoize';
import calculateTimezoneOffset from './calculateTimezoneOffset';

/**
 * Helper method to calculate the browsers offset to the website
 * timezone for a specific time.
 *
 * Based on the timezone json data downloaded from IANA and created by the parser script.
 * Historic data is tossed in the parser for size reasons, so really old dates may be incorrect.
 * Note that this method requires a lot of work - use tzTools directly to avoid recalculating on every call.
 *
 * @method getTimeOffsetToWebsiteTimezone
 * @param {Date|Number} time a Date or ms since epoch time
 * @return {Number} minutes - difference between website and local time
 */
export default memoize((time) => {
  if (time instanceof Date) {
    time = time.getTime();
  } else if (typeof time !== 'number') {
    throw new Error('Invalid argument');
  }

  const localTime = new Date(time);

  // get utc offsets
  const minsLocalAhead = localTime.getTimezoneOffset();
  const minsWebAhead = calculateTimezoneOffset(time).utcOffset;

  return minsWebAhead + minsLocalAhead;

});