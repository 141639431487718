"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

/**
 * A valid anchoring elements are:
 *  - nodes,
 *  - coordinates object
 *  - strings:
 *    - "mousepointer" - targets the last known mouse coordinate.
 *    - "window" - targets the general window
 *    - "selection" - text selection
 */
var anchor = _propTypes.default.oneOfType([_propTypes.default.instanceOf(Node), _propTypes.default.oneOf(['window', 'mousepointer', 'selection']), _propTypes.default.shape({
  top: _propTypes.default.number,
  left: _propTypes.default.number
})]);
/**
 * {x, y} coordinates to appear at.
 */


var coords = _propTypes.default.shape({
  x: _propTypes.default.number,
  y: _propTypes.default.number
});
/**
 * The positioning. If no anchor is supplied, then the dialog appears relative
 * to the window. An object would look like:
 *
 * {
 *   join: BOTTOM_CENTER,
 *   to: CENTER_CENTER
 *  }
 *
 * @see shared/constants/Positions
 */


var position = _propTypes.default.oneOfType([_propTypes.default.oneOf(['left', 'right', 'center', 'top', 'bottom']), _propTypes.default.shape({
  join: _propTypes.default.string,
  to: _propTypes.default.string
})]);

var _default = {
  anchor: anchor,
  coords: coords,
  position: position
};
exports.default = _default;
module.exports = exports.default;