import get from 'lodash/get';
import TemplateVersion from '@sqs/enums/TemplateVersion';

/**
 * This util is a self executing closure to prevent users from editing
 * `Static.SQUARESPACE_CONTEXT` and obtaining access to unauthorized features.
 *
 * Because this util caches data during import time, make sure
 * `Static.SQUARESPACE_CONTEXT` is bootstrapped beforehand.
 *
 * Be careful importing modules here because it may affect visitor facing load
 * times.
 *
 * The data is added through TemplateVersionUtils on the backend and checks if
 * Flag.NEW_STACKED_INDEX for 7.1, else 7
 */
export default (function() {
  const templateVersion = get(window, 'Static.SQUARESPACE_CONTEXT.templateVersion') as string;

  return {
    getVersion: function() {
      return templateVersion;
    },
    isVersion: function(version : any) {
      return templateVersion === version;
    },
    isSevenOne: function() {
      return templateVersion === TemplateVersion.SEVEN_ONE;
    }
  };
}());
