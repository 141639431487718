import { formatDateTime } from 'shared/i18n';
import shiftForWebsiteTimezoneDisplay from 'shared/utils/shiftForWebsiteTimezoneDisplay';
/**
   Wrapper function around formatDateTime function
   This function adjust the oDate to the timezone set in settings

   @method dateFormat
*/
export default (oDate, format = 'LLL') => {
  if (!oDate) {
    oDate = new Date();
  } else if (!(oDate instanceof Date) && typeof oDate === 'number' || typeof oDate === 'string') {
    oDate = new Date(oDate);
  } else if (isNaN(oDate.getTime())) {
    return 'Invalid Date';
  }

  oDate = shiftForWebsiteTimezoneDisplay(oDate);

  return formatDateTime(oDate, format);
};