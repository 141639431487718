"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.RecordType */

/* Java class defined in repository cms-legacy-data */
var RecordType;

(function (RecordType) {
  RecordType["TEXT"] = 1;
  RecordType["IMAGE"] = 2;
  RecordType["QUOTE"] = 4;
  RecordType["LINK"] = 5;
  RecordType["CHAT"] = 6;
  RecordType["AUDIO"] = 7;
  RecordType["VIDEO"] = 8;
  RecordType["VIDEO_DEPRECATED"] = 9;
  RecordType["REVIEW"] = 10;
  RecordType["STORE_ITEM"] = 11;
  RecordType["EVENT"] = 12;
  RecordType["THREAD"] = 13;
  RecordType["GALLERY"] = 14;
  RecordType["BINARY"] = 15;
  RecordType["CSSASSET"] = 16;
  RecordType["TWEAKASSET"] = 17;
  RecordType["DIGITALGOOD"] = 18;
  RecordType["ATTACHMENT"] = 19;
  RecordType["EXPORT_WORDPRESS"] = 20;
  RecordType["EXPORT_INTERNAL"] = 21;
  RecordType["TEXT_PROSE_MIRROR"] = 22;
  RecordType["TWEET"] = 50;
  RecordType["RSS"] = 51;
  RecordType["CHECKIN"] = 52;
  RecordType["DELICIOUS"] = 53;
  RecordType["KBARTICLE"] = 54;
  RecordType["PROJECT_ITEM"] = 55;
  RecordType["COLLECTION_TAXONOMY"] = 56;
  RecordType["SECTION_TAXONOMY"] = 57;
  RecordType["ITEM_TAXONOMY"] = 58;
  RecordType["PORTFOLIO_ITEM"] = 59;
  RecordType["EXPORT_TRANSLATABLE_STRINGS"] = 60;
  RecordType["SQSP_VIDEO"] = 61;
  RecordType["LESSON"] = 62;
})(RecordType || (RecordType = {}));

var _default = RecordType;
exports["default"] = _default;
module.exports = exports.default;