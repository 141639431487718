import { shape, string } from 'prop-types';
export var AUTOCOMPLETE_URL = '/api/google-maps/places/autocomplete';
export var DETAILS_URL = '/api/google-maps/places/details';
/**
 * context object containing key value pairs for internal tracking
 * productPage - The area of the product where the event occurred, e.g. frontsite, template-store, editor, etc.
 * productSection - The page of the product where the event occurred. A page is usually defined by its own URL,
 * and is something the user can navigate back/forwards to.
 */

export var googleTrackingContextProp = shape({
  productPage: string.isRequired,
  productSection: string.isRequired
});