import forIn from 'lodash/forIn';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import zipObject from 'lodash/zipObject';

import TimezoneOffsetCalculator from './TimezoneOffsetCalculator';

const decompact = (compacted) => {
  const data = { zones: [], rules: {} };
  data.zones = compacted.zones.map(zone => zipObject(['off', 'rules', 'format', 'until'], zone));

  forIn(compacted.rules, (rules, ruleName) => {
    const rulesDecompact = rules.map(rule => zipObject([
      'from',
      'to',
      'type',
      'in',
      'on',
      'at',
      'save',
      'letter'
    ], rule));

    data.rules[ruleName] = rulesDecompact;
  });

  return data;
};

// the underlying calculator full of craziness. Or null if it could not be instatiated.
// Sometimes there is no zone data available and we want to fail nicely.
let offsetCalculator = null;

const createCalculator = () => {
  const compacted = get(window.Static, 'SQUARESPACE_CONTEXT.tzData');
  if (!compacted) {
    return;
  }
  const { zones, rules } = decompact(compacted);
  if (zones && rules) {
    offsetCalculator = new TimezoneOffsetCalculator(zones, rules);
  }
};

createCalculator();



/**
 * Get information about an instant in time in the timezone of the website.
 *
 * @param  {Number|Date} time - ms since epoch or Date
 * @return {Object}
 *         standardUTCOffset - Minutes the zone is ahead of UTC usually, without daylight savings.
 *         utcOffset - Minutes ahead of standard time (daylight savings) at this moment.
 *         abbreviation - The active abbreviation, e.g. EST, EDT, LMT, GMT, BST. May be null.
 */
const calculateTimezoneOffset = memoize((time) => {
  // Maybe the Static context has loaded now. Attempt to create the calculator again.
  if (!offsetCalculator) {
    createCalculator();
  }

  if (!offsetCalculator) {
    console.warn('No timezone data loaded');
    return TimezoneOffsetCalculator.getFallbackInfo(time);
  }

  return offsetCalculator.getInfo(time);
});

export default calculateTimezoneOffset;