/*


   (  ( (   /'_/_ _ _/
  __)__)|/|//(/(/(- / .
              _/

  Created: Summer 2011

*/

/**
  Home of the Squarespace Widget, a yui widget extension that all Squarespace
  widgets should extend from. It provides some additional facilities for cleaning

  @module squarespace-ss-widget
*/
YUI.add('squarespace-ss-widget', function(Y) {

  Y.namespace('Squarespace.Widgets');

  /**
  * The Base Squarespace Widget. Any rendered class should extend off this class.
  * SSWidget is a place where you may want to add functionality that is available to all
  * Widgets in the Squarespace ecosystem.
  *
  * SSWidget provides an extra set of nice things on top of Y.Widget :
  *
  * - Logger: this.LOG.info('hello, world') ---> [YourClassName] hello, world!
  * - Event, Timer, Callbacks, and Animation management. The lifecycle behavior is extended to these types if registered
  * - Handlebars or inline HTML template. Built in template rendering (as well as event delegation).
  *
  * @namespace Squarespace.Widgets
  * @class SSWidget
  * @extends Widget
  */
  var SSWidget =
  Y.Squarespace.Widgets.SSWidget = Y.Squarespace.SSWidget =
  Y.Base.create('ssWidget', Y.Widget, [],
    {
      /**
        An Y.View style events object for delegated event binding. It should
        be in the following form:
        {
          '.some-selector': {
            eventName: '_nameOfHandler'
          }
        }
        @property delegatedEvents
        @protected
        @type Object
      */
      delegatedEvents: {},

      initializer: function(config) {

        this.LOG = new Y.Squarespace.Debugger({
          name: this.name,
          output: false
        });

        this._anims = new Y.Squarespace.Anims();

        this.on('classNameChange', function(e) {
          var boundingBox = this.get('boundingBox');

          if (boundingBox) {
            boundingBox
              .removeClass(e.prevVal)
              .addClass(e.newVal);
          }
        }, this);

        this._events = [];
        this._timers = [];
      },

      /**
       * Override the destroy() method to default the destroyAllNodes field
       * to true.
       *
       * @method destroy
       * @param destroyAllNodes {Boolean} If true, all nodes contained within the Widget are
       * removed and destroyed. We default this to true, to ensure things get extra clean.
       */
      destroy: function(destroyAllNodes) {
        if (!Y.Lang.isBoolean(destroyAllNodes) && !this.get('destroyed')) {
          var boundingBox = this.get('boundingBox');
          var contentBox = this.get('contentBox');
          if (boundingBox && boundingBox._node && contentBox && contentBox._node) {
            destroyAllNodes = true;
          } else {
            this.LOG.error('This widget\'s elements was improperly cleaned up!');
          }
        }

        SSWidget.superclass.destroy.call(this, destroyAllNodes);
      },

      destructor: function() {
        this._anims.destroy();

        Y.detach(this.get('id') + '|*');
        this.detach(this.get('id') + '|*');

        while (this._events.length !== 0) {
          this._events.shift().detach();
        }

        while (this._timers.length !== 0) {
          this._timers.shift().cancel();
        }

        this._anims = null;
        this._timers = [];
        this._events = [];

        this.LOG = null;
      },

      /*
       * Register an event, so on destruction, it'll safely get detached.
       *
       * @param {eventHandle*|Array} e Event handle to register or an array of handles
       */
      _registerEvent: function () {
        if (Y.Lang.isArray(arguments[0])) {
          this._registerEvent.apply(this, arguments[0]);
        } else {
          for (var i = 0, im = arguments.length; i < im; i++) {
            if (Y.Lang.isValue(arguments[i])) {
              this._events.push(arguments[i]);
            }
          }
        }
      },

      /**
       * Register a pointer action, so no destruction it'll safely get detached.
       *
       * @method _registerPointerEvent
       * @param {string} timing The event timing, 'on' or 'onceAfter'
       * @param {Node} node The node on which to put hte listener
       * @param {Function} callback The method to call.
       * @param {Object} context The context to apply to the callback upon the event
       */
      _registerPointerEvent: function (node, timing, callback, context) {
        var pointerEvent = Y.Squarespace.Utils._attachPointerAction(node, timing, callback, context);
        this._registerEvent(pointerEvent);
      },

      /**
       * Register an animation using the anims manager.
       */
      _registerAnim: function (a) {
        this._anims.add(a);
      },

      /**
       * Register a callback that will not run when this function is destroyed.
       * @param  {Function} fn  The callback function
       * @param  {Context}   ctx The context to run this callback in (defaults to this)
       * @return {Function}       The guarded function.
       */
      _registerCallback: function(fn, ctx) {
        return Y.bind(function() {
          if (this.get('destroyed')) {
            return;
          }

          fn.apply(ctx || this, arguments);
        }, this);
      },

      /**
       * Register a timer so on destruction this timer
       * will be canceled (so you don't have ghost
       * timer callbacks running after instance destruction).
       *
       * @method _registerTimer
       * @param a Y.later() or Y.soon() handle
       * @return {Object} The later handler.
       */
      _registerTimer: function(timer) {
        this._timers.push(timer);
        return timer;
      },

      renderUI: function() {
        var additionalClass = this.get('className');
        this.get('boundingBox').addClass(additionalClass);

        if (!this.get('preventRenderTemplate')) {
          this.get('contentBox').prepend(this.renderTemplate());
        }
      },

      bindUI: function() {
        var id = this.get('id');

        this.after(id + '|stringsChange', function(e) {
          if (!e.noSyncUI) {
            this.syncUI();
          }
        }, this);

        // Y.View style delegated event binding. The only difference is the
        // handlers can't be specified as an actual function pointer, only
        // as a string
        var boundingBox = this.get('boundingBox');
        Y.Object.each(this.delegatedEvents, function (evObj, selectorFilter) {
          Y.Object.each(evObj, function (handler, eventName) {
            boundingBox.delegate(id + '|' + eventName, this[handler], selectorFilter, this);
          }, this);
        }, this);
      },

      syncUI: function() {},

      /**
        Inspects this class to find the type of template defined and grab the
        corresponding template function, which can be called with a context
        to produce a rendered template string.

        More specifically it traverses up the class hierarchy to find the first
        defined handlebars template or standard html string,
        then creates a template function from it. This allows any user of this
        class or its subclasses to simply set the TEMPLATE property as a string, or set a handlebars template
        file name as the HANDLEBARS_TEMPLATE property.

        @protected
        @method _getTemplateInfo
        @return An object literal containing the 'type' string and 'template'
          function
      */
      _getTemplateInfo: function () {

        var templateTypes = SSWidget.TEMPLATE_TYPES;
        var isHandleBarsTemplate = false;
        var currentClass = this.constructor;

        var templateFn;
        var templateStr;
        var templateFileName;
        var type;

        var checkCurrentClass = function() {
          templateFileName = currentClass.HANDLEBARS_TEMPLATE;
          templateStr = currentClass.TEMPLATE;

          isHandleBarsTemplate = Y.Lang.isValue(templateFileName);
          type = isHandleBarsTemplate ? templateTypes.HANDLEBARS : templateTypes.HTML;
        };

        checkCurrentClass();

        /**
         * Guaranteed to stop at this class if no subclass has
         * defined HANDLEBARS_TEMPLATE or TEMPLATE.
         */
        while (Y.Lang.isUndefined(templateFileName) && Y.Lang.isUndefined(templateStr)) {
          currentClass = currentClass.superclass.constructor;
          checkCurrentClass();
        }


        if (isHandleBarsTemplate) {
          templateFn = Y.Squarespace.UITemplates.getCompiledTemplate(templateFileName);
        } else {
          templateFn = function () {
            return Y.Node.create(templateStr);
          };
        }

        return {
          type: type,
          template: templateFn
        };
      },

      /**
        Gets the template for this class and renders it.

        @method renderTemplate
        @private
        @return {String} The rendered template
      */
      renderTemplate: function () {
        var templateTypes = SSWidget.TEMPLATE_TYPES;
        var templateInfo = this._getTemplateInfo();

        if (templateInfo.type === templateTypes.HANDLEBARS) {
          var templateContext = this._getHBTemplateContext();
          return templateInfo.template(templateContext);
        }
        return templateInfo.template();

      },

      getProperty: function(name) {
        var currentClass = this.constructor,
          value = this.constructor[name];

        while (Y.Lang.isUndefined(value)) {
          // go up one level
          if (currentClass.superclass) {
            currentClass = currentClass.superclass.constructor;
          } else {
            break;
          }
          // stop at the topmost level
          if (Y.Lang.isUndefined(currentClass)) {
            break;
          }
          value = currentClass[name];
        }
        return value;
      },

      /**
        This method returns getAtts by default. Override this method to provide
        a custom context to render with in renderUI

        @method _getHBTemplateContext
        @protected
        @return {Object} The context object to use to render the handlebars
          template in renderUI
      */
      _getHBTemplateContext: function() {
        return this.getAttrs();
      }

    },
    // Static properties
    {
      CSS_PREFIX: 'sqs-widget',

      /**
        @property HANDLEBARS_TEMPLATE
        @type String
        @protected
        @description The filename of the handlebars template file in the
          handlebars template folder. The handlebars template is used in place
          of the TEMPLATE if found.
      */
      HANDLEBARS_TEMPLATE: null,

      /**
        @property TEMPLATE
        @protected
        @type String
        @description The standard html template for this class, overridable
          by subclasses. The HANDLEBARS_TEMPLATE is used in its place if found.
      */
      TEMPLATE: '',

      /**
        @property TEMPLATE_TYPES
        @protected
        @description Constants representing the type of template being used
      */
      TEMPLATE_TYPES: {
        HANDLEBARS: 'handlebars',
        HTML: 'html'
      },

      ATTRS: {
        /**
          @attribute className
          @description An optional additional classname to add to the bounding box
            of this widget
          @type String
          @default
        */
        className: {
          value: null
        },

        /**
         * Prevent the template from being rendered in renderUI.
         * Implementations of SSWidget that set this to false must then render
         * the template on their own. This is useful when the template rendering
         * is needed in syncUI rather than renderUI.
         *
         * @attribute preventRenderTemplate
         * @type Boolean
         * @default false
         * @writeOnce
         */
        preventRenderTemplate: {
          value: false,
          validator: Y.Squarespace.AttrValidators.isBoolean
        }
      }
    }
  );

}, '1.0', {
  requires: [
    'base',
    'node',
    'squarespace-anims',
    'squarespace-attr-validators',
    'squarespace-debugger',
    'squarespace-template-helpers',
    'squarespace-ui-templates',
    'squarespace-util',
    'widget'
  ]
});
