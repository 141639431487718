import { t } from 'shared/i18n';

export default {
  HOUR_12: 1,
  HOUR_24: 2,
  LANG: {
    closed: t("Closed"),


    open: t("Open"),


    allDay: t("All Day") },



  TIME_TYPE: {
    start: 'start',
    end: 'end' },

  MAX_TIMESTAMP: 1440 // 24 hours x 60 minutes
};