import moment from 'moment';

/**
  * Converts a date into a human readable format... with a few special rules.
  * Returns "Just now" for:
  * - a time in the future
  * - less than a minute in the past and showSeconds is false.
  * @see customizeMoment.js for relative time thresholds.
  * @see customMomentLocales for locales configurations
  *
  * @method humanizeDate
  * @param time {Date|String|Integer} The time to convert
  * @param showSeconds {Boolean}
  * @return {String} Humanized timestamp string (e.g. 'less than a minute ago', or '5 years ago')
  */
export default function humanizeDate( time, showSeconds = false) {
  // Make sure we accept different input types.
  time = moment(time);
  if (__DEV__ && !time.isValid()) {
    console.error('humanizeDate: Invalid datetime passed in as first argument.');
  }

  const isJustNow = time.isAfter() || (!showSeconds && time.diff(moment(), 's') > -60);
  return isJustNow ? time.from(time) : time.fromNow();
}
