"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RECAPTCHA_RENDER_TYPE = void 0;
exports.destroy = destroy;
exports.execute = execute;
exports.executeFirst = executeFirst;
exports.getCaptchaContainer = getCaptchaContainer;
exports.loadReCaptcha = loadReCaptcha;
exports.render = render;
exports.renderAll = renderAll;
exports.reset = reset;
exports.resetFirst = resetFirst;
exports.validate = validate;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

require("core-js/modules/es.promise.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.array.from.js");

require("core-js/modules/es.string.iterator.js");

var _loadScript = _interopRequireDefault(require("./loadScript"));

/**
 * GoogleReCaptchaAPI
 * @description Wrapper interface to googles captcha apis
 */
var SCRIPT_URL = 'https://www.google.com/recaptcha/api.js?onload=__grecaptchaOnLoadCallback&render=';
var ID_ATTR_NAME = 'data-id';

var getSiteKey = function getSiteKey(win) {
  var _win$Static, _win$Static$SQUARESPA, _win$Static$SQUARESPA2, _win$Static$SQUARESPA3;

  return win === null || win === void 0 ? void 0 : (_win$Static = win.Static) === null || _win$Static === void 0 ? void 0 : (_win$Static$SQUARESPA = _win$Static.SQUARESPACE_CONTEXT) === null || _win$Static$SQUARESPA === void 0 ? void 0 : (_win$Static$SQUARESPA2 = _win$Static$SQUARESPA.website) === null || _win$Static$SQUARESPA2 === void 0 ? void 0 : (_win$Static$SQUARESPA3 = _win$Static$SQUARESPA2.captchaSettings) === null || _win$Static$SQUARESPA3 === void 0 ? void 0 : _win$Static$SQUARESPA3.siteKey;
};

var RECAPTCHA_RENDER_TYPE;
/**
 * The __ window variables are special! Recaptcha uses them
 *
 * @param {Window} win
 * @param {string} recaptchaType
 * @return {Promise} resolves when recaptcha is available
 */

exports.RECAPTCHA_RENDER_TYPE = RECAPTCHA_RENDER_TYPE;

(function (RECAPTCHA_RENDER_TYPE) {
  RECAPTCHA_RENDER_TYPE["EXPLICIT"] = "explicit";
  RECAPTCHA_RENDER_TYPE["ONLOAD"] = "onload";
})(RECAPTCHA_RENDER_TYPE || (exports.RECAPTCHA_RENDER_TYPE = RECAPTCHA_RENDER_TYPE = {}));

function loadReCaptcha(win) {
  var recaptchaType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : RECAPTCHA_RENDER_TYPE.EXPLICIT;
  win.__grecaptchaOnLoadPromise = win.__grecaptchaOnLoadPromise || new Promise(function (resolve, reject) {
    win.__grecaptchaOnLoadCallback = function () {
      return resolve();
    };

    if (win.document) {
      (0, _loadScript.default)(SCRIPT_URL + recaptchaType, {
        doc: win.document
      }).catch(reject);
    } else {
      reject('[GoogleReCaptchaAPI] Document was destroyed');
    }
  });
  return win.__grecaptchaOnLoadPromise;
}
/**
 * Render an individual captcha node
 *
 * @param {Window} win
 * @param {DOMNode} node Container for the captcha to render
 * @param {object} [params={}] passed directly into grecaptcha.render
 * @return {Promise}
 */


function render(win, node) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    customSiteKey: ''
  };
  // note the casing of sitekey
  var sitekey = options.customSiteKey || getSiteKey(win);

  if (!sitekey) {
    // Captcha not configured (i.e. user removed their keys). Not an error.
    return;
  }

  return loadReCaptcha(win).then(function () {
    var _node$ownerDocument;

    // It is possible that in the time it takes for recaptcha to load, you
    // have destroyed the document (in CMS) or DOM node (via ajax navigation
    // or some other JS).
    if (!node || // node was destroyed?
    node.getAttribute(ID_ATTR_NAME) || // captcha already rendered in node
    win.document !== node.ownerDocument || // document was changed?
    !(node !== null && node !== void 0 && (_node$ownerDocument = node.ownerDocument) !== null && _node$ownerDocument !== void 0 && _node$ownerDocument.defaultView) || // iframe itself was destroyed?
    !win.grecaptcha // an issue assigning grecaptcha to the window?
    ) {
      console.error('Error rendering captcha into node', {
        node: node
      });
      return;
    }

    var size = node.clientWidth < 320 ? 'compact' : 'normal';
    var captchaId = win.grecaptcha.render(node, (0, _objectSpread2.default)({
      sitekey: sitekey,
      size: size
    }, params), true);
    node.setAttribute(ID_ATTR_NAME, "".concat(captchaId));
    node.classList.add('rendered');
  });
}
/**
 * Render all _unrendered_ captcha nodes on the page.
 *
 * @param {Window} win
 */


function renderAll(win) {
  if (!win.document) {
    return;
  }

  var nodes = win.document.querySelectorAll(".captcha-container:not('.rendered')");
  Array.from(nodes).forEach(function (node) {
    return render(win, node);
  });
}
/**
 * Get status of captcha
 *
 * @param {Window} win
 * @param {DOMNode} node Container for the captcha to validate
 * @return {String}      The key from captcha to validate server side or null if incomplete.
 */


function validate(win, node) {
  var id = node.getAttribute(ID_ATTR_NAME);

  if (!id || !win.grecaptcha) {
    return;
  }

  return win.grecaptcha.getResponse(parseFloat(id));
}
/**
 * Used to get a recaptcha token for invisible reCaptcha.
 *
 * execute will not return the validated token, you must call grecaptcha.getResponse or retrieve it from the callback.
 *
 * @param win
 * @returns
 */


function executeFirst(win) {
  if (win.grecaptcha) {
    return Promise.resolve(win.grecaptcha.execute());
  }

  return Promise.reject({
    error: 'ReCaptcha has not been set up!'
  });
}
/**
 * Used to get a recaptcha token for invisible reCaptcha.
 *
 * execute will not return the validated token, you must call grecaptcha.getResponse or retrieve it from the callback.
 *
 * @param {Window} win
 * @param {DOMNode} node Container for the captcha to execute
 * @returns
 */


function execute(win, node) {
  var id = node.getAttribute(ID_ATTR_NAME);

  if (!id) {
    return Promise.resolve();
  }

  if (win.grecaptcha) {
    return Promise.resolve(win.grecaptcha.execute(parseFloat(id)));
  }

  return Promise.reject({
    error: 'ReCaptcha has not been set up!'
  });
}
/**
 * Resets the state of a captcha node
 *
 * @param {Window} win
 * @param {DOMNode} node Container for the captcha to reset
 */


function reset(win, node) {
  var id = node.getAttribute(ID_ATTR_NAME);

  if (!id || !win.grecaptcha) {
    return;
  }

  win.grecaptcha.reset(parseFloat(id));
}
/**
 * Resets the first instance of recaptcha.
 *
 * @param {Window} win
 */


function resetFirst(win) {
  if (win && win.grecaptcha) {
    win.grecaptcha.reset();
  }
}
/**
 * Gets the captcha container from a formNode
 *
 * @param {Window} win
 * @param  {DOMNode} formNode A node containing a form with a captcha
 * @return {?DOMNode} If there is a captcha container and keys are properly
 * set returns node, else null
 */


function getCaptchaContainer(win, formNode) {
  var captchaContainer = formNode.querySelector('.captcha-container');
  return getSiteKey(win) ? captchaContainer : null;
}
/**
 * Destroys a captcha instance
 *
 * @param {DOMNode} node Container for the captcha to destroy
 */


function destroy(captchaContainer) {
  while (captchaContainer.firstChild) {
    captchaContainer.removeChild(captchaContainer.firstChild);
  }

  captchaContainer.removeAttribute(ID_ATTR_NAME);
}